import {Component, OnInit, ViewChild} from '@angular/core';
import {generateUUID} from 'three/src/math/MathUtils';
import {InspectionFormUtils} from 'src/app/modules/inspections/data/form/inspection-form-utils';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {InspectionFormService} from 'src/app/modules/inspections/services/inspection-form.service';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {AssetBaseLayout} from 'src/app/modules/asset-portfolio/screens/asset/asset-layout';
import {InspectionService} from 'src/app/modules/inspections/services/inspection.service';
import {InspectionLayout} from 'src/app/modules/inspections/screens/inspection/inspection-layout';
import {InspectionWorkflowService} from 'src/app/modules/inspections/services/inspection-workflow.service';
import {InspectionEditPage} from 'src/app/modules/inspections/screens/inspection/edit/inspection-edit.page';
import {AssetService} from 'src/app/modules/asset-portfolio/services/asset.service';
import {GAGapOrigin} from 'src/app/models/gap-analysis/gaps/gap-origin';
import {DL50InspectionLayouts} from 'src/app/modules/dl50/screens/inspections/dl50-inspections-layouts';
import {DL50Service} from 'src/app/modules/dl50/services/dl50.service';
import {DL50Question} from 'src/app/models/dl50/masterdata/dl50-question';
import {Dl50InspectionUtils} from 'src/app/modules/dl50/data/dl50-inspection-utils';
import {DL50InspectionQuestionResponse} from 'src/app/models/dl50/dl50-inspection-question-response';
import {DL50InspectionQuestionResponseGap} from 'src/app/models/dl50/dl50-inspection-question-response-gap';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {UnoDynamicFormComponent} from '../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {UnoFormField} from '../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionData, InspectionDataPath} from '../../../../../models/inspections/inspection/inspection-data';
import {InspectionFormField} from '../../../../../models/inspections/form/inspection-form-field';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {UnoDynamicFormModule} from '../../../../../components/uno-forms/uno-dynamic-form.module';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UUID} from '../../../../../models/uuid';
import {GAGap} from '../../../../../models/gap-analysis/gaps/gap';
import {GAGapStatus, GAGapStatusLabel} from '../../../../../models/gap-analysis/gaps/gap-status';
import {GAGapHistory} from '../../../../../models/gap-analysis/gaps/gap-history';
import {GAGapRecommendation} from '../../../../../models/gap-analysis/gaps/gap-recommendation';
import {GapLayout} from '../gap-layout';
import {GapRecommendationLayout} from '../gap-recommendation-layout';
import {InspectionFormFieldType} from '../../../../inspections/data/form/inspection-form-field-type';
import {InspectionForm} from '../../../../../models/inspections/form/inspection-form';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {GapAnalysisService} from '../../../services/gap-analysis.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'gap-edit',
	templateUrl: 'gap-edit.page.html',
	standalone: true,
	imports: [
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoNoDataComponent,
		UnoDynamicFormModule,
		UnoButtonComponent,
		UnoTitleComponent,
		IonicModule,
		TranslateModule,
		FormatDatePipe,
		PermissionsPipe
	]
})
export class GapEditPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	public get gapStatus(): typeof GAGapStatus { return GAGapStatus; }
	
	public get gapOrigin(): typeof GAGapOrigin { return GAGapOrigin; }

	public get gapStatusLabel(): typeof GAGapStatusLabel { return GAGapStatusLabel; }

	public get gapLayout(): UnoFormField[] { return GapLayout; }

	public get assetLayout(): any { return AssetBaseLayout; }

	public get inspectionLayout(): any { return InspectionLayout; }

	public get dl50InspectionLayouts(): any { return DL50InspectionLayouts; }

	public get recommendationsLayout(): UnoFormField[] { return GapRecommendationLayout; }

	@ViewChild('gapForm', {static: false})
	public gapForm: UnoDynamicFormComponent = null;

	@ViewChild('gapInspectionFormField', {static: false})
	public gapInspectionFormField: UnoDynamicFormComponent = null;

	@ViewChild('recommendationsForm', {static: false})
	public recommendationsForm: UnoDynamicFormComponent = null;

	/**
	 * Gap object being edited.
	 */
	public gap: GAGap = null;

	/**
	 * History of the gap entry.
	 */
	public history: GAGapHistory[] = [];

	/**
	 * Gap recommendations object being edited.
	 */
	public recommendations: {items: GAGapRecommendation[]} = {items: []};

	/**
	 * The dynamic inspection data object for the dynamic inspection step associated.
	 */
	public inspectionData: InspectionData = null;

	/**
	 * The gap inspection data to present on gap inspection field form.
	 */
	public fieldResponseData: any = null;

	/**
	 * The layout to present with the form field data that generated this gap.
	 */
	public fieldResponseLayout: UnoFormField[] = [];

	/**
	 * The asset associated with the gap
	 */
	public asset: APAsset = null;

	/**
	 * The dynamic inspection that created the gap.
	 */
	public inspection: any = null;

	/**
	 * The DL50 inspection that created the gap.
	 */
	public dl50Inspection: DL50Inspection = null;

	/**
	 * Dynamic forms to show the dynamic inspection steps.
	 *
	 * Mapped by step UUID.
	 */
	public stepFormLayout: Map<UUID, UnoFormField[]> = new Map<UUID, UnoFormField[]>();

	/**
	 * Inspection steps in case of handling with a dynamic inspection.
	 */
	public steps: Map<UUID, InspectionWorkflowStep> = new Map<UUID, InspectionWorkflowStep>();

	/**
	 * The question that originated this gap (only when from DL50 origin).
	 */
	public question: DL50Question = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		this.gap = null;
		this.recommendations = {items: []};
		this.history = [];

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		App.navigator.setTitle('gap');

		await Promise.all([
			// Load gap data
			this.loadGap(data.uuid),
			// Load gap recommendations data
			this.loadGapRecommendations(data.uuid),
			// Load gap history data
			this.loadHistoryData(data.uuid)
		]);

		if (this.gap.origin === GAGapOrigin.DYNAMIC_INSPECTIONS) {
			// Load dynamic inspection data
			this.inspectionData = await InspectionService.getInspectionData(this.gap.inspectionDataUuid);
			
			// Load tab data
			await this.loadDynamicInspectionTabData();
			
			await this.buildDynamicInspectionFieldForm(this.gap.inspectionFieldPath);
		} else if (this.gap.origin === GAGapOrigin.DL50_INSPECTIONS) {
			[this.question, this.dl50Inspection] = await Promise.all([
				DL50Service.questionsGetByResponseID(this.gap.dl50InspectionQuestionResponseUuid),
				DL50Service.inspectionsGetByResponseID(this.gap.dl50InspectionQuestionResponseUuid)
			]);
			
			this.asset = await AssetService.get(this.dl50Inspection.assetUuid);
			
			// Get the question response data from inspection
			const questionResponse: DL50InspectionQuestionResponse = this.dl50Inspection.responses.find((r: DL50InspectionQuestionResponse) => { return r.questionUuid === this.question.uuid; });

			// Remove all the other gaps data from the response
			questionResponse.gaps = questionResponse.gaps.filter((g: DL50InspectionQuestionResponseGap) => { return g.gapUuid === this.gap.dl50QuestionGapUuid; });

			this.fieldResponseData = {[this.question.uuid]: questionResponse};

			this.fieldResponseLayout = Dl50InspectionUtils.buildQuestionsLayout([this.question]);
		}
	}

	/**
	 * Loads gap data from the API to be displayed on form.
	 * 
	 * @param uuid - The UUID of the gap to get the data for.
	 */
	public async loadGap(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.get, null, null, {uuid: uuid}, Session.session);
		this.gap = GAGap.parse(request.response.gap);
	}

	/**
	 * Loads asset and inspection data from the API to be displayed on form.
	 */
	public async loadDynamicInspectionTabData(): Promise<void> {
		const request = await GapAnalysisService.listGaps({search: this.gap.uuid, searchFields: ['ga_gap_id']});

		if (request.gaps[0].asset) {
			this.asset = await AssetService.get(request.gaps[0].asset.uuid);
		}

		if (this.inspectionData.inspectionUuid) {
			this.inspection = await InspectionService.get(this.inspectionData.inspectionUuid);

			const workflow = await InspectionWorkflowService.getFromProject(this.inspection.projectUuid);
			// Fill the map with steps of the workflow
			for (const step of workflow.steps) {
				this.steps.set(step.uuid, step);
			}
	
			const stepForms = await InspectionEditPage.loadWorkflowForms(workflow);
	
			for (const [stepUuid, step] of this.steps) {
				// Build step dynamic form
				if (step.formUuid) {
					const dynamicForm: UnoFormField[] = InspectionFormUtils.buildDynamicForm(stepForms.get(stepUuid), step.formUuid, false);
					this.stepFormLayout.set(stepUuid, dynamicForm);
				}
			}
		}
	}

	/**
	 * Loads dl50 inspection data from the API.
	 * 
	 * @param uuid - The UUID of the inspection data to get.
	 */
	public async loadGapDL50InspectionData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.inspection.inspectionData.get, null, null, {uuid: uuid}, Session.session);
		this.inspectionData = InspectionData.parse(request.response.data);
	}

	/**
	 * Build the form to present the response of the inspection field associated with the gap.
	 *
	 * Will load the field associated with the gap.
	 *
	 * If the field is placed inside a composed field will load the other fields associated inside the composed field.
	 * 
	 * @param fieldPath - Inspection field path.
	 */
	public async buildDynamicInspectionFieldForm(fieldPath: InspectionDataPath): Promise<void> {
		const path: InspectionDataPath = structuredClone(fieldPath);

		// Inspection step
		let request = await Service.fetch(ServiceList.inspection.workflowStep.get, null, null, {uuid: this.inspectionData.stepUuid}, Session.session);
		const step = InspectionWorkflowStep.parse(request.response.step);

		// Gap field
		request = await Service.fetch(ServiceList.inspection.field.get, null, null, {uuid: path.pop()}, Session.session);
		const gapField: InspectionFormField = InspectionFormField.parse(request.response.field);

		// Gap parent field
		let parentField: InspectionFormField = null;
		if (path.length > 0) {
			while (typeof path[path.length - 1] === 'number' && path.length > 2) {
				path.pop();
			}
			
			if (path[path.length - 1] !== 0) {
				request = await Service.fetch(ServiceList.inspection.field.get, null, null, {uuid: path.pop()}, Session.session);
				parentField = InspectionFormField.parse(request.response.field);
			}
		}

		const layout: UnoFormField[] = [
			{
				type: UnoFormFieldTypes.TITLE,
				label: step.name
			}
		];

		const inspectionResponses = {};

		// Auxiliary method to add a field to the layout
		function addField(field: InspectionFormField): void {
			const layoutField: UnoFormField = InspectionFormUtils.createDynamicFormField(field);
			layoutField.editable = false;
			layout.push(layoutField);
		}

		// If parent field is composed field
		if (parentField && parentField.type === InspectionFormFieldType.COMPOSED_FIELD) {
			// Get the form of the composed field
			const form: InspectionForm = await InspectionFormService.get(parentField.subFormUuid);

			// Display direct fields only
			for (const f of form.fields) {
				// Skip fields with subforms
				if (!f.subFormUuid) {
					inspectionResponses[f.uuid] = InspectionData.getDataByPath(this.inspectionData.data, path.concat([parentField.uuid, f.uuid]));
					addField(f);
				}
			}
		} else {
			inspectionResponses[gapField.uuid] = InspectionData.getDataByPath(this.inspectionData.data, fieldPath);
			addField(gapField);
		}

		this.fieldResponseData = inspectionResponses;
		this.fieldResponseLayout = layout;
	}

	/**
	 * Loads gap recommendations data from the API to be displayed on gap recommendations form.
	 * 
	 * @param gapUuid - The UUID of the gap to get the recommendations for.
	 */
	public async loadGapRecommendations(gapUuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.recommendations.list, null, null, {gapUuids: [gapUuid]}, Session.session);
		const recommendations = request.response.recommendations.map((d: any) => {
			return GAGapRecommendation.parse(d);
		});

		this.recommendations = {items: recommendations};
	}

	/**
	 * Loads all gap history data entries of a specific gap.
	 * 
	 * @param gapUuid - The UUID of the gap to get the history data entries for.
	 */
	public async loadHistoryData(gapUuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.gapAnalysis.gap.history.list, null, null, {uuid: gapUuid}, Session.session);
		this.history = request.response.gapHistories.map((h: any) => {
			return GAGapHistory.parse(h);
		});
	}

	/**
	 * Update the gap data on the API.
	 * 
	 * @param stayOnPage - Wheter stay on page of leave after update.
	 * @param destinationStatus - The destination status to change the gap to, on update.
	 */
	public async update(stayOnPage: boolean = false, destinationStatus?: typeof GAGapStatus[keyof typeof GAGapStatus]): Promise<void> {
		if (!this.gapForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const gap = structuredClone(this.gap);
		if (destinationStatus !== undefined) {
			gap.status = destinationStatus;
		}

		// Set the gap UUID on gap recommendations and missing UUIDs
		const recommendations: GAGapRecommendation[] = this.recommendations.items.map((r: GAGapRecommendation) => {
			if (!r.uuid) {
				r.uuid = generateUUID();
			}

			r.gapUuid = this.gap.uuid;
			return r;
		});

		await Promise.all([
			// Update gap info
			Service.fetch(ServiceList.gapAnalysis.gap.update, null, null, gap, Session.session),
			// Update gap recommendations
			Service.fetch(ServiceList.gapAnalysis.gap.recommendations.updateBatch, null, null, {gapUuid: this.gap.uuid, recommendations: recommendations}, Session.session)
		]);

		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('success'));

		this.loadHistoryData(this.gap.uuid);
	}

	/**
	 * Navigate to action-plan edit screen to an action-plan with this gap.
	 */
	public async createActionPlan(): Promise<void> {
		if (!this.gapForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Update gap on API before create action plan
		await Service.fetch(ServiceList.gapAnalysis.gap.update, null, null, this.gap, Session.session);

		App.navigator.navigate('/menu/gap-analysis/action-plans/edit', {createMode: true, gapUuids: [this.gap.uuid]});
	}

	/**
	 * Delete current gap.
	 */
	public async delete(): Promise<void> {
		if (await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'))) {
			await Service.fetch(ServiceList.gapAnalysis.gap.delete, null, null, {uuid: this.gap.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
