import {ActionPlan} from 'src/app/models/atex-inspections/action-plan/action-plan';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {ActionPlanAction} from '../../../../models/atex-inspections/action-plan/action-plan-action';

export type AtexActionPlanListParams = {
	sortField?: string,
	sortDirection?: string,
	state?: number,
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	filterTeam?: number
};

export type AtexActionPlanCountParams = {
	state?: number,
	search?: string,
	searchFields?: string[],
	filterTeam: number
};

export type AtexActionPlanList = {
	assets: {names: string, tags: string}
	createdAt: string,
	description: string,
	fields: string,
	parentAssets: {names: string, tags: string}
	priority: number
	state: number
	updatedAt: string,
	uuid: string,
	workOrder: string,
};
export class ActionPlanActionService {
	/**
	 * List of all possible Action Plan actions.
	 */
	public static async load(): Promise<ActionPlanAction[]> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.actionsGet, null, null, null, Session.session);

		return Promise.resolve(request.response);
	}

	/**
	 * List all action plans meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async list(params: AtexActionPlanListParams): Promise<{hasMore: boolean, actionPlans: AtexActionPlanList[], id: number}> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.list, null, null, params, Session.session);
		await request.response.actionPlans.forEach((element) => {
			element = ActionPlan.parse(element);
		});

		return {
			hasMore: request.response.hasMore,
			actionPlans: request.response.actionPlans,
			id: request.id
		};
	}

	/**
	 * Count all action plans meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async count(params: AtexActionPlanCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.atex.actionPlan.count, null, null, params, Session.session)).response.count;
	}

}
