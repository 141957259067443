import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {ProgressBar} from 'src/app/progress-bar';
import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {ResourceUtils} from '../../../utils/resource-utils';
import {UnoTableColumnLayout, UnoTableColumnType} from './uno-table.component';

export class UnoTableExport {

	/**
	 * Export the Uno Table Content as XLSX file.
	 * 
	 * @param loadMore - Method to fetch more elements from the API.
	 * @param layout - The layout of each row.
	 * @param checkedRows - The rows that are checked.
	 * @param totalItemCount - How many items there are in total.
	 */
	public static async exportTableXLSX(loadMore: Function, layout: UnoTableColumnLayout[], checkedRows: number[] = [], totalItemCount: number, fileName: string = 'export.xlsx'): Promise<void> {
		const progress = new ProgressBar();
		progress.show();

		try {
			const header: string[] = [];
			const attributes: string[] = [];

			// Filter the header to only include the visible values.
			for (let i = 0; i <= layout.length - 1 ; i++) {
				if (layout[i].visible) {
					header.push(layout[i].header);
					attributes.push(layout[i].attribute);
				}
			}

			// The translated header to show on the excel.
			const translatedHeader: string[] = [];

			// Translate the header
			header.forEach(function(title, index) {
				translatedHeader[index] = Locale.get(title);
			});

			// Add the translated headers to the matrix that will be turned into an xlsx file.
			const fileData: any[][] = [translatedHeader];

			let from: number = 0;
			const count: number = 300;
			while (true) {
				const load = await loadMore(from, count);

				if (checkedRows.length !== 0) {
					load.elements = load.elements.filter(function(elem, index) {
						return checkedRows.indexOf(from + index) !== -1;
					});
				}
				for (const element of load.elements) {
					// Update the progress bar
					progress.update(Locale.get('loadingData'), (from + load.elements.indexOf(element)) / totalItemCount);

					// The row
					const fileRow: any = [];

					for (const column of attributes) {
						const type: any = layout.find((c: UnoTableColumnLayout) => {return c.attribute === column;}).type;
						if (type === UnoTableColumnType.IMAGE) {
							fileRow.push(ResourceUtils.getURL(element[column]));
						} else if (type === UnoTableColumnType.STATUS) {
							fileRow.push(element[column] ? Locale.get('true') : Locale.get('false'));
						} else if (type === UnoTableColumnType.ICONS) {
							// Dont export action icons.
							continue;
						} else {
							fileRow.push(element[column]);
						}
					}

					// Push the row to the matrix
					fileData.push(fileRow);
				}

				from += count;
				if (!load.hasMore) {
					break;
				}
			}

			// Write the XLSX file
			XlsxUtils.writeFile(fileData, fileName.includes('.xlsx') ? fileName : fileName + '.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}
