<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.lds) {
			<uno-tab-section [active]="true" title="{{'lds' | translate}}">
				<uno-dynamic-form [layout]="this.layout" [object]="this.lds" #form></uno-dynamic-form>
				<!-- Save/Update -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					@if (this.createMode) {
						@if ([userPermissions.PIPELINE_INTEGRITY] | hasPermissions) {
							<uno-button (click)="this.create();">{{'create' | translate}}</uno-button>
						}
					}
					@else {
						@if ([userPermissions.PIPELINE_INTEGRITY] | hasPermissions) {
							<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
						}
						@if ([userPermissions.PIPELINE_INTEGRITY] | hasPermissions) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}
						@if ([userPermissions.PIPELINE_INTEGRITY] | hasPermissions) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
						@if ([userPermissions.PIPELINE_INTEGRITY] | hasPermissions) {
							<uno-button (click)="this.uploadXlsx();">{{'uploadXlsx' | translate}}</uno-button>
						}
						@if (!environment.PRODUCTION) {
							<uno-button (click)="this.generateTestData()">{{'generate' | translate}}</uno-button>
						}
					}
				</div>
			</uno-tab-section>
		}
		<!-- Channels -->
		@if (this.lds) {
			<uno-tab-section title="{{'channels' | translate}}">
				<uno-title>{{'channels' | translate}}</uno-title>
				@for (channel of this.lds.channels; track channel) {
					<div>
						<uno-dynamic-form [layout]="this.channelLayout" [object]="channel"></uno-dynamic-form>
						@if (this.createMode) {
							<div class="ion-padding">
								<uno-button (click)="this.lds.removeChannel(channel);" color="error">{{'delete' | translate}}</uno-button>
							</div>
						}
					</div>
				}
				@if (this.createMode) {
					<div class="ion-padding">
						<uno-button (click)="this.lds.addChannel();">{{'add' | translate}}</uno-button>
					</div>
				}
			</uno-tab-section>
		}
		<!-- Alarms -->
		@if (!this.createMode) {
			<uno-tab-section title="{{'alarm' | translate}}">
				<uno-title>{{'alarms' | translate}}</uno-title>
				@if (this.alarmHistory.length === 0) {
					<div style="height: calc(100% - 150px)">
						<uno-no-data></uno-no-data>
					</div>
				}
				@if (this.alarmHistory.length !== 0) {
					@for (history of this.alarmHistory; track history) {
						<uno-list-item (click)="this.viewAlarmConfig(history)">
							<uno-list-item-label>
								<h2>{{'date' | translate}}: {{history.timestamp | formatDate}}</h2>
								<h3>{{'alarm' | translate}}: {{history.description}}</h3>
								<h3>{{'level' | translate}}: {{alarmLevelLabel.get(history.level) | translate}}</h3>
								<h3>{{'distance' | translate}}: {{history.distance}} m</h3>
								<h3>{{'value' | translate}}: {{history.value}} Cº</h3>
								<h3>{{'active' | translate}}: {{history.active.toString() | translate}}</h3>
							</uno-list-item-label>
						</uno-list-item>
					}
				}
			</uno-tab-section>
		}
		<!-- Alarm Configuration -->
		@if (!this.createMode) {
			<uno-tab-section title="{{'alarmConfiguration' | translate}}">
				<uno-title>{{'alarmConfiguration' | translate}}</uno-title>
				@if (this.alarmConfig.length === 0) {
					<div style="height: calc(100% - 150px)">
						<uno-no-data></uno-no-data>
					</div>
				}
				@if (this.alarmConfig.length !== 0) {
					@for (alarm of this.alarmConfig; track alarm) {
						<uno-list-item (click)="this.editAlarmConfig(alarm)">
							<uno-list-item-icon src="./assets/placeholder/asset.png"></uno-list-item-icon>
							<uno-list-item-label>
								<h2>{{alarm.description}}</h2>
								<h3>{{alarmLevelLabel.get(alarm.level) | translate}}</h3>
							</uno-list-item-label>
							<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.deleteAlarmConfig(alarm.uuid); $event.stopPropagation();" color="error">{{'delete' | translate}}</uno-button>
						</uno-list-item>
					}
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.addAlarmConfig()">{{'add' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}
	</uno-tab>
</div>
