import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {IonicSelectableComponent} from 'ionic-selectable';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AssetService} from '../../../modules/asset-portfolio/services/asset.service';
import {Modal} from '../../../modal';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {Session} from '../../../session';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {QRReaderComponent} from '../../../modules/qr/components/qr-reader/qr-reader.component';
import {NFCReaderComponent} from '../../../modules/nfc/components/nfc-reader/nfc-reader.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UUID} from '../../../models/uuid';
import {UnoFormField} from '../../uno-forms/uno-dynamic-form/uno-form-field';
import {AssetBaseLayout, AssetStructureLayout} from '../../../modules/asset-portfolio/screens/asset/asset-layout';
import {UnoDynamicFormComponent} from '../../uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {PermissionsPipe} from '../../../pipes/permissions.pipe';

/**
 * The asset list component is used for the user to select assets from a list retrieved from the API.
 *
 * The list can be filtered to include a specific type of assets.
 */
@Component({
	selector: 'uno-asset-selector',
	templateUrl: './uno-asset-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoAssetSelectorComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, IonicSelectableComponent, FormsModule, TranslateModule, PermissionsPipe]
})
export class UnoAssetSelectorComponent implements ControlValueAccessor {
	public get permissions(): any { return UserPermissions; }

	public get session(): any { return Session; }

	public get app(): any { return App; }
	
	/**
	 * Indicate if the user can select multiple assets.
	 *
	 * When selecting multiple the value stored is an array.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, clear button will be shown on component to clear its value.
	 */
	@Input()
	public showClear: boolean = false;

	/**
	 * If set, sorts all the items alphabetically upon fetch.
	 */
	@Input()
	public sort: boolean = false;
	
	/**
	 * List of the selected asset UUID stored in this component.
	 *
	 * This list can be changed dynamically using the writeValue() method.
	 */
	public value: (UUID[] | UUID) = [];

	/**
	 * Assets that are selected on the object and are not yet available from the API.
	 *
	 * These should be retrieved from the API.
	 */
	public baseOptions: APAsset[] = [];

	/**
	 * Assets available based on the search value inserted.
	 */
	public options: APAsset[] = [];

	/**
	 * Search value inserted.
	 */
	public search: string = '';

	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public batchSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	/**
	 * Load more data from the API to display. Only loads a chunk of data at a time.
	 *
	 * @param component - Component, used to control the data flow.
	 * @param isSearch - If set true its assumed to be a search or first request, the lazy loader counter is reset and data is cleared.
	 */
	public async loadDataLazy(component?: any, isSearch: boolean = false): Promise<void> {
		// Auxiliary method to add loaded options to the list
		const addOptions = (options): void => {
			for (let i = 0; i < options.length; i++) {
				// Check if the uuid already exists in the base options that were pre-loaded
				if (this.baseOptions.find(function(a) { return a.uuid === options[i].uuid; }) === undefined) {
					UnoAssetSelectorComponent.getDisplayText(options[i]);
					
					if (!this.options) {
						this.options = [];
					}
					
					this.options.push(options[i]);
				}
			}
		};
		
		const data: any = {
			from: isSearch ? 0 : this.count,
			count: this.batchSize,
			search: this.search
		};

		if (this.sort) {
			data.sortField = 'name';
			data.sortDirection = 'ASC';
		}

		const request = await Service.fetch(ServiceList.assetPortfolio.asset.list, null, null, data, Session.session);

		// If is it a search result clean up old options
		if (isSearch) {
			if (component) {
				component.enableInfiniteScroll();
			}

			this.options = this.baseOptions.concat([]);
			addOptions(request.response.assets);
			this.count = request.response.assets.length;
		} else {
			addOptions(request.response.assets);
			this.count += request.response.assets.length;
		}

		if (component) {
			if (!request.response.hasMore) {
				component.disableInfiniteScroll();
			}
			if (isSearch) {
				component.endSearch();
			}
			component.endInfiniteScroll();
		}
	}

	/**
	 * Create a new asset in a modal and select it.
	 *
	 * Only display basic asset information.
	 */
	public async createAssetModal(): Promise<void> {
		const asset = new APAsset();

		const layout: UnoFormField[] = AssetBaseLayout.concat(AssetStructureLayout);

		await Modal.form(Locale.get('asset'), asset, layout);

		if (!UnoDynamicFormComponent.requiredFilled(AssetStructureLayout, asset) || !UnoDynamicFormComponent.requiredFilled(AssetBaseLayout, asset)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const request = await Service.fetch(ServiceList.assetPortfolio.asset.create, null, null, asset, Session.session);

		this.writeValue(request.response.uuid);
		this.loadBaseOptions();
	}

	/**
	 * Load base options from the API server, these options are the ones selected on the object.
	 *
	 * They need to be fetched to show on the list (their name is not known, if their info is not retrieved).
	 */
	public async loadBaseOptions(): Promise<void> {
		if (!this.value) {
			return;
		}

		const assets: any = this.multiple ? this.value : [this.value];
		
		const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assets}, Session.session);

		this.baseOptions = request.response.assets;
		for (let i = 0; i < this.baseOptions.length; i++) {
			UnoAssetSelectorComponent.getDisplayText(this.baseOptions[i]);
		}

		this.options = this.baseOptions.concat([]);
	}

	/**
	 * Get the text to be displayed on the asset list for a specific option.
	 *
	 * @param option - Option of the list retrieved from the API
	 */
	public static getDisplayText(option: any): void {
		if (option.tag) {
			option.display = option.name + ' (' + option.tag + ')';
		} else {
			option.display = option.name;
		}
	}

	/**
	 * Callback method called when the user inputs something into the search box.
	 */
	public async onSearch(event: {component: IonicSelectableComponent, text: string}): Promise<void> {
		this.search = event.text;
		await this.loadDataLazy(event.component, true);
	}

	/**
	 * Callback method used to load more data from the API.
	 */
	public async onInfiniteLoad(event: {component: IonicSelectableComponent, text: string}): Promise<void> {
		await this.loadDataLazy(event.component, false);
	}

	/**
	 * Select a asset using a NFC tag, a NFC selector is shown to the user.
	 *
	 * If the NFC tag asset is already present in the list a message is presented to the user.
	 */
	public selectAssetNFC(): void {
		Modal.component(NFCReaderComponent, {
			onRead: async(id: any) => {
				const asset: APAsset = await AssetService.getByNFC(id);
				Modal.toast(this.selectAsset(asset.uuid) ? Locale.get('nfcSuccess') : Locale.get('assetAlreadySelected'));
			}
		});
	}

	/**
	 * Select an asset using a QR code, a QR code selector modal is shown to the user.
	 *
	 * If the QR asset is already present in the list a message is presented to the user.
	 */
	public selectAssetQR(): void {
		Modal.component(QRReaderComponent, {
			onRead: async(id: any) => {
				const asset: APAsset = await AssetService.getByQR(id);
				Modal.toast(this.selectAsset(asset.uuid) ? Locale.get('qrSuccess') : Locale.get('assetAlreadySelected'));
			}
		});
	}

	/**
	 * Select asset from its uuid, automatically adds its data to the list.
	 *
	 * @param uuid - Asset uuid received from the API.
	 * @returns "true" if the object was selected successfully, "false" if the object was already selected.
	 */
	public selectAsset(uuid: UUID): boolean {
		if (this.value && this.value.includes(uuid)) {
			return false;
		}

		this.writeValue(this.multiple ? this.value.concat(uuid) : uuid);
		return true;
	}

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.loadBaseOptions();
		this.onChange(this.value);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
