import {UUID, UUIDIdentification} from '../../uuid';
import {InspectionForm} from '../form/inspection-form';
import {InspectionFormFieldType} from '../../../modules/inspections/data/form/inspection-form-field-type';
import {InspectionWorkflowStep} from '../workflow/inspection-workflow-step';
import {InspectionFormField} from '../form/inspection-form-field';
import {InspectionFormFieldTypeDefaultValue} from '../../../modules/inspections/data/form/inspection-form-field-default';
import {InspectionData, InspectionDataFields} from './inspection-data';

/**
 * Inspections contain the actual inspection data, they are organized into projects.
 *
 * The data stored in the inspections depends on the workflow selected for this inspection project.
 */
export class Inspection extends UUIDIdentification {
	/**
	 * Name of the inspection.
	 */
	public name: string = '';

	/**
	 * Inspection number is a sequential identifier for an inspection in the context of the project.
	 */
	public numberId: number = 0;

	/**
	 * Description of the inspection.
	 */
	public description: string = '';

	/**
	 * Project that this inspection belongs to.
	 */
	public projectUuid: UUID = null;

	/**
	 * Team responsible for this inspection.
	 */
	public teamUuid: UUID = null;

	/**
	 * Asset attached to this inspection.
	 */
	public assetUuid: UUID = null;

	/**
	 * Inspection current step UUID.
	 *
	 * This inspection step is one of the steps available in the workflow in use by the project.
	 */
	public stepUuid: UUID = null;

	/**
	 * QR identifier of this inspection.
	 */
	public qr: string = null;

	/**
	 * Inspection data for each inspection step performed.
	 */
	public data: InspectionData[] = [];

	/**
	 * RBAC list of allowed roles
	 */
	public rbacAllowedList: UUID[] = [];

	/**
	 * Get inspection data for a specific inspection step by its UUID.
	 *
	 * @param stepUuid - The UUID of the step to fetch the data.
	 * @returns The inspection data object.
	 */
	public getInspectionDataForStep(stepUuid: UUID): InspectionData {
		return this.data.find(function(data: InspectionData) {
			return data.stepUuid === stepUuid;
		});
	}

	/**
	 * Parse data into Inspection object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): Inspection {
		const inspection = new Inspection();

		inspection.uuid = data.uuid;
		inspection.createdAt = new Date(data.createdAt);
		inspection.updatedAt = new Date(data.updatedAt);

		inspection.name = data.name || '';
		inspection.description = data.description || '';
		inspection.numberId = data.numberId;
		inspection.projectUuid = data.projectUuid;
		inspection.teamUuid = data.teamUuid;
		inspection.assetUuid = data.assetUuid ? data.assetUuid : null;
		inspection.stepUuid = data.stepUuid;
		inspection.qr = data.qr;
		inspection.data = [];

		inspection.rbacAllowedList = data.rbacAllowedList || [];

		if (data.data instanceof Array) {
			for (let i = 0; i < data.data.length; i++) {
				inspection.data.push(InspectionData.parse(data.data[i]));
			}
		}

		return inspection;
	}

	/**
	 * Initialize or update the inspection data fields to store inspection data.
	 * 
	 * Updates all inspection data fields and initializes the field of the current step ID.
	 *
	 * The fields will be initialized based on the project configuration.
	 *
	 * For and steps and forms associated with the inspections (as well as sub-forms) need to be provided, so the data can be initialized.
	 * 
	 * @param steps - Steps used by this inspection.
	 * @param forms - Forms used by this inspection, (including sub-forms).
	 */
	public updateData(steps: Map<UUID, InspectionWorkflowStep>, forms: Map<UUID, InspectionForm>): void {
		// Auxiliary function to initialize/update the data of a form.
		//
		// It will check if the fields in the data are present in the form and if not, remove them.
		function updateFields(data: InspectionDataFields, form: InspectionForm): any {
			// Check if there are fields in the data that are not present in the form
			for (const fieldUuid in data) {
				let found = false;
				for (const field of form.fields) {
					if (field.uuid === fieldUuid) {
						found = true;
						break;
					}
				}
				
				if (!found) {
					delete data[fieldUuid];
				}
			}

			// Iterate over form fields
			for (const field of form.fields) {
				// Check if the field is already present in the data
				if (data[field.uuid] === undefined) {
					data[field.uuid] = InspectionFormFieldTypeDefaultValue.get(field.type)();
					
					if (field.type === InspectionFormFieldType.SUB_FORM || field.type === InspectionFormFieldType.COMPOSED_FIELD) {
						if (!forms.has(field.subFormUuid)) {
							throw new Error('Forms map is missing the uuid ' + field.subFormUuid);
						}

						data[field.uuid] = updateFields(data[field.uuid], forms.get(field.subFormUuid));
					} else if (field.type === InspectionFormFieldType.OPTIONS) {
						data[field.uuid] = field.data.defaultOptionUuid || InspectionFormFieldTypeDefaultValue.get(field.type)();
					}
				} else {
					// If the field is a sub-form, update its fields
					if (field.type === InspectionFormFieldType.SUB_FORM || field.type === InspectionFormFieldType.COMPOSED_FIELD) {
						if (!forms.has(field.subFormUuid)) {
							throw new Error('Forms map is missing the uuid ' + field.subFormUuid);
						}

						data[field.uuid] = updateFields(data[field.uuid], forms.get(field.subFormUuid));
						
					} else if (field.type === InspectionFormFieldType.MULTIPLE_FORMS) {
						// Update multiple forms fields
						if (!forms.has(field.subFormUuid)) {
							throw new Error('Forms map is missing the uuid ' + field.subFormUuid);
						}

						for (let i = 0; i < data[field.uuid].length; i++) {
							data[field.uuid][i] = updateFields(data[field.uuid][i], forms.get(field.subFormUuid));
						}
					}
				}
			}

			return data;
		}


		if (!this.stepUuid) {
			throw new Error('Step UUID must be defined before data is initialized.');
		}
		
		if (!steps.has(this.stepUuid)) {
			throw new Error('Step map is missing the uuid ' + this.stepUuid);
		}

		// Get the inspection data entry
		let data: InspectionData = this.getInspectionDataForStep(this.stepUuid);
		if (!data) {
			data = new InspectionData();
			data.stepUuid = this.stepUuid;
			data.inspectionUuid = this.uuid;
			this.data.push(data);
		}

		// Initialize/update all fields in the inspection.
		for (let i = 0; i < this.data.length; i++) {
			if (!steps.has(this.data[i].stepUuid)) {
				throw new Error('Step map is missing the uuid ' + this.data[i].stepUuid);
			}

			const step: InspectionWorkflowStep = steps.get(this.data[i].stepUuid);

			// Check if step has a form associated
			if (step.formUuid) {
				if (!forms.has(step.formUuid)) {
					throw new Error('Forms map is missing the uuid ' + step.formUuid);
				}
	
				// Get the form for the step
				const form: InspectionForm = forms.get(step.formUuid);
	
				// Update existing step data
				this.data[i].data = updateFields(this.data[i].data, form);
			} else {
				this.data[i].data = {};
			}
		}

		// Sort inspection steps
		this.data.sort((a, b) => {
			return steps.get(a.stepUuid).index - steps.get(b.stepUuid).index;
		});
	}

	/**
	 * Clean old data no longer used on inspection due to changes on inspection project workflow.
	 *
	 * Data must be removed from inspection data in cases such as:
	 * - Steps no longer exist on the workflow;
	 * - Steps or forms whose sub-forms have changed;
	 * - Form fields no longer present or type changed on forms;
	 *
	 * @param inspectionData - Data of all inspection steps.
	 * @param steps - Inspection steps.
	 * @param forms - Forms used by the inspection, including sub-forms.
	 * @returns Filtered inspection data containing only the fields used in the inspection.
	 */
	public static cleanData(inspectionData: InspectionData[], steps: Map<UUID, InspectionWorkflowStep>, forms: Map<UUID, InspectionForm>): InspectionData[] {
		// Fields the fields that are no longer present on inspection. Returns the fields to be kept.
		function filterFields(data: InspectionDataFields, fields: InspectionFormField[]): InspectionDataFields {
			const filtered: InspectionDataFields = {};
			
			for (const field of fields) {
				for (const fieldUuid in data) {
					// Check if the field is present in the form
					if (field.uuid === fieldUuid) {
						// Sub-forms
						if (field.type === InspectionFormFieldType.SUB_FORM || field.type === InspectionFormFieldType.COMPOSED_FIELD) {
							if (!forms.has(field.subFormUuid)) {
								throw new Error('Forms map is missing the uuid ' + field.subFormUuid);
							}

							const form = forms.get(field.subFormUuid);
							filtered[fieldUuid] = filterFields(data[fieldUuid], form.fields);
						} else if (field.type === InspectionFormFieldType.MULTIPLE_FORMS) {
							if (!forms.has(field.subFormUuid)) {
								throw new Error('Forms map is missing the uuid ' + field.subFormUuid);
							}

							const form = forms.get(field.subFormUuid);
							filtered[fieldUuid] = [];
							for (let i = 0; i < data[fieldUuid].length; i++) {
								filtered[fieldUuid].push(filterFields(data[fieldUuid][i], form.fields));
							}
						} else {
							filtered[fieldUuid] = data[fieldUuid];
						}
					}
				}
			}

			return filtered;
		}
		
		const cleanedData: InspectionData[] = [];

		for (let i = 0; i < inspectionData.length; i++) {
			if (!steps.has(inspectionData[i].stepUuid)) {
				throw new Error('Step map is missing the uuid ' + inspectionData[i].stepUuid);
			}

			// Inspection data must be kept only if it refers to a step still present on inspection workflow
			const step: InspectionWorkflowStep = steps.get(inspectionData[i].stepUuid);
			const data: InspectionDataFields = inspectionData[i].data;

			if (!forms.has(step.formUuid)) {
				throw new Error('Forms map is missing the uuid ' + step.formUuid);
			}
			
			const form: InspectionForm = forms.get(step.formUuid);

			// Update inspection step data and add it to the object being returned
			inspectionData[i].data = filterFields(data, form.fields);
			cleanedData.push(inspectionData[i]);
		}

		return cleanedData;
	}
}
