<uno-content [borders]="true">
	@if (this.plant !== null) {
		<!-- Base Information -->
		<uno-dynamic-form [layout]="this.layout" [object]="this.plant" #form></uno-dynamic-form>
		<!-- Save/Update -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			@if (this.createMode) {
				@if ([userPermissions.PIPELINE_INTEGRITY_PLANT_CREATE] | hasPermissions) {
					<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
				}
			}
			@if (!this.createMode) {
				@if ([userPermissions.PIPELINE_INTEGRITY_PLANT_EDIT] | hasPermissions) {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
				}
				@if ([userPermissions.PIPELINE_INTEGRITY_PLANT_EDIT] | hasPermissions) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				@if ([userPermissions.PIPELINE_INTEGRITY_PLANT_DELETE] | hasPermissions) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	}
</uno-content>
