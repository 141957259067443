import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {LDSAlarm} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm';
import {LDSAlarmHistory} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm-history';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

/**
 * Service for LDS alarms.
 */
export class LDSAlarmHistoryService {

	/**
	 * Load alarms data by UUID from API
	 * 
	 * @param uuid - LDS UUID
	 * @returns Alarms associated with the LDS.
	 */
	public static async listAlarms(ldsUuid: UUID): Promise<LDSAlarm[]> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.list, null, null, {ldsUuid: ldsUuid}, Session.session);
		const data = request.response.alarms;

		return data.map(function(alarm) {
			return LDSAlarm.parse(alarm);
		});
	}

	/**
	 * Load alarm history for the LDS.
     * 
     * @param ldsUuid - LDS UUID
     * @returns Alarm activation history.
	 */
	public static async listAlarmHistory(ldsUuid: UUID): Promise<LDSAlarmHistory[]> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.history.list, null, null, {ldsUuid: ldsUuid}, Session.session);
		const data = request.response.alarms;

		return data.map(function(alarm) {
			return LDSAlarmHistory.parse(alarm);
		});
	}
}
