/**
 * List of picture formats that can be used for converting user provided data.
 */
export enum PictureFormat {
	/**
	 * JPEG data should be preferred for photos.
	 */
	JPEG = 'jpeg',

	/**
	 * PNG is better suited for vector graphics.
	 */
	PNG = 'png'
}

