import {Component, OnInit} from '@angular/core';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {App} from '../../../app';
import {MenuCardsOptions, MenuCardsComponent} from '../../../components/layout/menu-cards/menu-cards.component';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'demo-page',
	templateUrl: './demo.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class DemoPage extends ScreenComponent implements OnInit {

	public get app(): any { return App; }


	public options: MenuCardsOptions[] = [
		{
			label: 'components',
			icon: 'logo-web-component',
			route: '/menu/demo/components',
			data: null,
			permissions: [],
			badge: null
		},
		{
			label: 'form',
			icon: 'document-text-outline',
			route: '/menu/demo/form',
			data: null,
			permissions: [],
			badge: null
		},
		{
			label: 'expressionEditor',
			icon: 'bug',
			route: '/menu/demo/expression-editor',
			data: null,
			permissions: [],
			badge: null
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('demo');
	}
}
