import createReport from 'docx-templates';
import {Environment} from 'src/environments/environment';
import {Service} from '../http/service';
import {ServiceList} from '../http/service-list';
import {Resource} from '../models/resource';
import {UUID} from '../models/uuid';
import {Locale} from '../locale/locale';
import {User} from '../models/users/user';
import {Session} from '../session';
import {QRGenerator} from '../modules/qr/data/qr-generator';
import {FormatDatePipe} from '../pipes/format-date.pipe';
import {ResourceUtils} from './resource-utils';
import {ImageUtils} from './image-utils';

/**
 * Type to represent docx images passed as additional arguments images.
 */
export type DocxImage = {data: ArrayBuffer, extension: string, width: number, height: number};

/**
 * Contains utils to handle docx data generation.
 */
export class DocxUtils {
	/**
	 * Generate a docx file from DOCX template and data provided.
	 *
	 * The document should access the object provided to obtain information and run its commands. The commands are wrapped with "\{\{...\}\}".
	 *
	 * @param template - Arraybuffer data of the docx template to be used.
	 * @param context - Data to be passed to the document context (should include required variables and methods to generate the document).
	 * @returns DOCX Document generated from the template file and data provided as binary data.
	 */
	public static async generateDocxFromTemplate(template: ArrayBuffer, context: any): Promise<ArrayBuffer> {
		const data = {
			// Utils for applying style changes to docx document
			style: {
				table: {
					// Change table background color, receives RRGGBB hex color
					backgroundColor: function(color: string) {
						if (color.length !== 6) {
							throw new Error('Color is not in the correct format, should be RRGGBB');
						}

						return `||<w:tcPr><w:shd w:val="clear" w:color="auto" w:fill="${color}"/></w:tcPr>||`;
					}
				},
				font: {
					color: function(color: string) {
						if (color.length !== 6) {
							throw new Error('Color is not in the correct format, should be RRGGBB');
						}

						return `||<w:rPr><w:color w:val="${color}"/></w:rPr>||`;
					}
				}

			},
			// Method to get image data from resource
			image: async(resource: Resource, height: number = 3.37): Promise<DocxImage> => {
				return await DocxUtils.createImageResource(resource, height);
			},
			// Method to get qr code image asynchronously.
			qr: async(content: string, height: number = 5): Promise<DocxImage> => {
				return await DocxUtils.createImageData(await QRGenerator.generateArrayBuffer(content, 'png'), 'png', height);
			},
			user: {
				// Method to get a user by its UUID
				uuid: (id: UUID): User => {
					const request = Service.fetchSync(ServiceList.users.get, null, null, {uuid: id}, Session.session);

					return User.parse(request.response.user);
				},
				// Method to get a user signature by user UUID
				signature: async(id: UUID, width: number = 3.0, height?: number): Promise<DocxImage> => {
					const request = await Service.fetch(ServiceList.users.get, null, null, {uuid: id}, Session.session);

					const user: User = User.parse(request.response.user);

					return user.signature ? await DocxUtils.createImageResource(user.signature, height, width) : null;
				}
			},
			// Date and time utils
			formatDateUtils: FormatDatePipe,
			// Current locale
			locale: Locale.translations.get(Locale.code),
			// Current date
			date: new Date().toLocaleDateString(Locale.code),
			// Current time
			time: new Date().toLocaleTimeString(Locale.code)
		};

		Object.assign(data, context);

		const options: any = {
			additionalJsContext: data,
			template: template,
			noSandbox: true,
			rejectNullish: false,
			failFast: true,
			cmdDelimiter: ['{{', '}}'],
			processLineBreaks: true,
			fixSmartQuotes: true,
			maximumWalkingDepth: 1e6
		};

		if (!Environment.PRODUCTION) {
			console.log('EQS: Template generation options', options);
		}

		return await createReport(options);
	}

	/**
	 * Calculate the size of an image to place in document.
	 *
	 * @param aspect - Aspect ratio of the image.
	 * @param height - Preferred height (if any).
	 * @param width - Preferred height (if any).
	 */
	public static calculateImageSize(aspect: number, height?: number, width?: number): {width: number, height: number} {
		if (!height && !width) {
			height = 3.37;
		}

		if (!width && height) {
			width = height * aspect;
		}

		if (!height && width) {
			height = width / aspect;
		}

		return {width: width, height: height};
	}

	/**
	 * Create an image to place inside a DOCX file from arraybuffer data.
	 *
	 * If width and height are booth defined
	 *
	 * @param arraybuffer - Array buffer with the
	 * @param format - Format of the image (e.g. png, jpeg).
	 * @param height - Height of the image.
	 * @param width - Width of the image.
	 */
	public static async createImageData(arraybuffer: ArrayBuffer, format: string, height?: number, width?: number): Promise<DocxImage> {
		const blob = new Blob([arraybuffer]);
		const url = window.URL.createObjectURL(blob);

		const aspect = await ImageUtils.getAspectRatio(url);
		const size = DocxUtils.calculateImageSize(aspect, height, width);

		return {data: arraybuffer, width: size.width, height: size.height, extension: '.' + format};
	}

	/**
	 * Create a DOCX image object to place inside a DOCX file using a resource object.
	 *
	 * The resource is fetched from the server as arraybuffer data.
	 *
	 * @param resource - Resource to get image from.
	 * @param height - Height of the image (to calculate width from ratio).
	 * @param width - Width of the image.
	 */
	public static async createImageResource(resource: Resource, height?: number, width?: number): Promise<DocxImage> {
		const picture = await ResourceUtils.getArrayBuffer(resource);

		const aspect = await ResourceUtils.getAspectRatio(resource);
		const size = DocxUtils.calculateImageSize(aspect, height, width);

		return {data: picture, width: size.width, height: size.height, extension: '.' + resource.format};
	}
}
