/**
 * List of possible origin for the gap entries.
 */
export enum GAGapOrigin {
	/**
	 * All origins.
	 */
	ALL = -1,

	/**
	 * Dynamic inspections.
	 */
	DYNAMIC_INSPECTIONS = 1,

	/**
	 * DL50 inspections.
	 */
	DL50_INSPECTIONS = 2,
};

/**
 * List of possible origin labels for the gap entries.
 */
export const GAGapOriginLabel: Map<number, string> = new Map([
	[GAGapOrigin.ALL, 'all'],
	[GAGapOrigin.DYNAMIC_INSPECTIONS, 'inspections'],
	[GAGapOrigin.DL50_INSPECTIONS, 'dl50']
]);
