import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {DL50InspectionStatusType} from 'src/app/models/dl50/dl50-inspection-status';
import {Resource} from 'src/app/models/resource';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {DL50InspectionConclusionFilterType} from 'src/app/models/dl50/dl50-inspection-conclusion-filter';
import {DL50RegulatoryStandard} from '../../../models/dl50/masterdata/dl50-regulatory-standard';
import {DL50Question} from '../../../models/dl50/masterdata/dl50-question';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {SortUtils} from '../../../utils/sort-utils';
import {SortDirection} from '../../../utils/sort-direction';
import {CompareUtils} from '../../../utils/compare-utils';

// Parameters to apply on the DL50 questions count API request.
export type DL50QuestionsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

// Parameters to apply on the DL50 questions list API request.
export type DL50QuestionsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
};

// DL50 questions list API request format.
export type DL50QuestionsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched questions
	questions: DL50Question[]
};

// Parameters to apply on the DL50 regulatory standards count API request.
export type DL50RegulatoryStandardsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

// Parameters to apply on the DL50 regulatory standards list API request.
export type DL50RegulatoryStandardsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
};

// DL50 regulatory standards list API request format.
export type DL50RegulatoryStandardsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched regulatory standards
	standards: DL50Question[],
	// Id of the request
	id: number
};

// Parameters to apply on the DL50 inspections count API request.
export type DL50InspectionsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
	// The status to filter the inspections by
	status?: DL50InspectionStatusType
	// The final conclusion value to filter the inspections by
	finalNotesConclusion?: DL50InspectionConclusionFilterType
};

// Parameters to apply on the DL50 inspections list API request.
export type DL50InspectionsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// The status to filter the inspections by
	status?: DL50InspectionStatusType
	// The final conclusion value to filter the inspections by
	finalNotesConclusion?: DL50InspectionConclusionFilterType
};

// DL50 inspections list API request format.
export type DL50InspectionsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched inspections
	inspections: DL50InspectionListItem[]
};

export type DL50InspectionListItem = {
	// The UUID of the inspection
	uuid: UUID,
	// The creation date of this entry on database
	createdAt: Date,
	// The last update date of this entry on database
	updatedAt: Date,
	// The label of the inspection
	label: string,
	// The description of the inspection
	description: string,
	// The status of the inspection
	status: number,
	// The final notes conclusion value of the inspection
	finalNotesConclusion: number,
	// The number of gaps associated to this inspection
	gaps: number,
	// The asset list information
	asset: {
		// The UUID of the asset
		uuid: UUID,
		// The name of the asset
		name: string,
		// The tag of the asset
		tag: string,
		// The description of the asset
		description: string,
		// The pictures of the asset
		pictures: Resource[],
	},
}

export class DL50Service {
	/**
	 * Create a new DL-50 master data question.
	 */
	public static async questionsCreate(data: DL50Question): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.masterdata.questions.create, null, null, data, Session.session)).response.uuid;
	}

	/**
	 * Update a DL-50 master data question with received data.
	 */
	public static async questionsUpdate(question: DL50Question): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.update, null, null, question, Session.session);
	}

	/**
	 * Get a DL-50 master data question by its UUID.
	 */
	public static async questionsGet(uuid: UUID): Promise<DL50Question> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.get, null, null, {uuid: uuid}, Session.session);
		return DL50Question.parse(request.response.question);
	}

	/**
	 * Get a DL-50 master data question by question response UUID.
	 */
	public static async questionsGetByResponseID(uuid: UUID): Promise<DL50Question> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.getByResponse, null, null, {uuid: uuid}, Session.session);
		return DL50Question.parse(request.response.question);
	}

	/**
	 * Update multiple DL-50 master data questions in batch.
	 */
	public static async questionsUpdateBatch(questions: DL50Question[]): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.updateBatch, null, null, {questions: questions}, Session.session);
	}

	/**
	 * Count questions matching the params.
	 */
	public static async questionsCount(params: DL50QuestionsCountParams = {}): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.masterdata.questions.count, null, null, params, Session.session)).response.count;
	}

	/**
	 * List questions matching the params.
	 */
	public static async questionsList(params: DL50QuestionsListParams = {}): Promise<DL50QuestionsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.list, null, null, params, Session.session);

		const questions = request.response.questions.map((q: any) => {
			return DL50Question.parse(q);
		});

		return {
			hasMore: request.response.hasMore,
			questions: SortUtils.sortList(questions, 'index', CompareUtils.compareNumber, SortDirection.ASC)
		};
	}

	/**
	 * Delete a question by its UUID.
	 */
	public static async questionDelete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Create a regulatory standard with received data.
	 */
	public static async regulatoryStandardCreate(data: DL50RegulatoryStandard): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.create, null, null, data, Session.session)).response.uuid;
	}

	/**
	 * Update a regulatory standard with received data.
	 */
	public static async regulatoryStandardUpdate(data: DL50RegulatoryStandard): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.update, null, null, data, Session.session);
	}

	/**
	 * Count regulatory standards matching the params.
	 */
	public static async regulatoryStandardsCount(params: DL50RegulatoryStandardsCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.count, null, null, params, Session.session)).response.count;
	}

	/**
	 * List regulatory standards matching the params.
	 */
	public static async regulatoryStandardsList(params: DL50RegulatoryStandardsListParams): Promise<DL50RegulatoryStandardsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			standards: request.response.standards.map((q: any) => { return DL50RegulatoryStandard.parse(q); }),
			id: request.id
		};
	}

	/**
	 * Get regulatory standards in batch by their UUIDs.
	 */
	public static async regulatoryStandardsGetBatch(uuids: UUID[]): Promise<DL50RegulatoryStandardsListResponse[]> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.getBatch, null, null, {uuids: uuids}, Session.session);
		return request.response.standards.map((q: any) => { return DL50RegulatoryStandard.parse(q); });
	}

	/**
	 * Delete a regulatory standard by its UUID.
	 */
	public static async regulatoryStandardDelete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.regulatoryStandards.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Count inspections matching the params.
	 */
	public static async inspectionsCount(params: DL50InspectionsCountParams): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.inspections.count, null, null, params, Session.session)).response.count;
	}
	
	/**
	 * List inspections matching the params.
	 */
	public static async inspectionsList(params: DL50InspectionsListParams): Promise<DL50InspectionsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.inspections.list, null, null, params, Session.session);

		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections
		};
	}

	/**
	 * Validate multiple inspections in batch by their UUIDs.
	 */
	public static async inspectionsValidateBatch(uuids: UUID[]): Promise<void> {
		await Service.fetch(ServiceList.dl50.inspections.validateBatch, null, null, {uuids: uuids}, Session.session);
	}

	/**
	 * Get an inspection by its UUID.
	 */
	public static async inspectionsGet(uuid: UUID): Promise<DL50Inspection> {
		const request = await Service.fetch(ServiceList.dl50.inspections.get, null, null, {uuid: uuid}, Session.session);
		return DL50Inspection.parse(request.response.inspection);
	}

	/**
	 * Get an inspection by question response UUID.
	 */
	public static async inspectionsGetByResponseID(uuid: UUID): Promise<DL50Inspection> {
		const request = await Service.fetch(ServiceList.dl50.inspections.getByResponse, null, null, {uuid: uuid}, Session.session);
		return DL50Inspection.parse(request.response.inspection);
	}

	/**
	 * Delete an inspection by its UUID.
	 */
	public static async inspectionDelete(uuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.dl50.inspections.delete, null, null, {uuid: uuid}, Session.session);
	}

	/**
	 * Create an inspection with received data.
	 */
	public static async inspectionCreate(data: DL50Inspection): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.inspections.create, null, null, data, Session.session)).response.uuid;
	}

	/**
	 * Update an inspection with received data.
	 */
	public static async inspectionUpdate(data: DL50Inspection): Promise<void> {
		await Service.fetch(ServiceList.dl50.inspections.update, null, null, data, Session.session);
	}
}
