import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {sha256} from 'js-sha256';
import {ENTER} from '@angular/cdk/keycodes';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgClass, NgStyle} from '@angular/common';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {UrlUtils} from '../../../../../utils/url-utils';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {App} from '../../../../../app';
import {ValidationUtils} from '../../../../../utils/validation-utils';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoInputComponent} from '../../../../../components/uno/uno-input/uno-input-component';
import {UnoTextComponent} from '../../../../../components/uno/uno-text/uno-text.component';

@Component({
	selector: 'login-box',
	templateUrl: './reset-password-box.component.html',
	styleUrls: ['../login-box/login-box.component.scss'],
	standalone: true,
	imports: [UnoTextComponent, NgClass, NgStyle, UnoInputComponent, FormsModule, UnoButtonComponent, TranslateModule],
	encapsulation: ViewEncapsulation.None
})
export class ResetPasswordBoxComponent implements OnInit {
	/**
	 * Password field.
	 */
	public password: string = '';

	/**
	 * Password confirmation field.
	 */
	public confirmPassword: string = '';

	/**
	 * On initialization prevent the page to be loaded if the query parameter token is missing from the URL.
	 */
	public ngOnInit(): void {
		if (!UrlUtils.getQueryParameters(location.search).token) {
			App.navigator.navigate('login');
		}
	}

	@HostListener('window:keydown', ['$event'])
	public keyDown(event: any): void {
		if (event.which === ENTER) {
			this.resetPassword();
		}
	}

	/**
	 * Reset the user password using the information provided.
	 *
	 * Send the token and new password to the server.
	 */
	public async resetPassword(): Promise<void> {
		if (this.password !== this.confirmPassword) {
			Modal.alert(Locale.get('error'), Locale.get('passwordsDontMatch'));
			return;
		}

		if (!ValidationUtils.validPassword(this.password)) {
			Modal.alert(Locale.get('error'), Locale.get('passwordSizeError'));
			return;
		}

		const parameters = UrlUtils.getQueryParameters(location.search);
		const data = {
			password: sha256(this.password),
			token: parameters.token
		};

		try {
			await Service.fetch(ServiceList.authentication.reset, null, null, data, null);
			App.navigator.navigate('login/login');
			Modal.alert(Locale.get('success'), Locale.get('passwordResetSuccess'));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('passwordResetError'));
		}
	}

	public returnLogin(): void {
		App.navigator.navigate('login/login');
	}
}
