import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {AtexInspectionStatus} from '../../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {DateRange} from '../../../../../../models/date-range';
import {BarChartData, BarChartComponent} from '../../../../../../components/charts/bar-chart/bar-chart.component';
import {UnoDateTimeRangeComponent} from '../../../../../../components/uno-input/uno-date-time-range/uno-date-time-range.component';

@Component({
	selector: 'graph-atex-inspection-stats-done',
	templateUrl: './atex-inspection-daily-status-done.component.html',
	standalone: true,
	imports: [IonicModule, UnoDateTimeRangeComponent, FormsModule, BarChartComponent, NgStyle, TranslateModule]
})
export class AtexInspectionDailyStatusDoneComponent implements OnInit {
	/**
	 * Bar chart component.
	 */
	public data: BarChartData = null;

	/**
	 * Date range for data presented.
	 */
	public range: DateRange = new DateRange();

	public ngOnInit(): void {
		const from: any = new Date();
		from.setDate(from.getDate() - 7);

		this.range.set(from, new Date());

		this.loadData();
	}

	public async loadData(): Promise<void> {
		const data = {
			from: this.range.from,
			to: this.range.to,
			status: AtexInspectionStatus.REVIEW
		};

		const request = await Service.fetch(ServiceList.atex.inspection.countStatus, null, null, data, Session.session, true);
		this.data = new BarChartData(request.response.values);
	}
}
