import {ResourceUtils} from '../../../../utils/resource-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {ProgressBar} from '../../../../progress-bar';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {UUID} from '../../../../models/uuid';
import {RepairStatusLabel} from '../../../../models/repairs/repairs/repair-status';
import {RepairCriticalityLevelLabel} from '../../../../models/repairs/repairs/repair-criticality';
import {RepairLongevityLabel} from '../../../../models/repairs/repairs/repair-longevity';
import {Repair} from '../../../../models/repairs/repairs/repair';

/**
 * Tools to export repair data from the platform.
 */
export class RepairExport {
	/**
	 * Export repair list as XLSX file.
	 */
	public static async exportXLSX(): Promise<void> {

		const headers: {name: string, fields: string[]}[] = [
			{
				name: Locale.get('asset'),
				fields: [Locale.get('uuid'), Locale.get('name'), Locale.get('tag'), Locale.get('manufacturer'), Locale.get('model')]
			},
			{
				name: Locale.get('repair'),
				fields: [
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), Locale.get('description'),
					Locale.get('status'), Locale.get('criticality'), Locale.get('pictures'), Locale.get('technicalDocuments'), Locale.get('latitude'), Locale.get('longitude'), Locale.get('notes'),
					Locale.get('longevity'), Locale.get('expirationDate'), Locale.get('cost'), Locale.get('proposalCompany'), Locale.get('proposalDocuments'), Locale.get('proposalNotes'),
					Locale.get('needsWelding'), Locale.get('handleFluids'), Locale.get('riskAssessmentDocuments'), Locale.get('riskAssessmentNotes'),
					Locale.get('repairOrder'), Locale.get('proposalApprovalNotes'), Locale.get('jobPictures'), Locale.get('jobDocuments'), Locale.get('jobNotes'),
					Locale.get('blockedJustification'), Locale.get('blockedDocuments'), Locale.get('blockedNotes'), Locale.get('rejectionMessage')]
			}
		];

		const data: string[][] = [];

		data.push(headers.reduce(function(acc: any, value: any) {
			return acc.concat([value.name].concat(new Array(value.fields.length - 1).fill('')));
		}, []));

		data.push(headers.reduce(function(acc: any, value: any) {
			return acc.concat(value.fields);
		}, []));


		let from: number = 0;
		const count: number = 300;
		const nd = '';

		const progress = new ProgressBar();
		progress.show();

		const assets: Map<UUID, APAsset> = new Map();

		try {
			const total = (await Service.fetch(ServiceList.repairs.countStatus, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.repairs.listDetailed, null, null, {
						from: from,
						count: count
					}, Session.session, true);
					const repairs = request.response.repairs;

					// Prepare list of assets to be retrieved from database.
					const uuids: UUID[] = [];
					for (let i = 0; i < repairs.length; i++) {
						if (repairs[i].asset && !assets.has(repairs[i].asset)) {
							uuids.push(repairs[i].asset);
						}
					}

					// Retrieve assets from database
					const requestAssets = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: uuids}, Session.session, true);
					for (let i = 0; i < requestAssets.response.assets.length; i++) {
						const asset = requestAssets.response.assets[i];
						assets.set(asset.uuid, APAsset.parse(asset));
					}

					// Export data for all repairs
					for (let i = 0; i < repairs.length; i++) {
						const repair = Repair.parse(repairs[i]);

						let row = [];

						if (repair.asset) {
							const asset = assets.get(repair.asset);
							row.push(asset.uuid, asset.name, asset.tag, asset.manufacturer, asset.model);
						} else {
							row.push(new Array(5).fill(nd));
						}

						row.push(repair.uuid, new Date(repair.createdAt).toLocaleString(Locale.code), new Date(repair.updatedAt).toLocaleString(Locale.code), repair.description, Locale.get(RepairStatusLabel.get(repair.status)), Locale.get(RepairCriticalityLevelLabel.get(repair.criticality)));

						let pictures: string = '';
						if (repair.pictures !== null) {
							for (let k = 0; k < repair.pictures.length; k++) {
								pictures += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.pictures[k]);
							}
						}
						row.push(pictures);

						let technicalDocuments: string = '';
						if (repair.technicalDocuments !== null) {
							for (let k = 0; k < repair.technicalDocuments.length; k++) {
								technicalDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.technicalDocuments[k]);
							}
						}

						row.push(technicalDocuments);

						// GPS position
						row = row.concat(repair.position !== null ? [repair.position.latitude.toString(), repair.position.longitude.toString()] : [nd, nd]);

						row.push(repair.notes, Locale.get(RepairLongevityLabel.get(repair.longevity)), repair.cost !== null ? repair.cost.toString() : '', repair.company);

						let proposalDocuments: string = '';
						if (repair.proposalDocuments !== null) {
							for (let k = 0; k < repair.proposalDocuments.length; k++) {
								proposalDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.proposalDocuments[k]);
							}
						}
						row.push(proposalDocuments);

						row.push(repair.proposalNotes, Locale.get(repair.needsWelding ? 'yes' : 'no'));
						row.push(Locale.get(repair.handleFluids ? 'yes' : 'no'));

						let riskAssessmentDocuments: string = '';
						for (let k = 0; k < repair.riskAssessmentDocuments.length; k++) {
							riskAssessmentDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.riskAssessmentDocuments[k]);
						}
						row.push(riskAssessmentDocuments, repair.riskAssessmentNotes);
						row.push(repair.repairOrder, repair.proposalApprovalNotes);

						let jobPictures: string = '';
						for (let k = 0; k < repair.jobPictures.length; k++) {
							jobPictures += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.jobPictures[k]);

						}
						row.push(jobPictures);

						let jobDocuments: string = '';
						for (let k = 0; k < repair.jobDocuments.length; k++) {
							jobDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.jobDocuments[k]);
						}
						row.push(jobDocuments, repair.jobNotes);
						row.push(repair.blockedJustification);

						let blockedDocuments: string = '';
						for (let k = 0; k < repair.blockedDocuments.length; k++) {
							blockedDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(repair.blockedDocuments[k]);
						}
						row.push(blockedDocuments, repair.blockedNotes, repair.rejectionMessage);
						data.push(row);
					}

					from += repairs.length;

					if (!request.response.hasMore) {
						break;
					}
				}

				XlsxUtils.writeFile(data, 'repairs.xlsx');
			} else {
				Modal.alert(Locale.get('warning'), Locale.get('noDataOnServer'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}
