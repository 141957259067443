import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import {sha256} from 'js-sha256';
import {ENTER} from '@angular/cdk/keycodes';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgClass, NgStyle} from '@angular/common';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {App} from '../../../../../app';
import {ValidationUtils} from '../../../../../utils/validation-utils';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoInputComponent} from '../../../../../components/uno/uno-input/uno-input-component';
import {UnoTextComponent} from '../../../../../components/uno/uno-text/uno-text.component';

@Component({
	selector: 'login-box',
	templateUrl: './login-box.component.html',
	styleUrls: ['./login-box.component.scss'],
	standalone: true,
	imports: [UnoTextComponent, NgClass, NgStyle, FormsModule, UnoInputComponent, UnoButtonComponent, TranslateModule],
	encapsulation: ViewEncapsulation.None
})
export class LoginBoxComponent {
	/**
	 * Email inserted by the user.
	 */
	public email: string = '';

	/**
	 * Password that the user inserted into the input box.
	 *
	 * The password is ciphered before sending it to the API for authentication.
	 */
	public password: string = '';

	/**
	 * Flag to indicate if the login is being validated.
	 */
	public validating: boolean = false;

	@HostListener('window:keydown', ['$event'])
	public keyDown(event: any): void {
		if (event.which === ENTER) {
			this.login();
		}
	}

	/**
	 * Login into the platform using the values input by the user.
	 *
	 * Performs some data validation before submission.
	 */
	public async login(): Promise<void> {
		if (this.validating) {
			return;
		}

		this.validating = true;

		if (!ValidationUtils.isEmail(this.email)) {
			await Modal.alert(Locale.get('error'), Locale.get('invalidEmail'));
			this.validating = false;
			return;
		}

		if (!ValidationUtils.validPassword(this.password)) {
			await Modal.alert(Locale.get('error'), Locale.get('passwordSizeError'));
			this.validating = false;
			return;
		}

		const data = {
			email: this.email,
			password: sha256(this.password)
		};

		let twoFactorAuth = false;

		try {
			const validate = await Service.fetch(ServiceList.authentication.validate, null, null, data, null, false, false);
			twoFactorAuth = validate.response.twoFactorAuth;
		} catch (e) {
			await Modal.alert(Locale.get('error'), Locale.get('invalidLogin'));
			this.validating = false;
			return;
		}
		
		if (twoFactorAuth) {
			App.navigator.navigate('login/code', data);
		} else {
			try {
				const request = await Service.fetch(ServiceList.authentication.login, null, null, data, null, false, false);
				await Session.login(request.response.sessionID, request.response.user);
				App.navigator.navigate('/menu');
				Modal.toast(Locale.get('loginSuccess'));
			} catch (e) {
				await Modal.alert(Locale.get('error'), Locale.get('invalidLogin'));
				this.validating = false;
				return;
			}
		}

		this.validating = false;
	}

	/**
	 * Navigate to the password recovery screen.
	 */
	public recoverPassword(): void {
		App.navigator.navigate('login/recover');
	}
}
