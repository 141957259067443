/**
 * Format to represent time.
 */
export enum TimeFormat {
	/**
	 * Follow the format defined for locale.
	 */
	DEFAULT = 0,

	/**
	 * 24H format.
	 */
	HH24 = 2,

	/**
	 * 12H format.
	 */
	HH12 = 1
}

export const TimeFormatLabel: Map<TimeFormat, String> = new Map<TimeFormat, String>([
	[TimeFormat.DEFAULT, 'default'],
	[TimeFormat.HH24, 'H24'],
	[TimeFormat.HH12, 'H12']
]);
