import {Locale} from 'src/app/locale/locale';
import {Session} from 'src/app/session';
import {XlsxSheetData, XlsxUtils} from 'src/app/utils/xlsx-utils';
import {ProgressBar} from 'src/app/progress-bar';
import {GAGap} from 'src/app/models/gap-analysis/gaps/gap';
import {ServiceList} from '../../../../http/service-list';
import {Service} from '../../../../http/service';
import {Resource} from '../../../../models/resource';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {UUID} from '../../../../models/uuid';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {GAGapRecommendation} from '../../../../models/gap-analysis/gaps/gap-recommendation';
import {GAGapPriorityLabel} from '../../../../models/gap-analysis/gaps/gap-priority';
import {GAGapStatusLabel as GAGapStatusLabel} from '../../../../models/gap-analysis/gaps/gap-status';
import {ResourceUtils} from '../../../../utils/resource-utils';

export class GAGapExport {
	/**
	 * Export gaps and gaps recommendations list as XLSX file.
	 *
	 * @param filters - The filters to apply to the list of gaps to export
	 * @param gapUuids - A list of gaps UUIDs to export from the filtered list
	 */
	public static async exportXLSX(filters: any = {}, gapUuids: UUID[] = []): Promise<void> {
		const progress = new ProgressBar();
		progress.show();
		
		// Gaps sheet header
		const gapsHeader: any[] = [
			Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
			Locale.get('description'), 
			Locale.get('status'), Locale.get('priority'), Locale.get('financialImpact'),
			Locale.get('documents'),
			Locale.get('inspectionDataUuid'), Locale.get('inspectionFieldUuid'), Locale.get('fieldLabel'), Locale.get('fieldText'),
			Locale.get('projectUuid'), Locale.get('project'),
			Locale.get('assetUuid'), Locale.get('assetName'), Locale.get('assetTag'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag')
		];

		try {
			// Get gaps from API as well as asset, project and recommendations count info
			let gapsComposed: any[] = (await Service.fetch(ServiceList.gapAnalysis.gap.list, null, null, filters, Session.session, true, false)).response.gaps;
			
			// If in selection mode, filter the gaps to export
			if (gapUuids.length > 0) {
				// Update the list of gaps to export
				gapsComposed = gapsComposed.filter((gap: GAGap) => { return gapUuids.includes(gap.uuid);});
			}

			const assetUuids: UUID[] = [];

			// A map to store composed gaps by its UUIDs
			const gapsComposedMap: Map<UUID, any> = new Map<UUID, any>();

			// Fetch all the assets UUIDs to get them in batch
			for (let i = 0; i < gapsComposed.length; i++) {
				gapsComposedMap.set(gapsComposed[i].uuid, gapsComposed[i]);

				if (gapsComposed[i].asset?.uuid) {
					assetUuids.push(gapsComposed[i].asset.uuid);
				}
			}

			const gapsComposedData: any[][] = [];

			let assets: APAsset[] = await AssetService.getBatch(assetUuids);
			
			// Get all the assets parent assets
			const parentAssetUuids: UUID[] = [];
			for (let i = 0; i < assets.length; i++) {
				if (assets[i].parentUuid) {
					parentAssetUuids.push(assets[i].parentUuid);
				}
			}

			assets = assets.concat(await AssetService.getBatch(parentAssetUuids));
			
			// A map to store assets by its UUIDs
			const assetsMap: Map<UUID, APAsset> = new Map<UUID, APAsset>();
			for (let i = 0; i < assets.length; i++) {
				assetsMap.set(assets[i].uuid, assets[i]);
			}

			for (let i = 0; i < gapsComposed.length; i++) {
				const c: any = gapsComposed[i];

				const row: any[] = [
					c.uuid, c.createdAt.toLocaleString(Locale.code), c.updatedAt.toLocaleString(Locale.code),
					c.description,
					Locale.get(GAGapStatusLabel.get(c.status)), Locale.get(GAGapPriorityLabel.get(c.priority)), c.financialImpact,
					c.documents ? '[' + c.documents.map((d: Resource) => {return ResourceUtils.getURL(d); }).join(', ') + ']' : '[]',
					c.inspectionDataUuid, c.inspectionField.uuid, c.inspectionField.label, c.inspectionField.text,
					c.project.uuid, c.project.name
				];

				if (c.asset) {
					const asset: APAsset = assetsMap.get(c.asset.uuid);

					row.push(c.asset.uuid, c.asset.name, c.asset.tag);

					if (asset.parentUuid) {
						// Find asset parent info
						const parentAsset: APAsset = assetsMap.get(asset.parentUuid);
						
						if (parentAsset) {
							row.push(parentAsset.uuid, parentAsset.name, parentAsset.tag);
						}
					} else {
						// Parent asset UUID, name and tag
						row.push('', '', '');
					}
				} else {
					// Asset UUID, name and tag
					row.push('', '', '');
					
					// Parent asset UUID, name and tag
					row.push('', '', '');
				}
	
				gapsComposedData.push(row);
			}

			const gapsSheetData: XlsxSheetData = {
				name: Locale.get('gaps'),
				data: [gapsHeader, ...gapsComposedData]
			};

			progress.update(Locale.get('loadingData'), 1 / 4);

			// Get gap recommendations from API
			const recommendationsFilters = {gapUuids: gapsComposed.map((c: any) => { return c.uuid; })};
			const reqRecommendations = await Service.fetch(ServiceList.gapAnalysis.gap.recommendations.list, null, null, recommendationsFilters, Session.session, true, false);
			const recommendations: GAGapRecommendation[] = reqRecommendations.response.recommendations.map((d: any) => { return GAGapRecommendation.parse(d); });

			// Gaps recommendations sheet header
			const gapRecommendationsHeader: any[] = [
				Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
				Locale.get('gapUuid'), 
				Locale.get('description'), 
				Locale.get('quantity'), Locale.get('units'), Locale.get('cost'),
				Locale.get('documents')
			];

			const recommendationsData: any[] = recommendations.map((recommendation: GAGapRecommendation): any[] => {
				return [
					recommendation.uuid, recommendation.createdAt.toLocaleString(Locale.code), recommendation.updatedAt.toLocaleString(Locale.code),
					recommendation.gapUuid,
					recommendation.description,
					recommendation.quantity, recommendation.units, recommendation.cost,
					recommendation.documents ? '[' + recommendation.documents.map((d: Resource) => {return ResourceUtils.getURL(d); }).join(', ') + ']' : '[]'
				];
			});

			const recommendationsSheetData: XlsxSheetData = {
				name: Locale.get('recommendations'),
				data: [gapRecommendationsHeader, ...recommendationsData]
			};
			
			progress.update(Locale.get('loadingData'), 2 / 4);

			// Gaps and recommendations (kinda) merged sheet header
			const mergedSheetHeader: any[] = [
				Locale.get('gapUuid'), 
				Locale.get('gapDescription'), 
				Locale.get('status'), Locale.get('priority'), Locale.get('financialImpact'),
				Locale.get('gapDocuments'),
				Locale.get('inspectionDataUuid'), Locale.get('inspectionFieldUuid'), Locale.get('fieldLabel'), Locale.get('fieldText'),
				Locale.get('projectUuid'), Locale.get('project'),
				Locale.get('assetUuid'), Locale.get('assetName'), Locale.get('assetTag'),
				Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
				Locale.get('recommendationUuid'),
				Locale.get('recommendationDescription'), 
				Locale.get('quantity'), Locale.get('units'), Locale.get('cost'),
				Locale.get('recommendationDocuments')
			];

			// Merged data with all the rows data
			const mergedData: any[][] = [];
			for (let i = 0; i < recommendations.length; i++) {
				const recommendation: GAGapRecommendation = recommendations[i];
				
				// Find the gap associated to this recommendation
				const gapComposed: any = gapsComposedMap.get(recommendation.gapUuid);
				
				// Gap data
				const gapRowData: any[] = [
					gapComposed.uuid,
					gapComposed.description,
					Locale.get(GAGapStatusLabel.get(gapComposed.status)), Locale.get(GAGapPriorityLabel.get(gapComposed.priority)), gapComposed.financialImpact,
					gapComposed.documents ? '[' + gapComposed.documents.map((d: Resource) => {return ResourceUtils.getURL(d); }).join(', ') + ']' : '[]',
					gapComposed.inspectionDataUuid, gapComposed.inspectionField.uuid, gapComposed.inspectionField.label, gapComposed.inspectionField.text,
					gapComposed.project.uuid, gapComposed.project.name
				];

				if (gapComposed.asset) {
					gapRowData.push(gapComposed.asset.uuid, gapComposed.asset.name, gapComposed.asset.tag);

					// Find parent info
					const asset: APAsset = assetsMap.get(gapComposed.asset.uuid);

					if (asset.parentUuid) {
						// Find asset parent info
						const parentAsset: APAsset = assetsMap.get(asset.parentUuid);
						
						if (parentAsset) {
							gapRowData.push(parentAsset.uuid, parentAsset.name, parentAsset.tag);
						}
					} else {
						// Parent asset UUID, name and tag
						gapRowData.push('', '', '');
					}
				} else {
					// Asset UUID, name and tag
					gapRowData.push('', '', '');
					
					// Parent asset UUID, name and tag
					gapRowData.push('', '', '');
				}
				
				// Recommendation data
				const recommendationRowData: any[] = [
					recommendation.uuid,
					recommendation.description,
					recommendation.quantity, recommendation.units, recommendation.cost,
					recommendation.documents ? '[' + recommendation.documents.map((d: Resource) => {return ResourceUtils.getURL(d); }).join(', ') + ']' : '[]'
				];

				mergedData.push([...gapRowData, ...recommendationRowData]);
			}
			
			const mergedSheetData: XlsxSheetData = {
				name: Locale.get('gapRecommendations'),
				data: [mergedSheetHeader, ...mergedData]
			};

			progress.update(Locale.get('loadingData'), 3 / 4);

			const workBookSheets: XlsxSheetData[] = [gapsSheetData, recommendationsSheetData, mergedSheetData];
			XlsxUtils.writeMultiSheetFile(workBookSheets, 'gaps.xlsx');
		} catch {}

		progress.update(Locale.get('loadingData'), 4 / 4);

		progress.destroy();
	}
}
