<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.repair !== null) {
			<uno-tab-section [active]="true" title="{{'repair' | translate}}">
				<!-- Rejected message -->
				@if ((this.repair.status === this.status.PROPOSAL || this.repair.status === this.status.ON_GOING) && this.repair.rejectionMessage !== undefined && this.repair.rejectionMessage.length > 0) {
					<uno-dynamic-form [editable]="false" [layout]="this.layout.rejectionMessage" [object]="this.repair"></uno-dynamic-form>
				}
				<!-- Base Form -->
				<uno-dynamic-form [layout]="this.layout.base" [object]="this.repair" #baseForm></uno-dynamic-form>
				<!-- Repair inspections -->
				@if (this.createMode) {
					<uno-title>{{'inspections' | translate}}</uno-title>
					<uno-dynamic-form [layout]="this.layout.calendarEvent" [object]="this.inspectionCalendarEvent"></uno-dynamic-form>
				}
				@if (!this.createMode) {
					<!-- Proposal Form -->
					<uno-dynamic-form [layout]="this.layout.proposal" [object]="this.repair" #proposalForm></uno-dynamic-form>
					<!-- Handle Fluids Form -->
					<uno-dynamic-form [layout]="this.layout.handleFluids" [object]="this.repair" #handleFluidsForm></uno-dynamic-form>
					<!-- Weldings -->
					<uno-dynamic-form [layout]="this.layout.needWelding" [object]="this.repair" #weldingForm></uno-dynamic-form>
					<!-- Non Destructive Tests form -->
					<uno-dynamic-form [layout]="this.layout.nonDestructiveTests" [object]="this.repair" #ndtForm></uno-dynamic-form>
					<!-- Proposal Approval Form -->
					@if (this.repair.status >= this.status.WAITING_PROPOSAL_APPROVAL) {
						<uno-dynamic-form [layout]="this.layout.proposalApproval" [object]="this.repair" #proposalApprovalForm></uno-dynamic-form>
						<!-- Export Proposal Doc -->
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<uno-button (click)="this.exportProposalDOCX();">{{'exportProposal' | translate}}</uno-button>
							<uno-button (click)="this.exportProposalPDF();">{{'exportProposalPDF' | translate}}</uno-button>
						</div>
					}
					<!-- Blocked Form -->
					@if (this.repair.status === this.status.BLOCKED || (this.repair.status >= this.status.ON_GOING && this.repair.blockedJustification !== '')) {
						<uno-dynamic-form [layout]="this.layout.blocked" [object]="this.repair" #blockedForm></uno-dynamic-form>
					}
					<!-- Job Form -->
					@if (this.repair.status >= this.status.ON_GOING && this.repair.status < this.status.BLOCKED) { <uno-dynamic-form [layout]="this.layout.job" [object]="this.repair" #jobForm></uno-dynamic-form>
					}
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save / Create -->
					<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
					<!-- Update / Submit -->
					@if (!this.createMode) {
						<uno-button (click)="this.update(false, true);">{{'update' | translate}}</uno-button>
						<!-- Edit / Update -->
						@if (this.repair.status === this.status.PROPOSAL) {
							<uno-button (click)="this.update(true);" color="success">{{'submit' | translate}}</uno-button>
						}
						@if (this.repair.status === this.status.ON_GOING) {
							<uno-button (click)="this.update(true);" color="success">{{'finish' | translate}}</uno-button>
						}
						<!-- Approve / Reject -->
						@if (this.repair.status === this.status.WAITING_PROPOSAL_APPROVAL || this.repair.status === this.status.QUALITY_ASSURANCE) {
							<uno-button (click)="this.approve(true);" color="success">{{'approve' | translate}}</uno-button>
							<uno-button (click)="this.approve(false);" color="warning">{{'reject' | translate}}</uno-button>
						}
						<!-- Block -->
						@if ((userPermissions.REPAIR_BLOCK | hasPermissions) && this.repair.status === this.status.ON_GOING) {
							<uno-button (click)="this.block();" color="warning">{{'block' | translate}}</uno-button>
						}
						<!-- Unblock -->
						@if ((userPermissions.REPAIR_UNLOCK | hasPermissions) && this.repair.status === this.status.BLOCKED) {
							<uno-button (click)="this.update(true);" color="success">{{'unlock' | translate}}</uno-button>
						}
						<!-- Delete -->
						@if ([userPermissions.REPAIR_DELETE] | hasPermissions) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
						<!-- Archive -->
						@if (this.repair.status !== this.status.ARCHIVED && ([userPermissions.REPAIR_ARCHIVE] | hasPermissions)) {
							<uno-button (click)="this.update(false, false, this.status.ARCHIVED);" color="warning">{{'archive' | translate}}</uno-button>
						}
						<!-- Unarchive -->
						@if (this.repair.status === this.status.ARCHIVED && ([userPermissions.REPAIR_UNARCHIVE] | hasPermissions)) {
							<uno-button (click)="this.update(false, true, this.status.IMPLEMENTED);" color="success">{{'unarchive' | translate}}</uno-button>
						}
					}
					@if (this.repair.status === this.status.IMPLEMENTED) {
						<uno-button (click)="this.exportCompleteRepairDOCX();">{{'exportReportDOCX' | translate}}</uno-button>
						<uno-button (click)="this.exportCompleteRepairPDF();">{{'exportReportPDF' | translate}}</uno-button>
					}
				</div>
			</uno-tab-section>
		}

		@if (this.repair.status === this.status.IMPLEMENTED && ([userPermissions.REPAIR_INSPECTIONS] | hasPermissions)) {
			<uno-tab-section title="{{'inspections' | translate}}">
				<!-- Repair Inspections -->
				<uno-title>{{'inspections' | translate}}</uno-title>
				<ion-list class="ion-no-padding ion-no-margin">
					@if (this.inspections.length === 0) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
							<!-- Empty list placeholder -->
							<ion-label>
								<p>{{'noInspectionsToShow' | translate}}</p>
							</ion-label>
						</ion-item>
					}
					@if (this.inspections.length > 0) {
						@for (option of this.inspections; track option) {
							<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
								<ion-label>
									@if (option.date) {
										<h2>{{'date' | translate}}: {{option.date | formatDate}}</h2>
									}
									<h2>{{'result' | translate}}: {{inspectionResultLabel.get(option.result) | translate}}</h2>
									<h3>{{'status' | translate}}: {{inspectionStatusLabel.get(option.status) | translate}}</h3>
								</ion-label>
								<uno-button (click)="app.navigator.navigate('/menu/repairs/inspections/edit', {uuid: option.uuid});">{{'inspection' | translate}}</uno-button>
							</ion-item>
						}
					}
				</ion-list>
				<!-- New inspection creation button -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.createInspection();">{{'createNewInspection' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}

		@if (this.asset !== null) {
			<uno-tab-section title="{{'asset' | translate}}">
				<!-- Asset Form -->
				<uno-title>{{'asset' | translate}}</uno-title>
				<uno-dynamic-form [editable]="false" [layout]="assetsLayout" [object]="this.asset"></uno-dynamic-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid});">{{ 'asset' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}

		@if (!this.createMode) {
			<uno-tab-section class="repair-calendar-event-table-tab" [active]="false" title="{{'planning' | translate}}">
				<!-- Events -->
				<uno-title>{{'events' | translate}}</uno-title>
				<div class="repair-calendar-event-table-buttons-bar">
					<uno-button color="primary" type="fill" size="medium" (click)="this.createEvent();">{{'createEvent' | translate}}</uno-button>
				</div>
				<div class="repair-calendar-event-table">
					<!-- The header of the table -->
					<div class="repair-calendar-event-table-header">
						<span class="repair-calendar-event-table-header-column">{{'name' | translate}}</span>
						<!-- The date the event starts -->
						<span class="repair-calendar-event-table-header-column">{{'date' | translate}}</span>
						<!-- The date this calendar event was created -->
						<span class="repair-calendar-event-table-header-column">{{'createdAt' | translate}}</span>
					</div>
					<!-- Table content -->
					<div class="repair-calendar-event-table-content">
						@if (this.calendarEvents.length === 0) {
							<div class="repair-calendar-event-table-no-content">
								<uno-no-data></uno-no-data>
							</div>
						}
						@for (event of this.calendarEvents; track event) {
							<div class="repair-calendar-event-table-content-list-item">
								<div class="repair-calendar-event-table-content-list-item-inner">
									<span class="repair-calendar-event-table-content-list-item-inner-column" (click)="this.editEvent(event)">{{event.name}}</span>
									<span class="repair-calendar-event-table-content-list-item-inner-column" (click)="this.editEvent(event)">{{this.getDate(event.date)}}</span>
									<span class="repair-calendar-event-table-content-list-item-inner-column" (click)="this.editEvent(event)">{{this.getDate(event.createdAt)}}</span>
									<uno-icon class="repair-calendar-event-table-content-list-item-delete-icon" color="var(--brand-primary)" [src]="'assets/icons/material/delete/outline.svg'" [width]="25" [height]="32" (click)="this.deleteEvent(event)"></uno-icon>
								</div>
							</div>
						}
					</div>
				</div>
			</uno-tab-section>
		}

		@if (this.history && this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<uno-title>{{'history' | translate}}</uno-title>
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option; let i = $index) {
						<ion-item (click)="this.openHistoryEntry(this.history[i].id);" button class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.date | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{option.user.name}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.user.email + (option.user.phone.length > 0 ? (' | ' + option.user.phone) : '')}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
