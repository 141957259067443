<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Add plant button -->
		<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/pipeline-integrity/plant/edit', {createMode: true})">
			@if (app.device.isDesktop()) {
				{{'create' | translate}}
			}
		</uno-button>
		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	</div>

	<!-- Filters -->
	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-responsive-table-list [layout]="this.layout" [loadMore]="this.loadMore" [totalItems]="this.totalItems" [selectable]="false" [pageSize]="this.tablePageSize" [rowClickable]="true" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/pipeline-integrity/plant/map', {uuid: $event.element.uuid.value});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</uno-content>
