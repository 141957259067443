@if (this.block) {
	<uno-content [borders]="true">
		<!-- Base Form -->
		<uno-dynamic-form [layout]="this.formTemplateBlockLayout" [object]="this.block" #formTemplateBlockForm></uno-dynamic-form>
		<!-- Form Template Fields -->
		<uno-title>{{'fields' | translate}}</uno-title>
		@if (this.block.fields.length > 0) {
			@for (field of this.block.fields; track field; let i = $index) {
				<uno-dynamic-form [layout]="this.formTemplateFieldLayout" [object]="field" #formTemplateFieldForms></uno-dynamic-form>
				<!-- Each Form Template Field buttons -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.removeField(field);" color="error">{{'delete' | translate}}</uno-button>
					@if (i < (this.block.fields.length - 1)) { <uno-button (click)="this.moveFieldDown(field);">
							<ion-icon name="caret-down-outline"></ion-icon>
						</uno-button>
					}
					@if (i > 0) {
						<uno-button (click)="this.moveFieldUp(field);">
							<ion-icon name="caret-up-outline"></ion-icon>
						</uno-button>
					}
				</div>
			}
		}
		@if (this.block.fields.length === 0) {
			<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
				<ion-label>
					<p class="ion-margin-horizontal">{{'noFieldsSet' | translate}}</p>
				</ion-label>
			</ion-item>
		}
		<!-- Add Field -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<uno-button (click)="this.addField();">{{'addField' | translate}}</uno-button>
		</div>
		<!-- Submit buttons -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<!-- Save / Create -->
			<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
			<!-- Update / Delete -->
			@if (!this.createMode) {
				@if ([userPermissions.ASSET_PORTFOLIO_FORM_BLOCK_EDIT] | hasPermissions) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				@if ([userPermissions.ASSET_PORTFOLIO_FORM_BLOCK_DELETE] | hasPermissions) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	</uno-content>
}
