
/**
 * Possible types of item to present in tree.
 */
export enum UnoTreeLayoutType {
	/**
	 * Title of the item.
	 */
	TITLE = 1,

	/**
	 * Label of the item.
	 */
	LABEL = 2,

	/**
	 * Picture of the item.
	 */
	PICTURE = 3
}


/**
 * Layout used to present a item in the tree.
 */
export class UnoTreeLayout {
	/**
	 * Type of the element to be displayed.
	 */
	public type: UnoTreeLayoutType;

	/**
	 * The name of the attribute that will be mapped to this columns from the object.
	 *
	 * Attribute can refer to a sub-object or index (e.g a.b.c)
	 */
	public attribute: string;
}
