import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

export class AtexInspectionChecklistService {
	/**
	 * Load inspection checklists from the API server.
	 */
	public static async get(): Promise<any> {
		const request = await Service.fetch(ServiceList.atex.inspection.checklistsGet, null, null, null, Session.session);

		return request.response;
	}

}
