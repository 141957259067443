import {Component, OnInit} from '@angular/core';
import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {InspectionReportADN} from 'src/client-custom/adn/inspection-report-adn';
import {InspectionWizardADN} from 'src/client-custom/adn/inspection-wizard-adn';
import {RepairExport} from '../../repairs/repair-work/data/repair-export';
import {Session} from '../../../session';
import {App} from '../../../app';
import {Environment} from '../../../../environments/environment';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {QRTools} from '../../qr/data/qr-tools';
import {AtexInspectionTools} from '../../atex/inspections/data/atex-inspection-tools';
import {FFPTools} from '../../atex/ffp/data/ffp-tools';
import {EPIExport} from '../../epi/data/epi-export';
import {ActionPlanExport} from '../../atex/action-plan/data/action-plan-export';
import {FFPWizard} from '../../atex/ffp/data/ffp-wizard';
import {FFPExport} from '../../atex/ffp/data/ffp-export';
import {AtexInspectionExport} from '../../atex/inspections/data/atex-inspection-export';
import {EPIImport} from '../../epi/data/epi-import';
import {AtexInspectionWizard} from '../../atex/inspections/data/atex-inspection-wizard';
import {RepairImport} from '../../repairs/repair-work/data/repair-import';
import {InspectionExport} from '../../inspections/data/inspection/inspection-export';
import {APAssetExport} from '../../asset-portfolio/data/asset-export';
import {APAssetImport} from '../../asset-portfolio/data/asset-import';
import {InspectionAverageExportADN} from '../../../../client-custom/adn/inspection-export-adn';
import {FormBlockDataTools} from '../../asset-portfolio/data/asset-form-block-export';
import {AssetPortfolioStructureDataTools} from '../../asset-portfolio/data/asset-portfolio-structure-export';
import {RepairInspectionExport} from '../../repairs/repair-inspections/data/repair-inspection-export';
import {GAGapExport} from '../../gap-analysis/gaps/data/gap-export';
import {UserExport} from '../../users/data/user-export';
import {UnoButtonComponent} from '../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';
import {InspectionWorkflowImport} from '../../inspections/data/workflow/inspection-workflow-import';
import {InspectionTools} from '../../inspections/data/inspection/inspection-tools';
import {PermissionsPipe} from '../../../pipes/permissions.pipe';

class DataToolsSection {
	/**
	 * Title of the tool section.
	 */
	public title: string;

	/**
	 * List of permissions required to view this section (only one is required).
	 */
	public permissions: number[];

	/**
	 * Options available in the section.
	 */
	public options: DataToolsOption[];
}

class DataToolsOption {
	/**
	 * Label/title of the option entry.
	 */
	public label: string;

	/**
	 * Description of the tool functionality displayed bellow the label.
	 */
	public description: string;

	/**
	 * List of permissions required to use this tool (only one is required to access the tool).
	 */
	public permissions: number[];

	/**
	 * Text to be displayed in the button.
	 */
	public buttons?: DataToolOptionButton[] = [];

	/**
	 * Flag to display or hide the tool card.
	 */
	public available: boolean;
}

class DataToolOptionButton {
	/**
	 * The text label to present on button.
	 */
	public label?: string;

	/**
	 * Method to execute the tool.
	 */
	public onClick: ()=> void;

	/**
	 * Style of the button.
	 */
	public color?: string;
}

@Component({
	selector: 'data-page',
	templateUrl: './data-tools.page.html',
	standalone: true,
	imports: [UnoContentComponent, IonicModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class DataToolsPage extends ScreenComponent implements OnInit {
	public get environment(): any { return Environment; }

	public get session(): any { return Session; }

	public options: DataToolsSection[] = [
		{
			title: 'import',
			permissions: [],
			options: [
				{
					label: 'importAssetsXlsx',
					description: 'importAssetsXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_IMPORT_ASSETS_XLSX],
					buttons: [
						{
							label: 'selectFile',
							onClick: APAssetImport.importAssetsXLSX
						},
						{
							label: 'template',
							onClick: APAssetImport.exportAssetsTemplateXlsx
						}
					],
					available: true
				},
				{
					label: 'importFormBlocksXlsx',
					description: 'importFormBlocksXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_FORM_BLOCK_IMPORT],
					buttons: [
						{
							label: 'selectFile',
							onClick: async() => {
								await FormBlockDataTools.importXLSX();
							}
						},
						{
							label: 'template',
							onClick: () => {return XlsxUtils.writeMultiSheetFile([FormBlockDataTools.blocksSheetData(), FormBlockDataTools.fieldsSheetData()], 'form-blocks-template.xlsx');}
						},
						{
							label: 'help',
							onClick: () => {return Modal.alert(Locale.get('additionalInformations'), Locale.get('importFormBlocksXlsxAdditionalInformation'));}
						}
					],
					available: true
				},
				{
					label: 'repairsXlsx',
					description: 'importRepairsXlsxSub',
					permissions: [UserPermissions.REPAIR_IMPORT_XLSX],
					buttons: [
						{onClick: RepairImport.importXLSX}
					],
					available: true
				},
				{
					label: 'epiXlsx',
					description: 'importEpiXlsx',
					permissions: [UserPermissions.EPI_IMPORT_XLSX],
					buttons: [
						{onClick: EPIImport.importXLSX}
					],
					available: true
				},
				{
					label: 'importInspectionWorkflowJSON',
					description: 'importInspectionWorkflowJSONSub',
					permissions: [UserPermissions.INSPECTION_WORKFLOW_IMPORT],
					buttons: [
						{
							label: 'selectFile',
							onClick: async() => {
								await InspectionWorkflowImport.importJSON();
							}
						}
					],
					available: true
				}
			]
		},
		{
			title: 'export',
			permissions: [],
			options: [
				{
					label: 'assetsJson',
					description: 'exportAssetsJsonSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_EXPORT_JSON],
					buttons: [
						{
							label: 'export',
							onClick: APAssetExport.exportJSON
						}
					],
					available: true
				},
				{
					label: 'exportAssetsDetailedXlsx',
					description: 'exportAssetsDetailedXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: APAssetExport.exportDetailedXLSX
						}
					],
					available: true
				},				
				{
					label: 'assetsXlsx',
					description: 'exportAssetsXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: APAssetExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'exportFormBlocksXlsx',
					description: 'exportFormBlocksXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_FORM_BLOCK_EXPORT],
					buttons: [
						{
							label: 'export',
							onClick: FormBlockDataTools.exportAllXLSX
						}
					],
					available: true
				},
				{
					label: 'exportAssetPortfolioStructureXlsx',
					description: 'exportAssetPortfolioStructureXlsxSub',
					permissions: [UserPermissions.ASSET_PORTFOLIO_STRUCTURE_EXPORT],
					buttons: [
						{
							label: 'export',
							onClick: AssetPortfolioStructureDataTools.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsJson',
					description: 'exportAtexInspectionsJson',
					permissions: [UserPermissions.ATEX_INSPECTION_EXPORT_JSON],
					buttons: [
						{
							label: 'export',
							onClick: AtexInspectionExport.exportJSON
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsXlsx',
					description: 'exportAtexInspectionsXlsxSub',
					permissions: [UserPermissions.ATEX_INSPECTION_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: AtexInspectionExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsXlsxResults',
					description: 'exportAtexInspectionsXlsxResultsSub',
					permissions: [UserPermissions.ATEX_INSPECTION_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: AtexInspectionExport.exportXLSXResults
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsReports',
					description: 'atexInspectionsReportsSub',
					permissions: [UserPermissions.ATEX_INSPECTION_EXPORT_REPORTS_BULK],
					buttons: [
						{
							label: 'export',
							onClick: AtexInspectionExport.exportReportsDocx
						}
					],
					available: true
				},
				{
					label: 'exportRepairInspectionsReportsBulk',
					description: 'exportRepairInspectionsReportsBulkSub',
					permissions: [UserPermissions.REPAIR_INSPECTIONS_EXPORT_REPORTS_BULK],
					buttons: [
						{
							label: 'export',
							onClick: RepairInspectionExport.exportReportsBulk
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsAuditXlsx',
					description: 'exportAtexInspectionsAuditXlsxSub',
					permissions: [UserPermissions.ATEX_INSPECTION_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: AtexInspectionExport.exportXLSXAuditUserHistory
						}
					],
					available: true
				},

				{
					label: 'epiJson',
					description: 'exportEPIJsonSub',
					permissions: [UserPermissions.EPI_EXPORT_JSON],
					buttons: [
						{
							label: 'export',
							onClick: EPIExport.exportJSON
						}
					],
					available: true
				},
				{
					label: 'epiXlsx',
					description: 'exportEPIXlsxSub',
					permissions: [UserPermissions.EPI_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: EPIExport.exportExcel
						}
					],
					available: true
				},
				{
					label: 'ffpJson',
					description: 'exportFFPJsonSub',
					permissions: [UserPermissions.FFP_EXPORT_JSON],
					buttons: [
						{
							label: 'export',
							onClick: FFPExport.exportJSON
						}
					],
					available: true
				},
				{
					label: 'ffpXlsx',
					description: 'exportFFPXlsxSub',
					permissions: [UserPermissions.FFP_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: FFPExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'actionPlanXlsx',
					description: 'exportActionPlanXlsxSub',
					permissions: [UserPermissions.ACTION_PLAN_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: ActionPlanExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'repairsXlsx',
					description: 'exportRepairsXlsxSub',
					permissions: [UserPermissions.REPAIR_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: RepairExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'inspectionsXlsx',
					description: 'exportInspectionsXlsxSub',
					permissions: [UserPermissions.INSPECTION_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: InspectionExport.exportAllXLSX
						}
					],
					available: true
				},
				{
					label: 'userXlsx',
					description: 'exportInspectionsUserSub',
					permissions: [UserPermissions.USER_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: UserExport.exportUserXLSX
						}
					],
					available: true
				},
				{
					label: 'inspectionsJson',
					description: 'exportInspectionsJsonSub',
					permissions: [UserPermissions.INSPECTION_EXPORT_JSON],
					buttons: [
						{
							label: 'export',
							onClick: InspectionExport.exportJSON
						}
					],
					available: true
				},
				{
					label: 'exportGapsXlsx',
					description: 'exportGapsXlsxSub',
					permissions: [UserPermissions.GA_GAP_EXPORT],
					buttons: [
						{
							label: 'export',
							onClick: GAGapExport.exportXLSX
						}
					],
					available: true
				},
				{
					label: 'exportInspectionsReportsBulk',
					description: 'exportInspectionsReportsBulkSub',
					permissions: [UserPermissions.INSPECTION_EXPORT_REPORTS_BULK],
					buttons: [
						{
							label: 'export',
							onClick: InspectionReportADN.exportReportsBulk
						}
					],
					available: !Environment.PRODUCTION || Environment.CLIENT_ID === 'adn' || Environment.CLIENT_ID === 'adn-uat'
				},
				{
					label: 'inspectionsAverageXlsx',
					description: 'exportInspectionsAverageXlsxSub',
					permissions: [UserPermissions.INSPECTION_EXPORT_XLSX],
					buttons: [
						{
							label: 'export',
							onClick: InspectionAverageExportADN.exportXLSX
						}
					],
					available: !Environment.PRODUCTION || Environment.CLIENT_ID === 'adn' || Environment.CLIENT_ID === 'adn-uat'
				}
			]
		},
		{
			title: 'tools',
			permissions: [],
			options: [

				{
					label: 'runGapAnalysis',
					description: 'runGapAnalysisSub',
					permissions: [],
					buttons: [
						{
							label: 'run',
							onClick: InspectionTools.runGapAnalysis
						}
					],
					available: Session.user.isAdmin
				},
				{
					label: 'bulkActionPlansFFP',
					description: 'bulkActionPlansFFPSub',
					permissions: [UserPermissions.ACTION_PLAN_CREATE_BULK],
					buttons: [
						{
							label: 'run',
							onClick: FFPWizard.bulkActionPlansByTagFields
						}
					],
					available: true
				},
				{
					label: 'atexInspectionsBulkFields',
					description: 'atexInspectionsBulkFieldsSub',
					permissions: [UserPermissions.ATEX_INSPECTION_FIELDS_EDIT_BULK],
					buttons: [
						{
							label: 'run',
							onClick: AtexInspectionWizard.changeFieldResponseBulk
						}
					],
					available: true
				},
				{
					label: 'checkAtexInspectionNoForms',
					description: 'checkAtexInspectionNoFormsSub',
					permissions: [UserPermissions.ATEX_INSPECTION_CHECKLIST_CHECK],
					buttons: [
						{
							label: 'run',
							onClick: AtexInspectionTools.checkNoFormsSelected
						}
					],
					available: true
				},
				{
					label: 'bulkDeleteFFPUnused',
					description: 'bulkDeleteFFPUnusedSub',
					permissions: [UserPermissions.FFP_DELETE_BULK_TOOL],
					buttons: [
						{
							label: 'delete',
							onClick: FFPTools.bulkDeleteUnusedFFP,
							color: 'error'
						}
					],
					available: true
				},
				{
					label: 'checkAtexInspectionsEmptyFields',
					description: 'checkAtexInspectionsEmptyFieldsSub',
					permissions: [UserPermissions.ATEX_INSPECTION_CHECKLIST_CHECK],
					buttons: [
						{
							label: 'run',
							onClick: AtexInspectionTools.checkMissingFields
						}
					],
					available: true
				},
				{
					label: 'updateReports',
					description: 'updateReportsSub',
					permissions: [],
					buttons: [
						{
							label: 'run',
							onClick: InspectionWizardADN.updateReportWizard
						}
					],
					available: !Environment.PRODUCTION || Environment.CLIENT_ID === 'adn' || Environment.CLIENT_ID === 'adn-uat'
				}
			]
		},
		{
			title: 'qrCode',
			permissions: [UserPermissions.TOOLS_QR_GENERATE, UserPermissions.TOOLS_QR_GENERATE_LOGO, UserPermissions.TOOLS_QR_GENERATE_LOGO_ZIP],
			options: [
				{
					label: 'generateQRTags',
					description: 'generateQRTagsSub',
					permissions: [UserPermissions.TOOLS_QR_GENERATE],
					buttons: [
						{
							label: 'generate',
							onClick: async() => {
								const config: any = await QRTools.getConfig();
								QRTools.generate(config);
							}
						}
					],
					available: true
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('importExport');
	}
}
