<!-- Header -->
<div class="menu-desktop-header" [ngClass]="{'menu-desktop-sidebar-expanded': this.expanded, 'menu-desktop-sidebar-collapsed': !this.expanded}">
	<div class="menu-desktop-header-start">
		<div class="menu-desktop-breadcrumbs-container">
			<img class="menu-desktop-img menu-desktop-arrow-back" src="../assets/components/menu/arrow-left.svg" (click)="app.navigator.pop();" [ngClass]="{'menu-desktop-darken': session.settings.theme=='dark'}" />

			@for (path of app.navigator.breadcrumbs(); track path) {
				<label class="menu-desktop-breadcrumbs" (click)="app.navigator.rollback(path);">
					{{path.title | translate}} /
				</label>
			}
		</div>
		<p class="menu-desktop-current-location">{{app.navigator.title() | translate}}</p>

	</div>
	<div class="menu-desktop-header-end">
		<div class="menu-desktop-label">
			@if (session.user) {
				<label class="menu-desktop-username-label"> {{session.user.name}} </label>
				<label class="menu-desktop-email-label"> {{session.user.email}} </label>
			}
		</div>

		<div style="margin: 5px;">
			<user-avatar></user-avatar>
		</div>
	</div>
</div>

<!-- Content -->
<div class="menu-desktop-content" [ngClass]="{'menu-desktop-sidebar-expanded': this.expanded, 'menu-desktop-sidebar-collapsed': !this.expanded}">
	<router-outlet></router-outlet>
</div>

<!-- Side bar -->
<div #sidebar class="menu-desktop-sidebar" [ngClass]="{'menu-desktop-expanded': this.expanded,'menu-desktop-collapsed': !this.expanded, 'menu-desktop-dark' : session.settings.theme=='dark'}">
	<!-- Logo -->
	<div class="menu-desktop-logo-container" (click)="this.toggle()">
		<img [src]="this.imgSmall ? '../assets/components/menu/uno-symbol.svg' : '../assets/components/menu/uno-logo.svg'" class="menu-desktop-img menu-desktop-sidebar-logo" [ngClass]="{'menu-desktop-small': this.imgSmall}" />
	</div>

	<!-- Menu items -->
	<div class="menu-desktop-sidebar-options-list" [ngClass]="{'menu-desktop-collapsed': !this.expanded, 'menu-desktop-dark' : session.settings.theme=='dark'}">
		@for (page of this.pages; track page) {
			@if (page.available && (page.permissions | hasPermissions) && (page.module | moduleCheck)) {

				@if (this.expanded) {
					<!-- Expanded -->
					<div class="menu-desktop-sidebar-option-expanded" (click)="app.navigator.navigate(page.route, page.data);" [ngClass]="{'active': menuOption.highlighted(page), 'menu-desktop-dark-mode': session.settings.theme==='dark'}">
						<uno-icon color="light" class="menu-desktop-img menu-desktop-sidebar-option-icon-expanded" [width]="30" [height]="30" src="{{page.icon}}"></uno-icon>
						<label class="menu-desktop-sidebar-option-label">{{page.label | translate}} </label>
					</div>
				} @else {
					<!-- Collapsed -->
					<div class="menu-desktop-sidebar-option-collapsed" (click)="app.navigator.navigate(page.route, page.data);" uno-tooltip uno-tooltip-text="{{page.label | translate}}" [ngClass]="{'active': menuOption.highlighted(page)}">
						<uno-icon color="light" class="menu-desktop-sidebar-option-icon-collapsed" [width]="30" [height]="30" src="{{page.icon}}"></uno-icon>
					</div>
				}
			}
		}
	</div>

	<!-- Expand button -->
	<div class="menu-desktop-sidebar-end" (click)="this.toggle()">
		<div class="menu-desktop-img menu-desktop-toggle-arrow" [ngStyle]="{'background-color': session.settings.theme==='dark' ? 'var(--gray-11)' : 'var(--brand-primary-hover)'}" [ngClass]="{'menu-desktop-rotate': this.expanded}">
			<uno-icon [src]="'../assets/components/menu/toggle-arrow.svg'" [width]="32" [height]="32"></uno-icon>
		</div>
	</div>
</div>
