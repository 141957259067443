/**
 * List of possible inspection final conclusion evaluations for the DL50 inspections.
 */
export enum DL50InspectionConclusion {
	/**
	 * No conclusion yet.
	 */
	NONE = 0,
	
	/**
	 * The inspection is OK.
	 */
	OK = 1,
	
	/**
	 * The inspection has light gaps.
	 */
	GAPS_LIGHT = 2,
	
	/**
	 * The inspection has severe gaps.
	 */
	GAPS_SEVERE = 3,
	
	/**
	 * The inspection is not OK.
	 */
	NOK = 4,
}

/**
 * List of possible inspection final conclusion evaluations labels for the DL50 inspections.
 */
export const DL50InspectionFinalConclusionLabel: Map<number, string> = new Map([
	[DL50InspectionConclusion.NONE, 'none'],
	[DL50InspectionConclusion.OK, 'gapsOk'],
	[DL50InspectionConclusion.GAPS_LIGHT, 'gapsLight'],
	[DL50InspectionConclusion.GAPS_SEVERE, 'gapsSevere'],
	[DL50InspectionConclusion.NOK, 'gapsNok']
]);

/**
 * Map with colors related with each conclusion value.
 */
export const DL50InspectionFinalConclusionColors: Map<number, string> = new Map<number, string>([
	[DL50InspectionConclusion.NONE, 'var(--gray-6)'],
	[DL50InspectionConclusion.OK, 'var(--success-normal)'],
	[DL50InspectionConclusion.GAPS_LIGHT, 'var(--warning-normal)'],
	[DL50InspectionConclusion.GAPS_SEVERE, 'var(--warning-normal)'],
	[DL50InspectionConclusion.NOK, 'var(--error-normal)']
]);
