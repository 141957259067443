import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {DL50MasterSettings} from 'src/app/models/dl50/masterdata/dl50-master-settings';
import {Session} from 'src/app/session';

export class Dl50MasterSettingsService {
	/**
	 * Get the DL50 Master Settings.
	 */
	public static async get(): Promise<DL50MasterSettings> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.masterSettings.get, null, null, null, Session.session, true);

		return DL50MasterSettings.parse(request.response.settings);
	}

	/**
	 * Update the DL50 master settings in the api.
	 *
	 * @param settings - Settings to apply in the api. 
	 */
	public static async update(settings: DL50MasterSettings): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.masterSettings.update, null, null, settings, Session.session, true);
	}
}
