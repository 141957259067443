import {DL50InspectionConclusion} from './dl50-inspection-conclusion';

export type DL50InspectionConclusionFilterType = typeof DL50InspectionConclusionFilter[keyof typeof DL50InspectionConclusionFilter];

/**
 * List of possible inspection final conclusion evaluation filters for the DL50 inspections.
 */
export enum DL50InspectionConclusionFilter {
	/**
	 * No specific conclusion filter.
	 */
	ALL = -1,
	
	/**
	 * No conclusion yet.
	 */
	NONE = DL50InspectionConclusion.NONE,
	
	/**
	 * The inspection is OK.
	 */
	OK = DL50InspectionConclusion.OK,
	
	/**
	 * The inspection has light gaps.
	 */
	GAPS_LIGHT = DL50InspectionConclusion.GAPS_LIGHT,
	
	/**
	 * The inspection has severe gaps.
	 */
	GAPS_SEVERE = DL50InspectionConclusion.GAPS_SEVERE,
	
	/**
	 * The inspection is not OK.
	 */
	NOK = DL50InspectionConclusion.NOK,
}

/**
 * List of possible inspection final conclusion evaluation filters label for the DL50 inspections.
 */
export const DL50InspectionConclusionFilterLabel: Map<number, string> = new Map([
	[DL50InspectionConclusionFilter.ALL, 'all'],
	[DL50InspectionConclusionFilter.NONE, 'none'],
	[DL50InspectionConclusionFilter.OK, 'gapsOk'],
	[DL50InspectionConclusionFilter.GAPS_LIGHT, 'gapsLight'],
	[DL50InspectionConclusionFilter.GAPS_SEVERE, 'gapsSevere'],
	[DL50InspectionConclusionFilter.NOK, 'gapsNok']
]);
