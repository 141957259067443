import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {Company} from '../../../models/companies/company';

export class CompanyService {

	/**
	 * Get a company by its uuid.
	 *
	 * @param companyUUID - The UUID of the Company.
	 */
	public static async get(companyUUID: UUID): Promise<Company> {
		const request = await Service.fetch(ServiceList.company.get, null, null, {uuid: companyUUID}, Session.session);
		return Company.parse(request.response.company);
	}
}
