import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoSearchbarComponent} from 'src/app/components/uno/uno-searchbar/uno-searchbar.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {DL50InspectionStatus, DL50InspectionStatusLabel, DL50InspectionStatusType} from 'src/app/models/dl50/dl50-inspection-status';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {SortDirection} from 'src/app/utils/sort-direction';
import {Session} from 'src/app/session';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {Inspection} from 'src/app/models/inspections/inspection/inspection';
import {DL50InspectionFinalConclusionColors} from 'src/app/models/dl50/dl50-inspection-conclusion';
import {DL50InspectionConclusionFilter, DL50InspectionConclusionFilterLabel} from 'src/app/models/dl50/dl50-inspection-conclusion-filter';
import {DL50InspectionsListParams, DL50InspectionsListResponse, DL50Service} from '../../../services/dl50.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * Page used to list dl50 inspections.
 */
@Component({
	selector: 'dl50-inspections-list-page',
	templateUrl: 'dl50-inspections-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoContentComponent,
		TranslateModule,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent,
		PermissionsPipe
	]
})
export class DL50InspectionsListPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50];

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public get session(): any {return Session;}

	public get userPermissions(): any {return UserPermissions;}

	public get app(): any { return App; }

	public get inspectionStatus(): any { return DL50InspectionStatus; }

	public get selfStatic(): any { return DL50InspectionsListPage; }

	// The status to filter the list by
	public status: DL50InspectionStatusType = DL50InspectionStatus.ALL;

	/**
	 * The count of items to show on table component with applied filters.
	 */
	public totalItems: number = 0;

	/**
 	 * The number of items per page on table.
 	 */
	public tablePageSize: number = 30;

	/**
	 * The table rows that are checked.
	 */
	public checkedInspections: any[] = [];

	/**
 	 * The layout of the table.
 	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.isVisible('image'), size: 'small'},
		{header: 'conclusion', type: UnoTableColumnType.STATUS, attribute: 'finalNotesConclusion', visible: this.isVisible('finalNotesConclusion'), size: 'small'},
		{header: 'uuid', type: UnoTableColumnType.TEXT, attribute: 'uuid', visible: this.isVisible('uuid'), size: 'small', sortBy: '[dl50_inspection].[id]'},
		{header: 'createdAt', type: UnoTableColumnType.TEXT, attribute: 'createdAt', visible: this.isVisible('createdAt'), size: 'small', sortBy: '[dl50_inspection].[created_at]'},
		{header: 'updatedAt', type: UnoTableColumnType.TEXT, attribute: 'updatedAt', visible: this.isVisible('updatedAt'), size: 'small', sortBy: '[dl50_inspection].[updated_at]'},
		{header: 'label', type: UnoTableColumnType.TEXT, attribute: 'label', visible: this.isVisible('label'), size: 'small', sortBy: '[dl50_inspection].[label]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.isVisible('description'), size: 'small', sortBy: '[dl50_inspection].[description]'},
		{header: 'assetUuid', type: UnoTableColumnType.TEXT, attribute: 'assetUuid', visible: this.isVisible('assetUuid'), size: 'small', sortBy: '[ap_asset].[id]'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: this.isVisible('assetName'), size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: this.isVisible('assetTag'), size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: this.isVisible('status'), size: 'small', sortBy: '[dl50_inspection].[status]'},		
		{header: 'gaps', type: UnoTableColumnType.TEXT, attribute: 'gaps', visible: this.isVisible('gaps'), size: 'small'},
		{
			header: 'actions', 
			type: UnoTableColumnType.ICONS, 
			attribute: 'actions', 
			visible: this.isVisible('actions'), 
			size: 'small', 
			icons: [
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row: any): void => {
						App.openInTab('/menu/dl50/inspections/edit', {uuid: row.uuid.value});
					}
				}
			]
		}
	];

	/**
	 * Table filters for the list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[dl50_inspection].[updated_at]',
			options: [
				{label: 'uuid', value: '[dl50_inspection].[id]'},
				{label: 'createdAt', value: '[dl50_inspection].[created_at]'},
				{label: 'updatedAt', value: '[dl50_inspection].[updated_at]'},
				{label: 'label', value: '[dl50_inspection].[label]'},
				{label: 'description', value: '[dl50_inspection].[description]'},
				{label: 'status', value: '[dl50_inspection].[status]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[dl50_inspection].[label]', '[dl50_inspection].[description]'],
			multiple: true,
			options: [
				{label: 'uuid', value: '[dl50_inspection].[id]'},
				{label: 'label', value: '[dl50_inspection].[label]'},
				{label: 'description', value: '[dl50_inspection].[description]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'tableFields',
			label: 'tableFields',
			multiple: true,
			default: [
				'image',
				'assetName',
				'assetTag',
				'status',
				'finalNotesConclusion',
				'gaps',
				'actions',
				'label'
			],
			options: [
				{label: 'image', value: 'image'},
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'status', value: 'status'},
				{label: 'conclusion', value: 'finalNotesConclusion'},
				{label: 'gaps', value: 'gaps'},
				{label: 'label', value: 'label'},
				{label: 'description', value: 'description'},
				{label: 'uuid', value: 'uuid'},
				{label: 'assetUuid', value: 'assetUuid'},
				{label: 'createdAt', value: 'createdAt'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'actions', value: 'actions'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'finalNotesConclusion',
			label: 'conclusion',
			multiple: false,
			default: DL50InspectionConclusionFilter.ALL,
			options: Array.from(DL50InspectionConclusionFilterLabel.keys()).map((value) => {return {label: DL50InspectionConclusionFilterLabel.get(value), value: value};})
		}
	];

	/**
	 * The inspection status filter to present only on DL50InspectionStatus.ALL status list
	 */
	public static statusFilterOption: UnoFilterBarOption = {
		type: UnoFilterBarOptionType.OPTIONS,
		attribute: 'status',
		label: 'status',
		multiple: false,
		default: DL50InspectionStatus.ALL,
		options: Array.from(DL50InspectionStatusLabel.keys()).map((value) => {return {label: DL50InspectionStatusLabel.get(value), value: value};})
	};

	/**
	 * Currently selected filters from the filter bar.
	 */
	public static selectedFilters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list.
		 */
		sortDirection: '',

		/**
		 * Column to sort the values by.
		 */
		sortField: '',

		/**
		 * Text used to filter items.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Table columns to be shown.
		 */
		tableFields: [],

		/**
		 * The status of the inspections to list.
		 */
		status: DL50InspectionStatus.ALL,
		
		/**
		 * The final conclusion value of the inspections to list.
		 */
		finalNotesConclusion: DL50InspectionConclusionFilter.ALL
	}, DL50InspectionsListPage.filterOptions);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		// Read route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.status = data?.status !== undefined ? data.status : DL50InspectionStatus.ALL;
		DL50InspectionsListPage.selectedFilters.status = this.status;

		// Load items
		this.totalItems = await DL50Service.inspectionsCount({
			status: DL50InspectionsListPage.selectedFilters.status,
			finalNotesConclusion: DL50InspectionsListPage.selectedFilters.finalNotesConclusion,
			search: DL50InspectionsListPage.selectedFilters.search,
			searchFields: DL50InspectionsListPage.selectedFilters.searchFields
		});

		App.navigator.setTitle('inspections');
	}

	/**
	 * Check if a column is visible.
	 * 
	 * @param attribute - The collumn attribute.
	 * @returns True if the attribute is in the visible list of table fields filter.
	 */
	public isVisible(attribute: string): boolean {
		return DL50InspectionsListPage.selectedFilters.tableFields.indexOf(attribute) !== -1;
	}

	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const params: DL50InspectionsListParams = {
			status: DL50InspectionsListPage.selectedFilters.status,
			finalNotesConclusion: DL50InspectionsListPage.selectedFilters.finalNotesConclusion,
			from: count,
			count: pageSize,
			search: DL50InspectionsListPage.selectedFilters.search,
			sortDirection: DL50InspectionsListPage.selectedFilters.sortDirection,
			sortField: DL50InspectionsListPage.selectedFilters.sortField,
			searchFields: DL50InspectionsListPage.selectedFilters.searchFields
		};

		const request: DL50InspectionsListResponse = await DL50Service.inspectionsList(params);

		const rows: any[] = [];

		for (const inspection of request.inspections) {
			const row: any = {
				image: inspection.asset?.pictures?.length > 0 ? inspection.asset.pictures[0] : null,
				uuid: inspection.uuid,
				createdAt: new Date(inspection.createdAt).toLocaleDateString(Locale.code, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}),
				updatedAt: new Date(inspection.updatedAt).toLocaleDateString(Locale.code, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}),
				label: inspection.label,
				description: inspection.description,
				status: Locale.get(DL50InspectionStatusLabel.get(inspection.status)),
				finalNotesConclusion: DL50InspectionFinalConclusionColors.get(inspection.finalNotesConclusion),
				gaps: inspection.gaps,
				assetUuid: inspection.asset?.uuid ? inspection.asset.uuid : '',
				assetName: inspection.asset?.name ? inspection.asset.name : '',
				assetTag: inspection.asset?.tag ? inspection.asset.tag : ''
			};


			rows.push(row);
		}

		return {
			elements: rows,
			hasMore: request.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(event: any): Promise<void> {
		DL50InspectionsListPage.selectedFilters = event;

		for (const column of this.layout) {
			column.visible = this.isVisible(column.attribute);
		};

		this.table.sortDirection = DL50InspectionsListPage.selectedFilters.sortDirection;
		this.table.sortField = DL50InspectionsListPage.selectedFilters.sortField;

		await this.reloadTableData();
	}
	
	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (DL50InspectionsListPage.selectedFilters.search !== event) {
			DL50InspectionsListPage.selectedFilters.search = event;
			await this.reloadTableData();
		}
	}

	/**
	 * Reloads table data.
	 */
	public async reloadTableData(): Promise<void> {
		this.totalItems = await DL50Service.inspectionsCount({
			status: DL50InspectionsListPage.selectedFilters.status,
			finalNotesConclusion: DL50InspectionsListPage.selectedFilters.finalNotesConclusion,
			search: DL50InspectionsListPage.selectedFilters.search,
			searchFields: DL50InspectionsListPage.selectedFilters.searchFields
		});

		this.table.reset();
	}

	/**
	 * Change the sort direction/field used in the list.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public sortChanged(sortBy: string): void {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === DL50InspectionsListPage.selectedFilters.sortField) {
			DL50InspectionsListPage.selectedFilters.sortDirection = this.table.sortDirection;
		} else {
			DL50InspectionsListPage.selectedFilters.sortField = sortBy;
			DL50InspectionsListPage.selectedFilters.sortDirection = SortDirection.ASC;
		}

		this.table.reset();
	}

	/**
	 * Update inspections in bulk from the validation status to the client feedback status.
	 * 
	 * @param inspections - The rows data related to the inspections to update.
	 */
	public async validateInspections(inspections: any[]): Promise<void> {
		if (inspections.length === 0 || !Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_EDIT)) { return; }

		// Confirm validation
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmInspectionValidation'));
		if (confirm) {
			try {
				await DL50Service.inspectionsValidateBatch(inspections.map((data: any) => { return data.uuid.value; }));
				Modal.toast(Locale.get('success'), 3000, 'success');
				await this.reloadTableData();
			} catch {
				Modal.toast(Locale.get('error'), 3000, 'danger');
			}
		}		
	}

	/**
	 * Sets the checkedInspections variable to be the same as the table checked rows.
	 * 
	 * @param event - The selected rows on the table
	 */
	public async checkRows(inspections: (Inspection[] | boolean)): Promise<void> {
		if (typeof inspections === 'boolean' && inspections) {
			const params: DL50InspectionsListParams = {
				status: DL50InspectionsListPage.selectedFilters.status,
				finalNotesConclusion: DL50InspectionsListPage.selectedFilters.finalNotesConclusion,
				search: DL50InspectionsListPage.selectedFilters.search,
				sortDirection: DL50InspectionsListPage.selectedFilters.sortDirection,
				sortField: DL50InspectionsListPage.selectedFilters.sortField,
				searchFields: DL50InspectionsListPage.selectedFilters.searchFields
			};
	
			const request: DL50InspectionsListResponse = await DL50Service.inspectionsList(params);

			this.checkedInspections = request.inspections;
		} else if (typeof inspections === 'boolean' && !inspections) {
			this.checkedInspections = [];
		} else {
			this.checkedInspections = inspections as Inspection[];
		}
	}
}
