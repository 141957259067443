import {UUID} from '../../uuid';

/**
 * A plant can have various pipelines.
 * 
 * A pipeline is usually a tube where only one type of matter flow. It can be a pipeline for Gaz or Oil for example.
 */
export class Pipeline {

	/**
	 * UUID of the pipeline.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the pipeline.
	 */
	public name: string = '';

	/**
	 * Tag of the pipeline.
	 */
	public tag: string = '';

	/**
	 * Description of the pipeline.
	 */
	public description: string = '';

	/**
	 * Color of the pipeline.
	 * 
	 * Stored as string in CSS hexadecimal format as #RRGGBB.
	 */
	public color: string = '#1990F9';

	/**
	 * UUID of the plant this pipeline belongs to.
	 */
	public plantUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved pipeline object.
	 *
	 * @param data - Pipeline object to parse data
	 */
	public static parse(data: any): Pipeline {
		const pipeline = new Pipeline();

		pipeline.uuid = data.uuid;
		pipeline.name = data.name;
		pipeline.description = data.description;
		pipeline.plantUuid = data.plantUuid;
		pipeline.tag = data.tag;
		pipeline.color = data.color || '#1990F9';

		return pipeline;
	}
}

