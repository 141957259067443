import {CompanyService} from 'src/app/modules/companies/services/companies.service';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {FileUtils} from '../../../../utils/file-utils';
import {Locale} from '../../../../locale/locale';
import {Company} from '../../../../models/companies/company';
import {DocxUtils} from '../../../../utils/docx-utils';
import {AssetReport} from '../../../asset-portfolio/data/asset-report';
import {RepairSettings} from '../master-data/repair-settings';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {RepairStatusLabel} from '../../../../models/repairs/repairs/repair-status';
import {RepairCriticalityLevelLabel} from '../../../../models/repairs/repairs/repair-criticality';
import {RepairLongevityLabel} from '../../../../models/repairs/repairs/repair-longevity';
import {ResourceUtils} from '../../../../utils/resource-utils';

/**
 * Generate report document for repairs. These reports are generated using data obtained from the API and based on docx templates.
 */
export class RepairReport {
	/**
	 * Path of the default report template.
	 */
	public static defaultReportURL: string = 'assets/template/repair-report.docx';

	/**
	 * Path of the default report template.
	 */
	public static defaultProposalURL: string = 'assets/template/repair-proposal.docx';

	/**
	 * Generate repair proposal document using a docx template.
	 *
	 * @param repair - Repair object to generate the report.
	 * @param template - Docx template file.
	 */
	public static async generateProposalDocx(repair: Repair, template?: ArrayBuffer): Promise<ArrayBuffer> {
		const settings = await RepairSettings.load();

		if (!template) {
			const url = ResourceUtils.getURL(settings.proposalTemplate, RepairReport.defaultProposalURL);
			template = await FileUtils.readFileArrayBuffer(url);
		}

		// Asset
		let asset: APAsset = null;
		if (repair.asset !== null) {
			asset = await AssetService.get(repair.asset);
		}

		const data = {
			// Asset
			asset: asset,
			assetData: asset ? AssetReport.getAssetData(asset) : null,
			assetImages: asset ? AssetReport.getAssetImages(asset) : null,

			// Repair
			repair: repair,
			repairData: RepairReport.getRepairData(repair),
			repairImages: repair.pictures,

			// Proposal
			repairProposalCompany: await RepairReport.getRepairProposalCompany(repair),
			repairProposalData: RepairReport.getRepairProposalData(repair),

			// Locale
			text: Locale.translations.get(Locale.code),
			date: new Date().toLocaleString(Locale.code)
		};

		return DocxUtils.generateDocxFromTemplate(template, data);
	}

	/**
	 * Generate repair document with all data available, using a docx template.
	 *
	 * @param repair - Repair object to generate the report.
	 * @param template - Docx template file.
	 */
	public static async generateCompleteRepairDocx(repair: Repair, template?: ArrayBuffer): Promise<ArrayBuffer> {
		const settings = await RepairSettings.load();

		if (!template) {
			const url = ResourceUtils.getURL(settings.reportTemplate, RepairReport.defaultReportURL);
			template = await FileUtils.readFileArrayBuffer(url);
		}

		// Asset
		let asset: APAsset = null;
		if (repair.asset !== null) {
			asset = await AssetService.get(repair.asset);
		}

		const data = {
			// Asset
			asset: asset,
			assetData: asset ? AssetReport.getAssetData(asset) : null,
			assetImages: asset ? AssetReport.getAssetImages(asset) : null,

			// Repair
			repair: repair,
			repairData: RepairReport.getRepairData(repair),
			repairImages: repair.pictures,

			// Proposal
			repairProposalCompany: await RepairReport.getRepairProposalCompany(repair),
			repairProposalData: RepairReport.getRepairProposalData(repair),
			repairProposalApprovalData: RepairReport.getRepairProposalApprovalData(repair),

			// Job
			jobData: RepairReport.getRepairJobData(repair),
			jobImages: repair.jobPictures
		};

		return await DocxUtils.generateDocxFromTemplate(template, data);
	}

	/**
	 * Get data from the repair job phase.
	 *
	 * @param repair - Repair to get the data from.
	 */
	public static getRepairJobData(repair: Repair): {attr: string, value: any}[] {
		return [
			{attr: Locale.get('notes'), value: repair.jobNotes}
		];
	}

	/**
	 * Get data from the repair proposal approval phase.
	 *
	 * @param repair - Repair to get the data from.
	 */
	public static getRepairProposalApprovalData(repair: Repair): {attr: string, value: any}[] {
		return [
			{attr: Locale.get('repairOrder'), value: repair.repairOrder},
			{attr: Locale.get('notes'), value: repair.proposalApprovalNotes}
		];
	}

	/**
	 * Get data from the company in the repair proposal phase.
	 *
	 * @param repair - Repair to get the data from.
	 */
	public static async getRepairProposalCompany(repair: Repair): Promise<{attr: string, value: any}[]> {
		let proposalCompany: {attr: string, value: any}[] = null;

		if (repair.company) {
			try {
				const company: Company = await CompanyService.get(repair.company);

				proposalCompany = [
					{attr: Locale.get('name'), value: company.name},
					{attr: Locale.get('email'), value: company.email},
					{attr: Locale.get('phoneNumber'), value: company.phoneNumber},
					{attr: Locale.get('address'), value: company.address}
				];
			} catch (e) {}
		}

		return Promise.resolve(proposalCompany);
	}

	/**
	 * Get data relative to the repair proposal phase.
	 *
	 * @param repair - Repair to get the data from.
	 */
	public static getRepairProposalData(repair: Repair): {attr: string, value: any}[] {
		const proposalData: {attr: string, value: any}[] = [
			{attr: Locale.get('longevity'), value: Locale.get(RepairLongevityLabel.get(repair.longevity))},
			{attr: Locale.get('cost'), value: repair.cost},
			{attr: Locale.get('notes'), value: repair.proposalNotes}
		];
		proposalData.push({attr: Locale.get('needsWelding'), value: repair.needsWelding ? Locale.get('yes') : Locale.get('no')});
		proposalData.push({attr: Locale.get('handleFluids'), value: repair.handleFluids ? Locale.get('yes') : Locale.get('no')});

		if (repair.handleFluids && repair.riskAssessmentNotes !== '') {
			proposalData.push({attr: Locale.get('riskAssessmentNotes'), value: repair.riskAssessmentNotes});
		}

		return proposalData;
	}

	/**
	 * Get Repair base data.
	 *
	 * @param repair - Repair to get the data from.
	 */
	public static getRepairData(repair: Repair): {attr: string, value: any}[] {
		return [
			{attr: Locale.get('createdAt'), value: new Date(repair.createdAt).toLocaleString(Locale.code)},
			{attr: Locale.get('status'), value: Locale.get(RepairStatusLabel.get(repair.status))},
			{attr: Locale.get('criticality'), value: Locale.get(RepairCriticalityLevelLabel.get(repair.criticality))},
			{attr: Locale.get('position'), value: repair.position !== null ? repair.position.latitude + ',' + repair.position.longitude : Locale.get('nd')},
			{attr: Locale.get('notes'), value: repair.notes}
		];
	}
}
