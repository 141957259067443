@if (this.company) {
	<uno-content [borders]="true">
		<uno-title>{{'company' | translate}}</uno-title>
		<uno-dynamic-form [editable]="this.canEdit()" [layout]="this.layout" [object]="this.company" #companyForm></uno-dynamic-form>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<!-- Save / Create -->
			<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>
			<!-- Update / Delete -->
			@if (!this.createMode) {
				@if (userPermissions.COMPANY_EDIT | hasPermissions) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				@if (userPermissions.COMPANY_DELETE | hasPermissions) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	</uno-content>
}
