<ion-grid class="ion-no-margin ion-no-padding">
	<ion-row class="ion-justify-content-end ion-align-items-end">
		<!-- Active -->
		@if (!this.disabled) {
			<ion-col class="ion-justify-content-center ion-align-items-center">
				@if (this.multiple) {
					@for (resource of $any(this.value); track resource) {
						<ion-row class="ion-justify-content-center ion-align-items-center" size="auto" style="vertical-align: middle; text-align: center; width: 150px;">
							<!-- Document information -->
							<ion-button mode="ios" (click)="this.downloadFile(resource);" expand="block" fill="none" style="width: 110px; min-height: 80px; max-height: 190px">
								<div>
									<ion-icon name="document" style="cursor: pointer; font-size: 30px;"></ion-icon>
									<br />
									@if (!this.local) {
										<ion-label>{{$any(resource).description}}</ion-label>
									} @else {
										<ion-label>{{$any(resource).name}}</ion-label>
									}
								</div>
							</ion-button>
							<!-- Delete button -->
							<ion-button mode="ios" (click)="this.remove(resource)" expand="block" fill="outline">
								<ion-label slot="end">{{'delete' | translate }}</ion-label>
							</ion-button>
						</ion-row>
					}
				} @else {
					<ion-row class="ion-justify-content-center ion-align-items-center" size="auto" style="vertical-align: middle; text-align: center; width: 150px;">
						<!-- Document information -->
						@if (this.value) {
							<ion-button mode="ios" (click)="this.downloadFile($any(this.value));" expand="block" fill="none" style="width: 110px; min-height: 80px; max-height: 190px">
								<div>
									<ion-icon name="document" style="cursor: pointer; font-size: 30px;"></ion-icon>
									<br />
									@if (!this.local) {
										<ion-label>{{$any(this.value).description}}</ion-label>
									} @else {
										<ion-label>{{$any(this.value).name}}</ion-label>
									}
								</div>
							</ion-button>

							<!-- Delete button -->
							<ion-button mode="ios" (click)="this.remove($any(this.value))" expand="block" fill="outline">
								<ion-label slot="end">{{'delete' | translate }}</ion-label>
							</ion-button>
						}
					</ion-row>
				}

				@if (this.multiple || !this.value) {
					<!-- Add Documents -->
					<ion-row class="ion-justify-content-center ion-align-items-center" size="auto" style="vertical-align: middle; text-align: center;">
						<ion-button mode="ios" (click)="this.selectFiles()" expand="block" fill="none" style="width: 110px; min-height: 80px; max-height: 190px">
							<div>
								<ion-icon name="document" style="cursor: pointer; font-size: 50px;"></ion-icon>
								<br />
								<ion-label>{{'selectFile' | translate }}</ion-label>
							</div>
						</ion-button>
					</ion-row>
				}
			</ion-col>
		}
		@else {
			@if (this.multiple) {
				@for (resource of $any(this.value); track resource) {
					<ion-col size="auto" style="width: 150px;">
						@if (!this.local) {
							<ion-text (click)="this.downloadFile(resource);" style="cursor: pointer;">{{$any(resource).description}}</ion-text>
						} @else {
							<ion-text (click)="this.downloadFile(resource);" style="cursor: pointer;">{{$any(resource).name}}</ion-text>
						}
					</ion-col>
				}
			} @else if(this.value) {
				@if (!this.local) {
					<ion-text (click)="this.downloadFile($any(this.value));" style="cursor: pointer;">{{$any(this.value).description}}</ion-text>
				} @else {
					<ion-text (click)="this.downloadFile($any(this.value));" style="cursor: pointer;">{{$any(this.value).name}}</ion-text>
				}
			}

		}

		<!-- Sample file -->
		@if (this.sampleFile) {
			<ion-button mode="ios" (click)="fileUtils.download(this.sampleFile);" expand="block" fill="outline" style="max-width: 120px;">
				<ion-label slot="end">{{'sampleFile' | translate }}</ion-label>
			</ion-button>
		}
	</ion-row>
</ion-grid>
