export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'repsol-uat',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.335.24',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'inspections',
		'observations',
		'repairs'
	],
	TIMESTAMP: '2024-07-25T14:25:23.994Z',
	COMMIT: 'fcc86b6281c1933b7a37ac4dfaa4f689e6e08480',
	BRANCH: 'task/AM-2855-script-copy-db',
	API_SERVER: 'https://repsol-uat-inspections-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://repsol-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
