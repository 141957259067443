import {Session} from 'src/app/session';
import {SortDirection} from 'src/app/utils/sort-direction';
import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {CalendarEventAlarmType, CalendarEventAlarmTypeLabel} from '../../../../models/asset-planning/calendar-event-alarm-type';
import {CalendarEventTriggerDirection, CalendarEventTriggerDirectionLabel} from '../../../../models/asset-planning/calendar-event-trigger-direction';
import {AtexInspectionResult, AtexInspectionResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-result';
import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {AtexInspectionStatus, AtexInspectionStatusLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-status';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {DateFrequencyPrecision} from '../../../../models/date-frequency';

export class AtexInspectionLayouts {
	/**
	 * Supervisor message form layout.
	 */
	public static supervisorMessage: UnoFormField[] = [
		{
			label: 'message',
			attribute: 'data.supervisorMessage',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * Inspection rejected message form layout.
	 */
	public static rejectedMessage: UnoFormField[] = [
		{
			label: 'message',
			attribute: 'data.rejectionMessage',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * Form portion to select the correct result for the inspection and fill notes.
	 */
	public static inspection: UnoFormField[] = [
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID
		},
		{
			required: false,
			attribute: 'teamUuid',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			showClear: true
		},
		{
			required: true,
			attribute: 'assetUuid',
			label: 'asset',
			type: UnoFormFieldTypes.ASSET_SELECTOR,
			showClear: false,
			editable: function(object, row) {
				return object.status === AtexInspectionStatus.TODO;
			}
		},
		{
			label: 'waitingDocuments',
			attribute: 'waitingDocuments',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			label: 'liftingEquipmentRequired',
			attribute: 'liftingEquipmentRequired',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			label: 'resultInspection',
			attribute: 'result',
			sort: false,
			type: UnoFormFieldTypes.OPTIONS,
			required: true,
			isEmpty: function(object) {
				return object.result === AtexInspectionResult.NONE;
			},
			options: Object.values(AtexInspectionResult).map(function(value) {
				return {value: value, label: AtexInspectionResultLabel.get(value)};
			})
		},
		{
			label: 'resultFinal',
			attribute: 'resultFinal',
			sort: false,
			type: UnoFormFieldTypes.OPTIONS,
			required: true,
			isActive: function(object: any, row: UnoFormField) {
				return object.status === AtexInspectionStatus.REVIEW || object.status === AtexInspectionStatus.COMPLETED;
			},
			isEmpty: function(object) {
				return object.resultFinal === AtexInspectionResult.NONE;
			},
			options: Object.values(AtexInspectionResult).map(function(value) {
				return {value: value, label: AtexInspectionResultLabel.get(value)};
			})
		},
		{
			label: 'notes',
			attribute: 'data.notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			label: 'photo',
			attribute: 'data.photo',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			label: 'video',
			attribute: 'data.video',
			type: UnoFormFieldTypes.VIDEO_RESOURCE
		},
		{
			label: 'audio',
			attribute: 'data.audio',
			type: UnoFormFieldTypes.AUDIO_RESOURCE
		}
	];

	/**
	 * Form portion to create a new Calendar event for the asset planning module.
	 */
	public static calendarEvent: UnoFormField[] = [
		{
			attribute: 'createEvent',
			label: 'createEvent',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true
		},
		{
			required: true,
			label: 'name',
			attribute: 'name',
			type: UnoFormFieldTypes.TEXT,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			attribute: 'description',
			label: 'description',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			attribute: 'alarmType',
			label: 'alarmType',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			options: Object.values(CalendarEventAlarmType).map((value) => {
				return {label: CalendarEventAlarmTypeLabel.get(value), value: value};
			}),
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: true,
			isActive: (object: any, row: UnoFormField) => {
				return object.alarmType.length !== 0 && object.createEvent;
			},
			attribute: 'alarmOffset',
			label: 'alarmOffset',
			dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: false,
			attribute: 'triggerEvent',
			label: 'triggerEvent',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: true,
			attribute: 'triggerEventDirection',
			label: 'direction',
			isActive: (object: any, row: UnoFormField) => {
				return object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.OPTIONS,
			options: Object.values(CalendarEventTriggerDirection).map((value) => {
				return {label: CalendarEventTriggerDirectionLabel.get(value), value: value};
			})
		},
		{
			required: true,
			attribute: 'triggerEventUuid',
			label: 'event',
			isActive: (object: any, row: UnoFormField) => {
				return object.triggerEvent && object.createEvent;
			},
			multiple: false,
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
			identifierAttribute: 'uuid',
			fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
				const data = {
					from: request.from,
					count: request.count,
					search: request.search,
					searchFields: ['name'],
					sortField: '[calendar_event].[name]',
					sortDirection: SortDirection.ASC
				};
	
				try {
					const req = await Service.fetch(ServiceList.assetPlanning.calendarEvent.list, null, null, data, Session.session);
					request.onFinish(req.response.calendarEvents, req.response.hasMore, req.id);
				} catch {
					request.onError();
				}
			},
			fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
				if (request.options.length > 0) {
					const data = {uuid: request.options[0]};
					const req = await Service.fetch(ServiceList.assetPlanning.calendarEvent.get, null, null, data, Session.session);
					request.onFinish([req.response.calendarEvent]);
				}
				request.onFinish([]);
			},
			getOptionText: function(option: any): string {
				return (option.name ? option.name + ' - ' : '') + (option.description ? option.description : '');
			}
		},
		{
			required: true,
			attribute: 'triggerEventOffset',
			label: 'triggerEventOffset',
			isActive: (object: any, row: UnoFormField) => {
				return object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: true,
			attribute: 'date',
			label: 'date',
			isActive: (object: any, row: UnoFormField) => {
				return !object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.DATE
		},
		{
			required: true,
			attribute: 'duration',
			label: 'duration',
			isActive: (object: any, row: UnoFormField) => {
				return !object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: true,
			attribute: 'periodic',
			label: 'periodic',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			isActive: (object: any, row: UnoFormField) => {
				return object.periodic && object.createEvent;
			},
			attribute: 'periodicity',
			label: 'periodicity',
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: false,
			isActive: (object: any, row: UnoFormField) => {
				return object.periodic && object.createEvent;
			},
			attribute: 'periodicityRepetition',
			label: 'numberTimesRepeat',
			type: UnoFormFieldTypes.NUMBER
		},
		{
			attribute: 'team',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		}
	];

	/**
	 * Layout to display history entries of the Atex inspections.
	 */
	public static inspectionHistory: UnoFormField[] = [
		{
			label: 'status',
			attribute: 'status',
			sort: false,
			type: UnoFormFieldTypes.OPTIONS,
			options: Object.values(AtexInspectionStatus).map(function(value) {
				return {value: value, label: AtexInspectionStatusLabel.get(value)};
			})
		},
		{
			label: 'resultInspection',
			attribute: 'result',
			sort: false,
			type: UnoFormFieldTypes.OPTIONS,
			required: true,
			isEmpty: function(object) {
				return object.result === AtexInspectionResult.NONE;
			},
			options: Object.values(AtexInspectionResult).map(function(value) {
				return {value: value, label: AtexInspectionResultLabel.get(value)};
			})
		},
		{
			label: 'resultFinal',
			attribute: 'resultFinal',
			sort: false,
			type: UnoFormFieldTypes.OPTIONS,
			isActive: function(object: any, row: UnoFormField) {
				return object.status === AtexInspectionStatus.REVIEW || object.status === AtexInspectionStatus.COMPLETED;
			},
			isEmpty: function(object) {
				return object.resultFinal === AtexInspectionResult.NONE;
			},
			options: Object.values(AtexInspectionResult).map(function(value) {
				return {value: value, label: AtexInspectionResultLabel.get(value)};
			})
		},
		{
			label: 'notes',
			attribute: 'data.notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			label: 'photo',
			attribute: 'data.photo',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
		},
		{
			label: 'video',
			attribute: 'data.video',
			type: UnoFormFieldTypes.VIDEO_RESOURCE
		},
		{
			label: 'audio',
			attribute: 'data.audio',
			type: UnoFormFieldTypes.AUDIO_RESOURCE
		}
	];
}


