import {Component, ViewEncapsulation} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Session} from '../../../../session';
import {ServiceList} from '../../../../http/service-list';
import {Service} from '../../../../http/service';
import {App} from '../../../../app';
import {UserAvatarPopoverComponent} from './popover/user-avatar-popover.component';

@Component({
	selector: 'user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true
})
export class UserAvatarComponent {
	public get app(): any { return App; }

	/**
	 * Image shown in the user avatar.
	 */
	public get image(): string {
		const user = Session.user;

		if (user !== null && user.picture !== null) {
			return Service.getURL(ServiceList.resources.image.get, {
				uuid: user.picture.uuid,
				format: user.picture.format
			});
		}

		return './assets/placeholder/profile.png';
	}

	public constructor(public popoverController: PopoverController) {}

	/**
	 * Show user avatar options.
	 *
	 * @param event - DOM event.
	 */
	public async showOptions(event?: any): Promise<void> {
		const popover = await this.popoverController.create({
			component: UserAvatarPopoverComponent,
			event: event
		});

		return await popover.present();
	}
}
