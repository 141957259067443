@if (this.epi !== null) {
	<uno-content [borders]="true">
		<!-- Base Form -->
		<uno-dynamic-form [layout]="this.layouts.details" [object]="this.epi" #detailsForm></uno-dynamic-form>
		<!-- Barrier form -->
		<uno-dynamic-form [layout]="this.layouts.barrier" [object]="this.epi.data.barrier" #barrierForm></uno-dynamic-form>
		<!-- Equipment form -->
		<uno-dynamic-form [layout]="this.layouts.equipment" [object]="this.epi.data.equipment" #equipmentForm></uno-dynamic-form>
		<!-- Cables form -->
		@if (this.epi.state >= epiState.BACKOFFICE || this.epi.data.cables.length > 0) {
			<uno-title>{{'cables' | translate}}</uno-title>
			@for (cable of this.epi.data.cables; track cable) {
				<uno-dynamic-form [layout]="layouts.cable" [object]="cable" [onChange]="this.onCableChange"></uno-dynamic-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.epi.data.cables.splice(this.epi.data.cables.indexOf(cable), 1);" color="error">{{'remove' | translate}}</uno-button>
				</div>
			}
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<uno-button (click)="this.addCable();">{{'add' | translate}}</uno-button>
			</div>
			<!-- Resulting cable table -->
			@if (resultingCable !== null) {
				<uno-title>{{'resultingCable' | translate}}</uno-title>
				<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
					<table>
						<tr>
							<td>{{'length' | translate}}</td>
							<td>{{resultingCable.length}}</td>
						</tr>
						<tr>
							<td>{{'resistance' | translate}}</td>
							<td>{{resultingCable.resistance}}</td>
						</tr>
						<tr>
							<td>{{'cableCapacity' | translate}}</td>
							<td>{{resultingCable.capacity}}</td>
						</tr>
						<tr>
							<td>{{'cableInductance' | translate}}</td>
							<td>{{resultingCable.inductance}}</td>
						</tr>
					</table>
				</ion-item>
			}
		}
		<!-- Result table -->
		@if (this.epi.barrierType !== -1) {
			<uno-title>{{'result' | translate}}</uno-title>
			<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
				<table>
					<tr>
						<td>{{'intrinsicSecurityTest' | translate}}</td>
						<td>{{'acceptedResult' | translate}}</td>
					</tr>

					@for (val of validations.getValidations(this.epi); track val) {
						@if ((this.epi.state >= epiState.BACKOFFICE && val.backofficeOnly) || !val.backofficeOnly) {
							<tr>
								<td>{{val.label}}</td>
								<td>{{(val.validator(this.epi) ? 'yes' : 'no') | translate}}</td>
							</tr>
						}
					}
				</table>
			</ion-item>
		}
		<!-- Submit buttons -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<uno-button (click)="this.save();">{{'save' | translate}}</uno-button>
			<uno-button (click)="this.save(true);">{{'update' | translate}}</uno-button>
			@if (this.epi.state === epiState.TODO) {
				<uno-button (click)="this.save(false, epiState.BACKOFFICE);" color="success">{{'submit' | translate}}</uno-button>
			}
			@if (this.epi.state === epiState.BACKOFFICE) {
				<uno-button (click)="this.save(false, epiState.COMPLETED);" color="success">{{'approve' | translate}}</uno-button>
				<uno-button (click)="this.save(false, epiState.TODO);" color="warning">{{'reject' | translate}}</uno-button>
			}
			@if ([userPermissions.EPI_DELETE] | hasPermissions) {
				<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
			}
		</div>
		<!-- Return buttons -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			@if (this.epi.state >= epiState.BACKOFFICE && ([userPermissions.EPI_REVERT_STATE] | hasPermissions)) {
				<uno-button (click)="this.save(false, epiState.TODO);" color="warning">{{'returnToTodo' | translate}}</uno-button>
			}
			@if (this.epi.state === epiState.COMPLETED && ([userPermissions.EPI_REVERT_STATE] | hasPermissions)) {
				<uno-button (click)="this.save(false, epiState.BACKOFFICE);" color="warning">{{'returnToBackoffice' | translate}}</uno-button>
			}
		</div>
	</uno-content>
}
