<!-- Label -->
@if (this.row.label) {
	<ion-text class="ion-no-margin ion-no-padding ion-text-wrap" color="primary" style="min-width: 100%; white-space: pre-wrap">{{this.row | label:this.items}}</ion-text>
}

<!-- Repetitive form -->
@for (item of this.items; track i; let i = $index) {
	<ng-container class="ion-text-end" style="height: 40px; --inner-padding-end: 0px;">
		<uno-dynamic-form [editable]="!this.disabled" [layout]="this.layout" [object]="item" [onChange]="this.onChange"></uno-dynamic-form>

		<ion-item class="ion-text-end ion-margin-horizontal ion-no-padding" lines="none" style="height: 40px; --inner-padding-end: 0px;">
			<ion-row slot="end">
				@if (!this.disabled && this.allowMove && i < this.items.length - 1) { <uno-button icon="/assets/icons/uno/down-specialarrow.svg" (click)="this.moveFieldUp(i)" size="medium" [fitContent]="true"></uno-button>
				}
				@if (!this.disabled && this.allowMove && i > 0) {
					<uno-button icon="/assets/icons/uno/up-specialarrow.svg" (click)="this.moveFieldDown(i)" size="medium" [fitContent]="true"></uno-button>
				}
				@if (!this.disabled) {
					<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.items.splice(i, 1);" size="medium" [fitContent]="true"></uno-button>
				}
			</ion-row>
		</ion-item>
	</ng-container>
}

<ion-item class="ion-text-end ion-margin-horizontal ion-no-padding" lines="none" style="height: 40px; --inner-padding-end: 0px;">
	@if (!this.disabled) {
		<uno-button slot="end" (click)="this.addItem()" size="medium">
			<uno-text>{{'add' | translate }}</uno-text>
		</uno-button>
	}
</ion-item>
