import {UUID} from '../../uuid';
import {LDSAlarmLevel} from './lds-alarm-level';

/**
 * Alarm activation history entry.
 *
 * Created when an alarm is processed by the system.
 */
export class LDSAlarmHistory {
	/**
	 * UUID of the history entry.
	 */
	public uuid: UUID;

	/**
	 * UUID of the alarm that generated this entry.
	 */
	public alarmUuid: UUID;

	/**
	 * UUID of the LDS sensor.
	 */
	public ldsUuid: UUID;

	/**
	 * Timestamp when the alarm was triggered.
	 */
	public timestamp: Date;

	/**
	 * The distance in meters on the pipeline where the faulty value was found that triggered the alarm
	 */
	public distance: number;

	/**
	 * The value received from the LDS that met the alarm conditions
	 */
	public value: number;

	/**
	 * Description of the alarm triggered.
	 */
	public description: number;

	/**
	 * Level of the alarm.
	 */
	public level: LDSAlarmLevel;

	/**
	 * Indicates if this alarm is still active.
	 */
	public active: boolean = false;

	/**
	 * Parse data received
	 * 
	 * 
	 * @param data - Data received from API
	 */
	public static parse(data: any): LDSAlarmHistory {
		const history = new LDSAlarmHistory();
		
		history.uuid = data.uuid;
		history.alarmUuid = data.alarmUuid;
		history.ldsUuid = data.ldsUuid;
		history.timestamp = new Date(data.timestamp);
		history.distance = data.distance;
		history.value = data.value;
		history.description = data.description;
		history.level = data.level;
		history.active = data.active === true;

		return history;
	}
}
