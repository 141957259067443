import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as QRCode from 'qrcode';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {Modal} from '../../../modal';
import {QRReaderComponent} from '../../../modules/qr/components/qr-reader/qr-reader.component';
import {FileUtils} from '../../../utils/file-utils';
import {Locale} from '../../../locale/locale';
import {Base64Utils} from '../../../utils/binary/base64-utils';
import {App} from '../../../app';

@Component({
	selector: 'uno-qr-code',
	templateUrl: './uno-qr-code.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoQrCodeComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule]
})
export class UnoQrCodeComponent implements ControlValueAccessor {
	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Resource representing the image stored.
	 */
	public value: any = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = null;

	/**
	 * QR image generated from the value placed in the component, it is shown the user in the input component.
	 *
	 * Might not be exactly like the physical QR code registered but contains the same data.
	 */
	public preview: string = null;

	/**
	 * Show the QR scanner modal to the user to capture and select a new QR code for this field.
	 */
	public async select(): Promise<void> {
		if (this.disabled) {
			throw new Error('Cannot select a QR when the input is disabled');
		}

		if (!App.device.hasCamera()) {
			Modal.alert(Locale.get('error'), Locale.get('errorCameraNotFound'));
			return;
		}

		const popover = await Modal.component(QRReaderComponent, {
			dismissOnRead: true,
			onRead: (data: string) => {
				this.writeValue(data);
			}
		});
	}

	/**
	 * Save the QR data into a file.
	 */
	public async save(): Promise<void> {
		const options = {
			errorCorrectionLevel: 'L',
			type: 'png',
			width: 512,
			margin: 2,
			scale: 1.0
		};

		try {
			const url = await QRCode.toDataURL(this.value, options);
			FileUtils.writeFileBase64('qr.png', Base64Utils.removeHeader(url));
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExportingQR'));
		}
	}

	/**
	 * Generate a QR code preview to show to the user.
	 *
	 * Should be called every time after updating the component value.
	 */
	public async generatePreview(): Promise<void> {
		if (this.value) {
			const options = {
				errorCorrectionLevel: 'L',
				type: 'png',
				width: 100,
				margin: 2,
				scale: 1.0
			};

			this.preview = await QRCode.toDataURL(this.value, options);
		} else {
			this.preview = null;
		}
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		if (this.onChange) {
			this.onChange(this.value);
		}

		this.generatePreview();
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
