import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {UnoDynamicFormComponent} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {FileUtils} from '../../../../../../utils/file-utils';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Environment} from '../../../../../../../environments/environment';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {FileReaderAsync} from '../../../../../../utils/file-reader-async';
import {ObjectKeysPipe} from '../../../../../../pipes/object-keys.pipe';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {AtexInspectionFieldsService} from '../../../services/atex-inspection-fields.service';
import {AtexInspectionChecklistService} from '../../../services/atex-inspection-checklist.service';
import {AtexInspectionFormFieldType} from '../../../data/master-data/atex-inspection-fields';
import {AtexInspectionFormFieldLayout} from './atex-inspections-fields-layout';

@Component({
	selector: 'masterdata-atex-inspections-fields-page',
	templateUrl: 'atex-inspections-fields.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule, ObjectKeysPipe]
})
export class AtexInspectionsFieldsPage extends ScreenComponent implements OnInit {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get layout(): any { return AtexInspectionFormFieldLayout; }

	public permissions = [UserPermissions.ATEX_INSPECTION_FIELDS_EDIT];

	/**
	 * Form data being edited on the JSON edited.
	 */
	public data: any = null;

	/**
	 * Master data checklists used for validation.
	 */
	public checklists: any = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('inspectionFields');

		this.checklists = await AtexInspectionChecklistService.get();
		this.data = await AtexInspectionFieldsService.get();
	}

	/**
	 * Validate the field data before submitting it to the API.
	 */
	public validate(data: any): boolean {
		for (const i in data) {
			if (!UnoDynamicFormComponent.requiredFilled(AtexInspectionFormFieldLayout, data[i])) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return false;
			}

			if (data[i].type === AtexInspectionFormFieldType.TITLE) {
				if (data[i].attribute !== i) {
					Modal.alert(Locale.get('error'), Locale.get('errorAttributeShouldHaveKeyValue', {attribute: i}));
					return false;
				}
			} else if (data[i].type === AtexInspectionFormFieldType.FIELD) {
				if (data[i].attribute !== i) {
					Modal.alert(Locale.get('error'), Locale.get('errorAttributeShouldHaveKeyValue', {attribute: i}));
					return false;
				}

				if (data[i].inspector && data[i].backoffice) {
					Modal.alert(Locale.get('error'), Locale.get('errorOnlyBackofficeInspector', {field: i}));
					return false;
				}
			}
		}

		// Check if every field in usage still exists
		for (const i in this.checklists) {
			const checklist = this.checklists[i];
			for (let j = 0; j < checklist.fields.length; j++) {
				const field = checklist.fields[j];
				if (!data[field]) {
					Modal.alert(Locale.get('error'), Locale.get('errorFieldInspectionRemoved', {field: field, inspection: i}));

					if (!Environment.PRODUCTION) {
						console.warn('EQS: Field inspection missing.', i, j, this.checklists, data);
					}
					return false;
				}
			}

		}

		return true;
	}

	/**
	 * Import data from JSON file.
	 */
	public async import(): Promise<void> {
		const files = await FileUtils.chooseFile('.json', false);
		if (files.length > 0) {
			const result = await FileReaderAsync.readAsText(files[0]);
			try {
				// @ts-ignore
				const data = JSON.parse(result);

				if (this.validate(data)) {
					this.data = data;
				} else {
					if (!Environment.PRODUCTION) {
						console.log('EQS: Validation of data imported failed.', this.data);
					}

					Modal.alert(Locale.get('error'), Locale.get('errorImport'));
				}
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImport'));
			}
		}
	}

	/**
	 * Export inspection fields to JSON file.
	 */
	public export(): void {
		if (this.validate(this.data)) {
			FileUtils.writeFile('inspections-fields.json', JSON.stringify(this.data, null, '\t'));
		}
	}

	/**
	 * Update the checklist value in the server.
	 */
	public async save(stayOnPage: boolean = false): Promise<void> {
		if (!this.validate(this.data)) {
			return;
		}

		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm'));
		if (confirm) {
			await Service.fetch(ServiceList.atex.inspection.fieldsUpdate, null, null, this.data, Session.session);
			Modal.toast(Locale.get('updatedSuccessfully'));

			if (!stayOnPage) {
				App.navigator.pop();
			}
		}
	}
}
