import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Session} from '../../../session';
import {App} from '../../../app';
import {UnoFormField} from '../uno-dynamic-form/uno-form-field';
import {UnoDynamicFormModule} from '../uno-dynamic-form.module';
import {Modal} from '../../../modal';
import {Locale} from '../../../locale/locale';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../uno/uno-title/uno-title.component';
import {UnoDynamicFormComponent} from '../uno-dynamic-form/uno-dynamic-form.component';

/**
 * Represents the data in the form buttons.
 *
 * Buttons should have success and label attributes.
 *
 * A color class for the button may be specified as well as a callback(object) method activated on click.
 */
export class UnoFormModalButton {
	/**
	 * If Success set true the form fields are verified to ensure that all required fields are filled.
	 */
	public success: boolean = false;

	/**
	 * Label to be shown to the user on the button (the label will be translated in the GUI).
	 */
	public label: string = '';

	/**
	 * Color of the button (e.g. warning, primary)
	 */
	public color?: string = 'primary';

	/**
	 * Callback method called when this button is pressed.
	 */
	public callback?: (object: any)=> Promise<void> | void;
}

@Component({
	selector: 'uno-uno-form-modal',
	templateUrl: './uno-form-modal.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoTitleComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class UnoFormModalComponent {
	public get app(): any { return App; }

	public get session(): any { return Session; }

	@ViewChild('form', {static: false})
	public form: UnoDynamicFormComponent = null;

	public constructor(public controller: PopoverController) {}

	/**
	 * Title of the modal window.
	 */
	@Input()
	public title: string = '';

	/**
	 * Object being shown in the dynamic form.
	 */
	@Input()
	public object: any = null;

	/**
	 * Layout of the form, array of fields of the dynamic form.
	 */
	@Input()
	public layout: UnoFormField[] = [];

	/**
	 * Indicate if the form is editable.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * List of buttons to show on the form modal. By default, only the OK and cancel button are presented.
	 */
	@Input()
	public buttons: UnoFormModalButton[] = [
			{
				success: true,
				label: 'ok',
				color: 'primary',
				callback: null
			},
			{
				success: false,
				label: 'cancel',
				color: 'primary',
				callback: null
			}
		];

	/**
	 * Callback method to retrieve the result from the form.
	 *
	 * It's only called on success and receives the object of the form as parameter.
	 */
	@Input()
	public onDone: (result: any)=> void = null;

	/**
	 * Callback method to detect when the modal was canceled.
	 */
	@Input()
	public onCancel: (result: any)=> void = null;

	/**
	 * Process button press, depending on the success value different results are applied.
	 *
	 * The button callback method is accessed here.
	 */
	public async buttonPressed(button: UnoFormModalButton): Promise<void> {
		if (button.callback instanceof Function) {
			await button.callback(this.object);
		}
		
		if (button.success) {
			await this.submit();
		} else {
			await this.cancel();
		}
	}

	/**
	 * Submit the form result and call the onDone method.
	 *
	 * Validates the form content if it fails an alert is shown to the user.
	 */
	public submit(): Promise<boolean> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		if (this.onDone instanceof Function) {
			this.onDone(this.object);
		}

		return this.dismiss();
	}

	/**
	 * Cancel the modal, and call the onDone method.
	 *
	 * Values input into the form are still applied to the object after the form has been canceled.
	 */
	public cancel(): Promise<boolean> {
		if (this.onCancel instanceof Function) {
			this.onCancel(this.object);
		}

		return this.dismiss();
	}

	/**
	 * Dismiss the modal controlled. Should close the modal screen instantly.
	 */
	public dismiss(): Promise<boolean> {
		return this.controller.dismiss();
	}
}
