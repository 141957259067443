<div style="position:absolute; width: 100%; height: 100%; top: 0px; left: 0px">
	<div #map class="pipeline-edit-map-expanded">
		<!-- Map -->
		<div #mapContainer style="position:absolute; top:0px; bottom:0px; width: 100%; height: 100%;"></div>

		<!-- Legend -->
		<div class="pipeline-edit-legend pipeline-edit-map-overlay">
			@for (l of this.legend(); track l) {
				<div style="cursor: pointer;" (click)="this.toggleLegendItem(l.type, l.featureType);">
					<span class="pipeline-edit-legend-key" [ngStyle]="{backgroundColor: l.color, pointerEvents: 'none'}"></span>
					<span [ngClass]="!l.enabled ? 'pipeline-edit-strikethrough' : ''" [ngStyle]="{pointerEvents: 'none'}">
						{{l.label}}
					</span>
				</div>
			}
		</div>
	</div>

	<div #sidebar class="pipeline-edit-sidebar-expanded">
		@if (this.pipeline !== null) {
			<!-- Base Information -->
			<uno-dynamic-form [layout]="this.layout" [object]="this.pipeline" #form></uno-dynamic-form>
			<!-- Save/Update -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				@if (this.createMode) {
					@if ([userPermissions.PIPELINE_INTEGRITY_PIPELINE_CREATE] | hasPermissions) {
						<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
					}
				}
				@else {
					@if ([userPermissions.PIPELINE_INTEGRITY_PIPELINE_EDIT] | hasPermissions) {
						<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
					}
					@if ([userPermissions.PIPELINE_INTEGRITY_PIPELINE_DELETE] | hasPermissions) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				}
			</div>
		}
	</div>

	<!-- Expand/ Collapse button -->
	<div [ngStyle]="this.expanded() ? {left: '400px'} : {left: '0px'}" style="position:absolute; display: flex; width: 30px; height: 50px; top: 50px; align-items: center; justify-content: center; cursor: pointer; background-color: var(--gray-13)" (click)="this.toggleSidebar();">
		@if (this.expanded()) {
			<ion-icon name="caret-back-outline"></ion-icon>
		} @else {
			<ion-icon name="caret-forward-outline"></ion-icon>
		}
	</div>

</div>
