/**
 * List of possible inspection final conclusion evaluations for the DL50 inspections.
 */
export enum DL50InspectionValidationStatus {	
	/**
	 * No validation set yet.
	 */
	NONE = 0,
	
	/**
	 * The inspection validation is pending.
	 */
	PENDING = 1,

	/**
	 * The inspection validation has been approved.
	 */
	APPROVED = 2,
	
	/**
	 * The inspection validation has been disapproved.
	 */
	DISAPPROVED = 3
};

/**
 * List of possible inspection final conclusion evaluations labels for DL50 inspections.
 */
export const DL50InspectionValidationStatusLabel: Map<number, string> = new Map([
	[DL50InspectionValidationStatus.NONE, 'none'],
	[DL50InspectionValidationStatus.PENDING, 'pending'],
	[DL50InspectionValidationStatus.APPROVED, 'approved'],
	[DL50InspectionValidationStatus.DISAPPROVED, 'disapproved']
]);
