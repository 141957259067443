import {Component, OnInit} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoDynamicFormModule} from 'src/app/components/uno-forms/uno-dynamic-form.module';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {App} from 'src/app/app';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {Dl50MasterSettingsService} from 'src/app/modules/dl50/services/dl50-master-settings.service';
import {DL50MasterSettings} from 'src/app/models/dl50/masterdata/dl50-master-settings';
import {DL50MasterSettingsLayout} from '../dl50-configuration-layout';

/**
 * Page used to show the DL50 Master Settingss.
 */
@Component({
	selector: 'dl50-master-settings',
	templateUrl: 'dl50-master-settings.page.html',
	standalone: true,
	imports: [UnoDynamicFormModule, UnoContentComponent, UnoButtonComponent, TranslateModule]
})
export class DL50SettingsPage extends ScreenComponent implements OnInit {
	public get dl50CompanyLayout(): any {return DL50MasterSettingsLayout;}

	public get app(): any {return App;}

	public settings: DL50MasterSettings; 

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.settings = await Dl50MasterSettingsService.get();

		App.navigator.setTitle('settings');
	}

	/**
	 * Updates the master settings in the api and returns to the previous page
	 */
	public async update(): Promise<void> {
		await Dl50MasterSettingsService.update(this.settings);
		await Modal.toast(Locale.get('updatedSuccessfully'), 2000);
		App.navigator.pop();
	}
}
