/**
 * The possible DL50 inspections result evaluations
 */
export enum DL50InspectionQuestionResult {
	/**
	 * No evaluation given for the assessment yet.
	 */
	NONE = 0,

	/**
	 * Evaluation is OK.
	 */
	OK = 1,

	/**
	 * Evaluation is NOK.
	 */
	NOK = 2,
}

/**
 * List of possible labels for question result.
 */
export const DL50InspectionQuestionResultLabel: Map<number, string> = new Map([
	[DL50InspectionQuestionResult.NONE, 'none'],
	[DL50InspectionQuestionResult.OK, 'ok'],
	[DL50InspectionQuestionResult.NOK, 'nok']
]);
