import {RBACLayout} from 'src/app/modules/roles/screens/rbac-layout';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UnoFormField} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {Session} from '../../../../session';

export const InspectionLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		editable: false,
		label: 'number',
		attribute: 'numberId',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: true,
		label: 'name',
		attribute: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		label: 'description',
		attribute: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'assetUuid',
		label: 'asset',
		type: UnoFormFieldTypes.ASSET_SELECTOR,
		showClear: true,
		multiple: false,
		isActive: true
	},
	{
		required: false,
		attribute: 'teamUuid',
		label: 'team',
		type: UnoFormFieldTypes.TEAM_SELECTOR,
		showClear: true
	},
	{
		required: false,
		label: 'inspectionQrCode',
		attribute: 'qr',
		type: UnoFormFieldTypes.QR,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.INSPECTION_QR); }
	},
	...RBACLayout
];
