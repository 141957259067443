import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {AcquisitionListResponse, AcquisitionService} from 'src/app/modules/pipeline-integrity/services/acquisitions.service';
import {CMPService} from 'src/app/modules/pipeline-integrity/services/cmp.service';
import {PipelineService} from 'src/app/modules/pipeline-integrity/services/pipeline.service';
import {UUID} from '../../../../../../models/uuid';
import {Service} from '../../../../../../http/service';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Acquisition} from '../../../../../../models/pipeline-integrity/mot/acquisition';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {Experiment} from '../../../../../../models/pipeline-integrity/mot/experiment';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';

@Component({
	selector: 'mot-acquisition-list-page',
	templateUrl: 'mot-acquisition-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		IonicModule,
		TranslateModule,
		FormatDatePipe,
		UnoResponsiveTableListComponent
	]
})
export class MOTAcquisitionListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get selfStatic(): any { return MOTAcquisitionListPage; }

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * UUID of the CMP that belongs to the acquisition.
	 */
	public cmpUuid: UUID;

	/**
	 * UUID of the pipeline that belongs to the acquisition.
	 */
	public pipelineUuid: UUID;

	/**
	 * List of Acquisitions to be displayed retrieved by the API.
	 */
	public acquisitions: Acquisition[] = [];

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'date', type: UnoTableColumnType.DATE, attribute: 'dateTime', visible: true, size: 'small', tag: ListDisplayStyle.TEXT},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', tag: ListDisplayStyle.TEXT},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/pipeline-integrity/graph-icon.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/acquisition/chart', {uuid: row.uuid.value, pipelineUuid: this.pipelineUuid, cmpUuid: this.cmpUuid});
					}
				},
				{
					src: './assets/icons/assets/edit-icon.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/acquisition/edit', {uuid: row.uuid.value});
					}
				}]
		}
	];

	public loadMore = async(): Promise<any> => {
		if (!this.cmpUuid || !this.pipelineUuid) {
			this.ngOnInit();
		}

		const list: AcquisitionListResponse = await AcquisitionService.list({cmpUuid: this.cmpUuid});

		return {
			elements: list.acquisitions,
			hasMore: list.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data || !data.cmpUuid) {
			App.navigator.pop();
			return;
		}
		this.acquisitions = [];
		this.cmpUuid = data.cmpUuid;

		if (!data.pipelineUuid) {
			const cmp = await CMPService.get(this.cmpUuid);
			const segment = await PipelineService.getSegment(cmp.segmentUuid);
			this.pipelineUuid = segment.pipelineUuid;
		} else {
			this.pipelineUuid = data.pipelineUuid;
		}


		App.navigator.setTitle('acquisition');

		this.totalItems = await AcquisitionService.count({cmpUuid: this.cmpUuid});
	}

	/**
	 * Load acquisitions and respective experiments data by CMP from API.
	 * 
	 * Acquisitions dropdowns selection displays experiments lists by acquisition.
	 * 
	 * Experiments dropdowns are displayed after respective acquisition has been selected.
	 */
	public async loadAcquisitionsToCompare(): Promise<void> {

		// List of experiments from acquisition
		let acquisitionAList: Experiment[] = [];
		let acquisitionBList: Experiment[] = [];

		const data = {
			acquisitionA: null,
			experimentA: null,
			acquisitionB: null,
			experimentB: null
		};

		const layout: UnoFormField[] = [
			{	
				attribute: 'acquisitionA',
				label: Locale.get('acquisition') + ' A',
				required: true,
				type: UnoFormFieldTypes.OPTIONS,
				fetchOptions: async(object: any, row: UnoFormField) => {
					const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.list, null, null, {cmpUuid: this.cmpUuid}, Session.session);
					const response = request.response;
					const options = [];
					for (let i = 0; i < response.acquisitions.length; i++) {
						options.push({
							value: response.acquisitions[i].uuid,
							label: response.acquisitions[i].description + ' - ' + new Date(response.acquisitions[i].dateTime).toLocaleString(Locale.code) 
						});
					}
					row.options = options;
					if (options.filter((a) => {return a.value === object.acquisitionA;}).length === 0) {
						object.acquisitionA = null;
					}
				},
				onChange: async function(object, row, value): Promise<any> {
					const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.list, null, null, {acquisitionUuid: object.acquisitionA}, Session.session);
					acquisitionAList = request.response.experiments;
					object.experimentA = null;
				}
			},
			{
				attribute: 'experimentA',
				label: Locale.get('experiment') + ' A',
				required: true,
				type: UnoFormFieldTypes.OPTIONS,
				isActive: function(object, row): boolean {
					return object.acquisitionA !== null;
				},
				fetchOptions: function(object: any, row: UnoFormField) {
					if (acquisitionAList) {
						const options = [];
						for (let i = 0; i < acquisitionAList.length; i++) {
							options.push({
								value: acquisitionAList[i],
								label: (acquisitionAList[i].label ? acquisitionAList[i].label + ' - ' : '') + acquisitionAList[i].frequency + 'KHz' + ' - ' + new Date(acquisitionAList[i].dateTime).toLocaleString(Locale.code) 
							});
						}
						row.options = options;
						if (this.options.filter((a) => {return a.value === object.experimentA;}).length === 0) {
							object.experimentA = null;
						}
					}
				}
			},
			{
				attribute: 'acquisitionB',
				label: Locale.get('acquisition') + ' B',
				required: true,
				type: UnoFormFieldTypes.OPTIONS,
				fetchOptions: async(object: any, row: UnoFormField) => {
					const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.acquisition.list, null, null, {cmpUuid: this.cmpUuid}, Session.session);
					const response = request.response;
					const options = [];
					for (let i = 0; i < response.acquisitions.length; i++) {
						options.push({
							value: response.acquisitions[i].uuid,
							label: response.acquisitions[i].description + ' - ' + new Date(response.acquisitions[i].dateTime).toLocaleString(Locale.code)
						});
					}
					row.options = options;
					if (options.filter((a) => {return a.value === object.acquisitionB;}).length === 0) {
						object.acquisitionB = null;
					}
				},
				onChange: async function(object, row, value): Promise<any> {
					const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.list, null, null, {acquisitionUuid: object.acquisitionB}, Session.session);
					acquisitionBList = request.response.experiments;
					object.experimentB = null;
				}
			},
			{
				attribute: 'experimentB',
				label: Locale.get('experiment') + ' B',
				required: true,
				type: UnoFormFieldTypes.OPTIONS,
				isActive: function(object, row): boolean {
					return object.acquisitionB !== null;
				},
				fetchOptions: function(object: any, row: UnoFormField) {
					if (acquisitionBList) {
						const options = [];
						for (let i = 0; i < acquisitionBList.length; i++) {
							options.push({
								value: acquisitionBList[i],
								label: (acquisitionBList[i].label ? acquisitionBList[i].label + ' - ' : '') + ' - ' + acquisitionBList[i].frequency + 'KHz' + ' - ' + new Date(acquisitionBList[i].dateTime).toLocaleString(Locale.code) 
							});
						}
						row.options = options;
						if (this.options.filter((b) => {return b.value === object.experimentB;}).length === 0) {
							object.experimentB = null;
						}
					}
				}
			}
		];

		try {
			await Modal.form(Locale.get('pickTwoAcquisitions'), data, layout);
			App.navigator.navigate('/menu/pipeline-integrity/acquisition/chart-comparison', {experiments: [data.experimentA, data.experimentB], pipelineUuid: this.pipelineUuid, cmpUuid: this.cmpUuid});
		} catch (error) {
		}
	}
}
