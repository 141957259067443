import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspectionSettings} from '../data/master-data/atex-inspection-settings';

export class AtexInspectionSettingsService {
	/**
	 * Get the master data settings from the API.
	 */
	public static async get(): Promise<AtexInspectionSettings> {
		const request = await Service.fetch(ServiceList.atex.inspection.settingsGet, null, null, {}, Session.session);

		return AtexInspectionSettings.parse(request.response.settings);
	}
}
