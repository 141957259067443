import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {App} from '../../../app';
import {Atex, AtexTag} from '../../../models/atex/atex';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {UnoTextComponent} from '../../uno/uno-text/uno-text.component';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';
import {UnoAtexTagComponent} from './uno-atex-tag/uno-atex-tag.component';

@Component({
	selector: 'uno-atex-tag-group',
	templateUrl: './uno-atex-tag-group.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoAtexTagGroupComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, UnoAtexTagComponent, FormsModule, UnoButtonComponent, UnoTextComponent, TranslateModule]
})
export class UnoAtexTagGroupComponent implements ControlValueAccessor {
	public get app(): any { return App; }

	/**
	 * Form row used to display this value.
	 */
	@Input()
	public row: any = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Value stored in this input component.
	 *
	 * Should be an Atex value.
	 */
	public value: Atex = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Add new Atex tag to the field.
	 *
	 * Copy the value of the last Atex tag inserted if there is any.
	 */
	public addTag(): void {
		if (this.value !== null) {
			if (this.value.tags.length > 0) {
				this.value.tags.push(AtexTag.parse(this.value.tags[this.value.tags.length - 1]));
			} else {
				this.value.tags.push(new AtexTag());
			}

			this.writeValue(this.value);
		}
	}

	/**
	 * Remove a Atex tag from the field group.
	 *
	 * The Atex field needs to have at least one tag.
	 * 
	 * @param index - Index of the tag to be removed. If not provided removes last.
	 */
	public removeTag(index?: number): void {
		if (this.value !== null) {
			if (index) {
				this.value.tags.splice(index, 1);
			} else {
				if (this.value.tags.length > 1) {
					this.value.tags.pop();
				} else {
					Modal.alert(Locale.get('error'), Locale.get('errorAtexTags'));
				}
			}

			this.writeValue(this.value);
		}
	}

	public writeValue(value: any): void {
		this.value = structuredClone(value);
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
