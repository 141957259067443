import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {App} from '../../../app';
import {Session} from '../../../session';
import {UserPermissions} from '../../../models/users/user-permissions';
import {
	InputOptionsMultipleBatchRequest,
	InputOptionsMultipleLazyPageRequest,
	UnoOptionsLazyComponent
} from '../../uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoButtonComponent} from '../uno-button/uno-button.component';
import {UnoTextComponent} from '../uno-text/uno-text.component';
import {UnoSearchbarComponent} from '../uno-searchbar/uno-searchbar.component';

/**
 * Possible types of filters.
 */
export enum UnoFilterBarOptionType {
	SEARCH = 1,
	OPTIONS = 2,
	OPTIONS_LAZY = 3,
	CHECKBOX = 4,
	BUTTON = 5
}

/**
 * UNO filter option, to define how the filter should behave.
 */
export class UnoFilterBarOption {
	/**
	 * Type of the bar option.
	 */
	public type: UnoFilterBarOptionType;

	/**
	 * Attribute to be manipulated.
	 */
	public attribute?: string;

	/**
	 * Label to be presented in the interface.
	 */
	public label?: string;

	/**
	 * Default value for the filter field.
	 */
	public default?: any = null;

	/**
	 * List of options to present.
	 *
	 * Only applied to OPTIONS, OPTIONS_LAZY.
	 */
	public options?: {label: string, value: any}[] = [];

	/**
	 * Multiple flag to indicate if multiple options can be selected.
	 *
	 * Only applied to OPTIONS, OPTIONS_LAZY.
	 */
	public multiple?: boolean;

	/**
	 * Action to perform on button press.
	 *
	 * Only applied to BUTTON
	 */
	public onClick?: Function;

	/**
	 * Lazy fetch options from the API for infinite scrollable list.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public fetchOptionsLazy?: (request: InputOptionsMultipleLazyPageRequest, object?: any)=> void;

	/**
	 * Fetch batch options from the API to fill already existing values.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public fetchOptionsBatch?: (request: InputOptionsMultipleBatchRequest, object?: any)=> void;

	/**
	 * Get the list options text.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public getOptionText?: (obj: any)=> string;

	/**
	 * Attribute of the objects places in the form entry to consider as key.
	 *
	 * Only used for OPTIONS_LAZY
	 */
	public identifierAttribute?: string;
}

/**
 * UNO filter bar is used to present a list of filter applied alongside with list or tables
 */
@Component({
	selector: 'uno-filter-bar',
	templateUrl: './uno-filter-bar.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoSearchbarComponent, UnoTextComponent, IonicModule, FormsModule, UnoButtonComponent, TranslateModule, UnoOptionsLazyComponent]
})
export class UnoFilterBarComponent implements OnChanges {
	public get filterType(): any {return UnoFilterBarOptionType;}

	public get app(): any { return App; }

	public get session(): any { return Session; }

	public get userPermissions(): any { return UserPermissions; }

	/**
	 * List of filter to present.
	 */
	@Input()
	public options: UnoFilterBarOption[] = [];

	/**
	 * Object that contains the filter values presented.
	 */
	@Input()
	public filters: any = {};

	/**
	 * Callback method to be called when the filters change.
	 */
	@Output()
	public onChange = new EventEmitter<any>();

	/**
	 * Name of the search attribute if any.
	 *
	 * There can only be one search attribute in the search bar.
	 */
	public searchFilter: string = null;

	public ngOnChanges(changes: SimpleChanges): void {
		// Validate options
		if (changes.options) {
			this.searchFilter = null;
			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].type === UnoFilterBarOptionType.SEARCH) {
					if (this.searchFilter !== null) {
						throw Error('Only a single search field is allowed');
					}

					this.searchFilter = this.options[i].attribute;
				}
			}
		}
	}

	/**
	 * Reset the filters to their default values.
	 */
	public reset(): void {
		UnoFilterBarComponent.reset(this.filters, this.options);
	}

	/**
	 * Reset the filters to their default values.
	 */
	public static reset(data: any, filters: UnoFilterBarOption[]): any {
		for (const f of filters) {
			data[f.attribute] = f.default;
		}

		return data;
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public onSearchChange(search: string): void {
		this.filters[this.searchFilter] = search;
	}

	/**
	 * Emit when the multiple lazy component is changed
	 *
	 * @param event - The selected object.
	 */
	public multipleLazyChange(selected: any, attribute: string): void {
		this.filters[attribute] = selected;
		this.onChange.emit(this.filters);
	}
}
