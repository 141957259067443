import {UnoFormField} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field-types';

export const DL50RegulatoryStandardLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'label',
		label: 'name',
		type: UnoFormFieldTypes.TEXT,
		isEmpty: (object: any, row: UnoFormField): boolean => {
			return !object || !object.label || object?.label?.length === 0;
		}
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	}
];
