import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {RepairCriticalityLevelLabel} from 'src/app/models/repairs/repairs/repair-criticality';
import {QRGenerator} from 'src/app/modules/qr/data/qr-generator';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {RepairLongevity} from '../../../../../models/repairs/repairs/repair-longevity';
import {RepairStatus, RepairStatusLabel} from '../../../../../models/repairs/repairs/repair-status';
import {Locale} from '../../../../../locale/locale';
import {RepairCriticalityLabelPipe} from '../../pipes/repair-criticality-label.pipe';
import {RepairStatusLabelPipe} from '../../pipes/repair-status-label.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {RepairCountParams, RepairListParams, RepairListResponse, RepairService} from '../../services/repair.service';

@Component({
	selector: 'repairs-list-page',
	templateUrl: 'repairs-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		FormatDatePipe,
		RepairStatusLabelPipe,
		RepairCriticalityLabelPipe,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent
	]
})
export class RepairsListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public get app(): any { return App; }

	public get selfStatic(): any { return RepairsListPage; }

	public permissions = [UserPermissions.REPAIR_LIST_ALL];

	/**
	 * Status of repair.
	 */
	public status: number = RepairStatus.ALL;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.headerActive('image'), size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.headerActive('description'), size: 'small', sortBy: '[repair].[description]'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: this.headerActive('assetName'), size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: this.headerActive('assetTag'), size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: this.headerActive('status'), size: 'small', sortBy: '[repair].[status]'},
		{header: 'updatedAt', type: UnoTableColumnType.TEXT, attribute: 'updatedAt', visible: this.headerActive('updatedAt'), size: 'small', sortBy: '[repair].[updated_at]'},
		{header: 'criticality', type: UnoTableColumnType.TEXT, attribute: 'criticality', visible: this.headerActive('criticality'), size: 'small', sortBy: '[repair].[criticality]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: this.headerActive('actions'),
			size: 'medium', 
			icons: [{
				src: './assets/components/menu/qrcode.svg',
				click: (row): void => {
					QRGenerator.generateFile(row.uuid.value);
				}
			},
			{
				src: './assets/icons/assets/expand-icon.svg',
				click: (row): void => {
					App.openInTab('/menu/repairs/works/edit', {uuid: row.uuid.value});
				}
			}]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Repair Inspection list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[repair].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[repair].[updated_at]'},
				{label: 'createdAt', value: '[repair].[created_at]'},
				{label: 'criticality', value: '[repair].[criticality]'},
				{label: 'status', value: '[repair].[status]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[repair].[id]', '[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[id]', '[repair].[repair_order]', '[repair].[description]', '[repair].[notes]'],
			options: [
				{label: 'uuid', value: '[repair].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'repairOrder', value: '[repair].[repair_order]'},
				{label: 'description', value: '[repair].[description]'},
				{label: 'notes', value: '[repair].[notes]'},
				{label: 'proposalNotes', value: '[repair].[proposal_notes]'},
				{label: 'riskAssessmentNotes', value: '[repair].[risk_assessment_notes]'},
				{label: 'proposalApprovalNotes', value: '[repair].[proposal_approval_notes]'},
				{label: 'blockedNotes', value: '[repair].[blocked_notes]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'longevity',
			label: 'longevity',
			default: [RepairLongevity.UNKNOWN, RepairLongevity.TEMPORARY, RepairLongevity.DEFINITIVE],
			options: [	
				{label: 'unknown', value: RepairLongevity.UNKNOWN},
				{label: 'temporary', value: RepairLongevity.TEMPORARY},
				{label: 'definitive', value: RepairLongevity.DEFINITIVE}]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'tableFields',
			label: 'tableFields',
			default: ['image', 'assetName', 'assetTag', 'status', 'updatedAt', 'actions'],
			options: [
				{label: 'image', value: 'image'},
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'status', value: 'status'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'criticality', value: 'criticality'},
				{label: 'actions', value: 'actions'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter repairs by their name.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Longevity filters to be considered on repair filter
		 */
		longevityFilters: []
	}, RepairsListPage.filterOptions);

	public static defaultFilters = structuredClone(RepairsListPage.filters);

	public resetFilters(): void {
		Object.assign(RepairsListPage.filters, RepairsListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Read status from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		App.navigator.setTitle(Locale.get(RepairStatusLabel.has(data.status) ? RepairStatusLabel.get(data.status) : 'list'));
		this.status = data.status !== undefined ? data.status : RepairStatus.ALL;
	}


	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: RepairListParams = {
			status: this.status,
			from: count,
			count: pageSize,
			search: RepairsListPage.filters.search,
			sortDirection: RepairsListPage.filters.sortDirection,
			sortField: RepairsListPage.filters.sortField,
			searchFields: RepairsListPage.filters.searchFields,
			longevityFilters: RepairsListPage.filters.longevityFilters
		};

		const list: RepairListResponse = await RepairService.list(params);
		const repairs: any[] = list.repairs;

		repairs.forEach((repair) => {
			repair.image = repair.pictures.length > 0 ? repair.pictures[0] : './assets/placeholder/asset.png';
			repair.assetName = repair.asset.name;
			repair.assetTag = repair.asset.tag;
			repair.criticality = Locale.get(RepairCriticalityLevelLabel.get(repair.criticality));
			repair.status = Locale.get(RepairStatusLabel.get(repair.status));
			repair.updatedAt = new Date(repair.updatedAt).toLocaleDateString(Locale.code, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
		});
		return {
			elements: list.repairs,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public onSearchChange(search: string): void {
		RepairsListPage.filters.search = search;
		this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(filters: any): void {
		RepairsListPage.filters = filters;
		this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		this.table.sortDirection = RepairsListPage.filters.sortDirection;
		this.table.sortField = RepairsListPage.filters.sortField;

		const params: RepairCountParams = {
			status: this.status,
			search: RepairsListPage.filters.search,
			searchFields: RepairsListPage.filters.searchFields,
			longevityFilters: RepairsListPage.filters.longevityFilters
		};

		this.tableLayout.forEach((column) => {
			column.visible = this.headerActive(column.attribute);
		});

		this.tableTotalItemsCount = await RepairService.count(params);

		this.table.reset();
	}

	/**
	 * When the user changes the sort using the table
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public sortChanged(sortBy: any): void {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === RepairsListPage.filters.sortField) {
			RepairsListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			RepairsListPage.filters.sortField = sortBy;
			RepairsListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.reset();
	}

	/**
	 * Check if a table header is active
	 * 
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active
	 */
	public headerActive(attribute: string): boolean {
		return RepairsListPage.filters.tableFields.indexOf(attribute) !== -1;
	}

	protected readonly ResourceUtils = ResourceUtils;
}
