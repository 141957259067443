import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe to obtain a list of object keys, useful to iterate properties of objects.
 */
@Pipe({
	name: 'objectKeys',
	pure: false,
	standalone: true
})
export class ObjectKeysPipe implements PipeTransform {
	public transform(value: any): any {
		return Object.keys(value);
	}
}
