import {UnoFormField} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Session} from 'src/app/session';

export const RBACLayout: UnoFormField[] = [
	{
		label: 'rbac',
		type: UnoFormFieldTypes.TITLE,
		isActive: function(object: any, row: UnoFormField) {
			return Session.user.isAdmin;
		}
	},
	{
		attribute: 'rbacMasterAllowedList',
		label: 'allowedRoles',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		isActive: function(object: any, row: UnoFormField) {
			return object.rbacMasterAllowedList !== undefined && Session?.user?.isAdmin;
		},
		fetchOptions: async function(object: any, row: UnoFormField) {
			const request = await Service.fetch(ServiceList.roles.listName, null, null, null, Session.session);
			const response = request.response;
			const options = [];
			for (let i = 0; i < response.roles.length; i++) {
				options.push({
					value: response.roles[i].uuid,
					label: response.roles[i].name
				});
			}

			row.options = options;
		}
	},
	{
		attribute: 'rbacAllowedList',
		label: 'effectiveRoles',
		editable: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		isActive: function(object: any, row: UnoFormField) {
			return Session?.user?.isAdmin;
		},
		fetchOptions: async function(object: any, row: UnoFormField) {
			const request = await Service.fetch(ServiceList.roles.listName, null, null, null, Session.session);
			const response = request.response;
			const options = [];
			for (let i = 0; i < response.roles.length; i++) {
				options.push({
					value: response.roles[i].uuid,
					label: response.roles[i].name
				});
			}

			row.options = options;
		}
	}
];
