import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {MasterSettingsPage} from './screens/master-settings.page';
import {MasterSettingsAppearancePage} from './screens/appearance/appearance-master-settings-edit.page';
import {MasterSettingsPreferencesEditPage} from './screens/preferences/preferences-edit.page';

const routes: Routes = [
	{
		path: '',
		component: MasterSettingsPage
	},
	{
		path: 'appearance',
		component: MasterSettingsAppearancePage
	},
	{
		path: 'preferences',
		component: MasterSettingsPreferencesEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MasterSettingsRouterModule { }
