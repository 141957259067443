import {UUIDIdentification} from '../../uuid';

/**
 * Format of a regulatory standard.
 */
export class DL50RegulatoryStandard extends UUIDIdentification {
	/**
	 * Label of the question.
	 */
	public label: string = '';

	/**
	 * Description of the question.
	 */
	public description: string = '';
	
	/**
	* Parse JSON data received from the API into an object.
	*
	* @param data - Object received from API.
	* @returns Object of the correct type, built with parsed data received.
	*/
	public static parse(data: any): DL50RegulatoryStandard {
		const question = new DL50RegulatoryStandard();

		question.uuid = data.uuid;
		question.updatedAt = new Date(data.updatedAt);
		question.createdAt = new Date(data.createdAt);

		question.label = data.label;
		question.description = data.description;
		
		return question;
	}
}
