import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../../../session';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../../components/charts/doughnut-chart/doughnut-chart.component';
import {CSSUtils} from '../../../../../utils/css-utils';

@Component({
	selector: 'graph-action-plan-blocked',
	templateUrl: './action-plan-blocked.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class ActionPlanBlockedComponent implements OnInit {
	/**
	 * Data for the doughnut chart.
	 */
	public data: DoughnutChartData[] = [];

	public async ngOnInit(): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.countState, null, null, null, Session.session);
		const response = request.response;

		this.data = [
			new DoughnutChartData(Locale.get('blocked'), response.blocked, CSSUtils.parseColor(CSSUtils.getVariable('--special-red-1'))),
			new DoughnutChartData(Locale.get('approved'), response.accepted, CSSUtils.parseColor(CSSUtils.getVariable('--special-green-4')))
		];
	}
}


