@if (this.value) {
	<div style="width: 100%">
		<!-- Not applicable -->
		@if (!this.disabled) {
			<ion-item class="ion-no-margin ion-no-padding" lines="{{this.value.notApplicable ? 'none' : 'full'}}">
				<ion-checkbox justify="start" labelPlacement="end" (ngModelChange)="this.setAttribute('notApplicable', $event);" [ngModel]="this.value.notApplicable" [ngModelOptions]="{standalone: true}"> {{'notApplicable' | translate}}</ion-checkbox>
			</ion-item>
		}
		@if (this.disabled && this.value.notApplicable) {
			<ion-label class="ion-no-margin ion-no-padding ion-text-end">{{'notApplicable' | translate}}</ion-label>
		}
		<!-- Hide the rest if the field is not applicable -->
		@if (!this.value.notApplicable) {
			<!-- Response -->
			@if (!this.disabled) {
				<ion-radio-group (ngModelChange)="this.setAttribute('result', $event);" [ngModel]="this.value.result" [ngModelOptions]="{standalone: true}">
					<ion-row>
						@for (value of self.resultValues; track value) {
							<ion-col>
								<ion-item lines="none" style="width: 100%">
									<ion-radio justify="start" [value]="value.value"> {{value.label | translate}}</ion-radio>
								</ion-item>
							</ion-col>
						}
					</ion-row>
				</ion-radio-group>
			} @else {
				<ion-item class="ion-no-margin ion-no-padding" lines="none">
					<ion-label class="ion-no-margin ion-no-padding">{{inspectionFieldLabel.get(this.value.result) | translate}}</ion-label>
				</ion-item>
			}
			<!-- Notes -->
			@if (!this.disabled) {
				<ion-textarea fill="outline" (ngModelChange)="this.setAttribute('notes', $event);" [ngModel]="this.value.notes" [ngModelOptions]="{standalone: true}" [readonly]="this.disabled" autoGrow placeholder="{{'notes' | translate}}"></ion-textarea>
			}
			@if (this.disabled && this.value.notes) {
				<ion-label class="ion-no-margin ion-no-padding ion-text-wrap">{{this.value.notes}}</ion-label>
			}

			<!-- Photo -->
			@if ((!this.disabled) || (this.disabled && this.value.photo)) {
				<uno-image-selector (ngModelChange)="this.setAttribute('photo', $event);" [disabled]="this.disabled" [ngModel]="this.value.photo" [ngModelOptions]="{standalone: true}"></uno-image-selector>
			}

			<!-- Justifications -->
			@if (!this.disabled && this.row.justifications.length > 0) {
				<ion-item class="ion-no-margin ion-no-padding" lines="none">
					<ion-select fill="outline" (ngModelChange)="this.setAttribute('justifications', $event);" [disabled]="this.disabled" [interfaceOptions]="{header: locale.get('justifications')}" [ngModel]="this.value.justifications" [ngModelOptions]="{standalone: true}" interface="alert" multiple="true" placeholder="{{'justifications' | translate}}">
						@for (l of this.row.justifications; track l; let i = $index) {
							<ion-select-option [value]="i">{{l}}</ion-select-option>
						}
					</ion-select>
				</ion-item>
			}
			@if (this.disabled && this.value.justifications.length > 0) {
				@for (i of this.value.justifications; track i) {
					<ion-item class="ion-no-margin ion-no-padding" lines="none">
						<ion-label class="ion-no-margin ion-no-padding ion-text-wrap">{{this.row.justifications[i]}}</ion-label>
					</ion-item>
				}
			}
		}
	</div>
}
