import {Service} from '../../../http/service';
import {ProgressBar} from '../../../progress-bar';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {Locale} from '../../../locale/locale';
import {FileUtils} from '../../../utils/file-utils';
import {Modal} from '../../../modal';
import {UnoFormField} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field';
import {UUID} from '../../../models/uuid';
import {XlsxSheetData, XlsxUtils} from '../../../utils/xlsx-utils';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-dynamic-form/uno-form-field-types';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {ServiceResponse} from '../../../http/service-response';
import {SortDirection} from '../../../utils/sort-direction';
import {AssetService} from '../services/asset.service';
import {AssetFieldDataService} from '../services/asset-field-data.service';
import {Atex} from '../../../models/atex/atex';
import {AtexExplosionGroups, AtexExplosionGroupsLabels, AtexTemperatureLabels, AtexZones, AtexZonesLabel} from '../../../models/atex/atex-enums';
import {APAssetSubType} from '../../../models/asset-portfolio/asset-sub-type';
import {APAssetType} from '../../../models/asset-portfolio/asset-type';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {APAssetFormTab} from '../../../models/asset-portfolio/asset-form-tab';
import {APAssetFormTabCard} from '../../../models/asset-portfolio/asset-form-tab-card';
import {APAssetFormBlockField} from '../../../models/asset-portfolio/asset-form-block-field';
import {APAssetFieldData} from '../../../models/asset-portfolio/asset-field-data';
import {APAssetFormBlockFieldComponentType} from '../../../models/asset-portfolio/asset-form-block-field-type';
import {ResourceUtils} from '../../../utils/resource-utils';
import {AssetReport} from './asset-report';

export class APAssetExport {
	/**
	 * Export assets as a JSON file.
	 */
	public static async exportJSON(): Promise<void> {
		let from: number = 0;
		const count: number = 300;
		const assets: APAsset[] = [];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.assetPortfolio.asset.listDetailed, null, null, {from: from, count: count}, Session.session, true);

					for (let i = 0; i < request.response.assets.length; i++) {
						assets.push(APAsset.parse(request.response.assets[i]));
					}

					from += request.response.assets.length;
					if (!request.response.hasMore) {
						break;
					}
				}

				FileUtils.writeFile('assets.json', JSON.stringify(assets, null, '\t'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Export assets list with base information only as XLSX file.
	 * 
	 * This method can use external filters or ask for the type and sub-type filters in a modal.
	 * 
	 * @param presentFilterModal - If true, a modal is presented to the user to choose the list filters.
	 * @param typeUuid - The asset type UUID to filter the assets by. If none provided, all the assets are returned.
	 * @param subtypeUuid - The asset sub-type UUID to filter the assets by.
	 * @param search - The search string to filter the assets by.
	 * @param searchFields - The search fields to filter the assets by.
	 * @param sortField - The sort string to order the assets by.
	 * @param sortDirection - The sort direction.
	 */
	public static async exportXLSX(presentFilterModal: boolean = true, typeUuid: UUID = null, subtypeUuid: UUID = null, search: string = null, searchFields: string[] = [], sortField: string = null, sortDirection: string = null): Promise<void> {
		// Object with the needed layout attributes for the tool modal prompted to user
		const filters = {
			// Asset type uuids to filter the export by
			typeUuid: typeUuid,
			// Asset subtype uuids to filter the export by
			subtypeUuid: subtypeUuid
		};
		
		if (presentFilterModal) {
			const layout: UnoFormField[] = [
				{
					attribute: 'typeUuid',
					label: 'assetType',
					type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
					showClear: true,
					multiple: false,
					placeholder: 'all',
					identifierAttribute: 'uuid',
					fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
						const data = {
							from: request.from,
							count: request.count,
							search: request.search, 
							searchFields: ['[ap_asset_type].[id]', '[ap_asset_type].[name]'],
							sortField: '[ap_asset_type].[name]',
							sortDirection: SortDirection.ASC
						};
			
						try {
							const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetType.listName, null, null, data, Session.session);
							request.onFinish(req.response.types, req.response.hasMore, req.id);
						} catch {
							request.onError();
						}
					},
					fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
						if (request.options.length > 0) {
							const data = {uuid: request.options[0]};
			
							try {
								const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetType.get, null, null, data, Session.session);
								request.onFinish(req.response.type);
							} catch {}
						} else {
							request.onFinish(null);
						}
					},
					getOptionText: function(option: any): string {
						return option.name;
					}
				},
				{
					attribute: 'subtypeUuid',
					label: 'assetSubType',
					type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
					showClear: true,
					required: false,
					placeholder: 'all',
					editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
					multiple: false,
					identifierAttribute: 'uuid',
					fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<void> {
						const data = {
							from: request.from,
							count: request.count,
							search: request.search,
							typeUuid: params.typeUuid,
							sortField: '[ap_asset_sub_type].[name]',
							sortDirection: SortDirection.ASC
						};
			
						try {
							const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listName, null, null, data, Session.session);
							request.onFinish(req.response.subTypes, req.response.hasMore, req.id);
						} catch {
							request.onError();
						}
					},
					fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest, object: any): Promise<void> {
						if (request.options.length > 0) {
							const data = {
								uuid: request.options[0],
								typeUuid: object.typeUuid
							};
			
							try {
								const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, data, Session.session);
								request.onFinish(req.response.subType);
							} catch {}
						} else {
							request.onFinish(null);
						}
					},
					getOptionText: function(option: any): string {
						return option.name;
					}
				}
			];
			
			await Modal.form(Locale.get('filters'), filters, layout);
		}
		
		const assetsCache: Map<UUID, APAsset> = new Map<UUID, APAsset>();
		const parentAssetsCache: Map<UUID, APAsset> = new Map<UUID, APAsset>();

		const typesCache: Map<UUID, APAssetType> = new Map<UUID, APAssetType>();
		const subTypesCache: Map<UUID, APAssetSubType> = new Map<UUID, APAssetSubType>();

		const assetXLSXBaseHeader: string[] = [
			Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
			Locale.get('name'), Locale.get('tag'), Locale.get('description'),
			Locale.get('pictures'), Locale.get('documents'),
			Locale.get('latitude'), Locale.get('longitude'), Locale.get('altitude'),
			Locale.get('qr'), Locale.get('nfc'),
			Locale.get('nameplate'),
			Locale.get('manufacturer'), Locale.get('model'), Locale.get('serialNumber'),
			Locale.get('manufacturingYear'), Locale.get('installationDate'), 
			Locale.get('typeUuid'), Locale.get('type'),
			Locale.get('subtypeUuid'), Locale.get('subtype'), 
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('atexZone'), Locale.get('atexZoneTemperature'), Locale.get('atexZoneExplosion'), Locale.get('atexTags'), Locale.get('atexCompliant')
		];

		const fileData: any[][] = [assetXLSXBaseHeader];

		let from: number = 0;
		const count: number = 300;

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.assetPortfolio.asset.count, null, null, {typeUuid: filters.typeUuid, subtypeUuid: filters.subtypeUuid, search: search, searchFields: searchFields}, Session.session, true)).response.count;

			if (total > 0) {
				const typesUuid: UUID[] = [];
				const subTypesUuid: UUID[] = [];

				// Get all assets
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.assetPortfolio.asset.listDetailed, null, null, {from: from, count: count, typeUuid: filters.typeUuid, subtypeUuid: filters.subtypeUuid, search: search, searchFields: searchFields, sortField: sortField, sortDirection: sortDirection}, Session.session, true);

					const parentAssetsUuid: UUID[] = [];

					for (let i = 0; i < request.response.assets.length; i++) {
						const asset = APAsset.parse(request.response.assets[i]);
						assetsCache.set(asset.uuid, asset);

						// Asset type info
						if (asset.typeUuid && typesUuid.indexOf(asset.typeUuid) === -1) {
							typesUuid.push(asset.typeUuid);
						}

						// Asset sub-type info
						if (asset.subTypeUuid && subTypesUuid.indexOf(asset.subTypeUuid) === -1) {
							subTypesUuid.push(asset.subTypeUuid);
						}

						if (asset.parentUuid) {
							parentAssetsUuid.push(asset.parentUuid);
						}
					}

					// Get all parent assets of this batch
					const requestParents = (await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: parentAssetsUuid}, Session.session, true)).response.assets;
					requestParents.forEach((d: any) => {
						const asset: APAsset = APAsset.parse(d);
						parentAssetsCache.set(asset.uuid, asset);						
					});

					from += request.response.assets.length;
					if (!request.response.hasMore) {
						break;
					}
				}

				// Get all types
				const requestTypes = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, {types: typesUuid}, Session.session, true);
				for (let i = 0; i < requestTypes.response.types.length; i++) {
					const t: APAssetType = APAssetType.parse(requestTypes.response.types[i]);
					typesCache.set(t.uuid, t);
				}

				// Get all sub-types
				const requestSubTypes = await Service.fetch(ServiceList.assetPortfolio.assetSubType.getBatch, null, null, {subTypes: subTypesUuid}, Session.session, true);
				for (let i = 0; i < requestSubTypes.response.subTypes.length; i++) {
					const sub: APAssetSubType = APAssetSubType.parse(requestSubTypes.response.subTypes[i]);
					subTypesCache.set(sub.uuid, sub);
				}

				// Add assets info to document
				for (const asset of assetsCache.values()) {
					const assetRow: string[] = APAssetExport.buildAssetXLSXBaseRow(asset, parentAssetsCache.get(asset.parentUuid), typesCache.get(asset.typeUuid), subTypesCache.get(asset.subTypeUuid));
					fileData.push(assetRow);
				}
			}
			
			XlsxUtils.writeFile(fileData, 'assets.xlsx');
			progress.update(Locale.get('loadingData'), 1);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Build a row with base data to export on a asset xlsx sheet.
	 * 
	 * @param asset - The asset object to extract the base data from.
	 * @param parentAsset - The parent asset object to present on row.
	 * @param assetType - The asset type object to present data on row.
	 * @param assetSubtype - The asset subtype object to present data on row.
	 * @returns The row with asset base data regarding the base headers.
	 */
	private static buildAssetXLSXBaseRow(asset: APAsset, parentAsset?: APAsset, assetType?: APAssetType, assetSubtype?: APAssetSubType): string[] {
		const nd = '';
		
		let row: any[] = [
			asset.uuid, asset.createdAt.toLocaleString(Locale.code), asset.updatedAt.toLocaleString(Locale.code),
			asset.name, asset.tag, asset.description
		];

		const imagesURLs: string[] = [];
		for (let i = 0; i < asset.pictures.length; i++) {
			imagesURLs.push(ResourceUtils.getURL(asset.pictures[i]));
		}
		row.push(imagesURLs.join(', '));
		
		const documentsURLs: string[] = [];
		for (let i = 0; i < asset.documents.length; i++) {
			documentsURLs.push(ResourceUtils.getURL(asset.documents[i]));
		}
		row.push(documentsURLs.join(', '));
		
		if (asset.position) {
			row = row.concat([
				asset.position.latitude ? String(asset.position.latitude) : nd, 
				asset.position.longitude ? String(asset.position.longitude) : nd, 
				asset.position.altitude ? String(asset.position.altitude) : nd
			]);
		} else {
			row = row.concat([nd, nd, nd]);
		}

		row = row.concat([asset.qr ? asset.qr : nd, asset.nfc ? asset.nfc : nd]);

		const nameplateURLs: string[] = [];
		if (asset.nameplate) {
			for (let i = 0; i < asset.nameplate.length; i++) {
				nameplateURLs.push(ResourceUtils.getURL(asset.nameplate[i]));
			}
		}
		row.push(nameplateURLs.join(', '));

		row = row.concat([
			asset.manufacturer, asset.model, asset.serialNumber,
			asset.manufacturingYear, asset.installationDate ? asset.installationDate.toLocaleString(Locale.code) : nd
		]);
		
		// Asset type info
		if (asset.typeUuid) {
			row = row.concat(assetType ? [assetType.uuid, assetType.name] : [nd, nd]);
		} else {
			row = row.concat([nd, nd]);
		}

		// Asset sub-type info
		if (asset.subTypeUuid) {
			row = row.concat(assetSubtype ? [assetSubtype.uuid, assetSubtype.name] : [nd, nd]);
		} else {
			row = row.concat([nd, nd]);
		}

		// Parent asset info
		if (asset.parentUuid) {
			row.push(asset.parentUuid);
			
			if (parentAsset) {
				row = row.concat([parentAsset.name, parentAsset.tag]);
			} else {
				row = row.concat([nd, nd]);
			}
		} else {
			row = row.concat([nd, nd, nd]);
		}

		if (assetType && asset.atex !== null) {
			// Atex zones
			if (asset.atex.zone && asset.atex.zone.length > 0) {
				const zones: string[] = [];
				for (let z = 0; z < asset.atex.zone.length; z++) {
					zones.push(Locale.get(AtexZonesLabel.get(asset.atex.zone[z])));
				}
				row.push(zones.join(', '));
			} else {
				row.push(Locale.get(AtexZonesLabel.get(AtexZones.UNCLASSIFIED)));
			}
			row.push(Atex.isGasZone(asset.atex.zone) ? AtexTemperatureLabels.get(asset.atex.zoneTemperature) : asset.atex.zoneTemperature);
			row.push(asset.atex.zoneExplosion !== AtexExplosionGroups.UNKNOWN ? AtexExplosionGroupsLabels.get(asset.atex.zoneExplosion) : nd);

			// Atex tags
			let tags = '';
			for (let k = 0; k < asset.atex.tags.length; k++) {
				tags += asset.atex.tags[k].toString();
				if (k + 1 < asset.atex.tags.length) {
					tags += ', ';
				}
			}
			row.push(tags || nd);

			// Atex compliant
			row.push(asset.atex.isCompliant() ? Locale.get('yes') : Locale.get('no'));
		} else {
			// Push one ND for each of the Atex fields if no data available
			row = row.concat([nd, nd, nd, nd, nd]);
		}

		return row;
	}
	
	/**
	 * Exports assets' list by asset type and subtype with complete details from all structure tabs on a XLSX file.
	 * 
	 * Assets are exported by sheets depending on its type and subtype.
	 */
	public static async exportDetailedXLSX(): Promise<void> {
		const filters = {
			// Asset type uuids to filter the export by
			typeUuids: null
		};
		
		const layout: UnoFormField[] = [
			{
				attribute: 'typeUuids',
				label: 'assetTypes',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				showClear: true,
				multiple: true,
				placeholder: 'all',
				identifierAttribute: 'uuid',
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						from: request.from,
						count: request.count,
						search: request.search, 
						searchFields: ['[ap_asset_type].[id]', '[ap_asset_type].[name]'],
						sortField: '[ap_asset_type].[name]',
						sortDirection: SortDirection.ASC
					};
		
					try {
						const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetType.listName, null, null, data, Session.session);
						request.onFinish(req.response.types, req.response.hasMore, req.id);
					} catch {
						request.onError();
					}
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						try {
							const req: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, {types: request.options}, Session.session);
							request.onFinish(req.response.type);
						} catch {}
					} else {
						request.onFinish(null);
					}
				},
				getOptionText: function(option: any): string {
					return option.name;
				}
			}
		];
		
		await Modal.form(Locale.get('filters'), filters, layout);
		
		const progressBar = new ProgressBar();
		progressBar.show();
		progressBar.update(Locale.get('loadingData'), 0);
		
		// Get asset types depending on user choices
		let requestTypes: ServiceResponse;
		if (filters.typeUuids?.length > 0) {
			requestTypes = await Service.fetch(ServiceList.assetPortfolio.assetType.getBatch, null, null, {types: filters.typeUuids}, Session.session, true);
		} else {
			requestTypes = await Service.fetch(ServiceList.assetPortfolio.assetType.listName, null, null, {}, Session.session, true);
		}

		// Get asset types
		const types: APAssetType[] = requestTypes.response.types.map((d) => {return APAssetType.parse(d);});

		const assetsCache: Map<UUID, APAsset> = new Map<UUID, APAsset>();

		// Get an asset by its UUID. If the asset is already on cache, it will be returned from it. If not, the asset is fetched from the API.
		async function getAsset(assetUuid: UUID): Promise<APAsset> {
			if (!assetUuid) {
				throw new Error('Invalid asset UUID provided when trying to get an asset.');
			}
			
			let asset: APAsset; 
			
			if (assetsCache.has(assetUuid)) {
				asset = assetsCache.get(assetUuid);
			} else {
				asset = await AssetService.get(assetUuid, true, false);
				assetsCache.set(asset.uuid, asset);
			}

			return asset;
		}

		const assetXLSXBaseHeader: string[] = [
			Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
			Locale.get('name'), Locale.get('tag'), Locale.get('description'),
			Locale.get('pictures'), Locale.get('documents'),
			Locale.get('latitude'), Locale.get('longitude'), Locale.get('altitude'),
			Locale.get('qr'), Locale.get('nfc'),
			Locale.get('nameplate'),
			Locale.get('manufacturer'), Locale.get('model'), Locale.get('serialNumber'),
			Locale.get('manufacturingYear'), Locale.get('installationDate'), 
			Locale.get('typeUuid'), Locale.get('type'),
			Locale.get('subtypeUuid'), Locale.get('subtype'), 
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('atexZone'), Locale.get('atexZoneTemperature'), Locale.get('atexZoneExplosion'), Locale.get('atexTags'), Locale.get('atexCompliant')
		];

		const workBookSheets: XlsxSheetData[] = [];

		// Loads assets with detailed information in batches
		async function loadAssetsDetailedByType(typeUuid: UUID): Promise<APAsset[]> {
			let from: number = 0;
			const count: number = 250;

			let requestedAssets: APAsset[] = [];
			
			// Request to list assets by asset type
			let assetsRequest: ServiceResponse;

			// Load assets
			do {
				assetsRequest = await Service.fetch(ServiceList.assetPortfolio.asset.listDetailed, null, null, {typeUuid: typeUuid, from: from, count: count}, Session.session, true);
				
				requestedAssets = requestedAssets.concat(assetsRequest.response.assets.map((d) => {
					const a: APAsset = APAsset.parse(d);
					
					// Keep asset on local cache object
					if (!assetsCache.has(a.uuid)) {
						assetsCache.set(a.uuid, a);
					}
					
					return a;
				}));

				from += count;
			} while (assetsRequest.response.hasMore);

			return requestedAssets;
		}
		
		// Build xlsx headers from tabs
		function buildXLSXHeadersFromTabs(tabs: APAssetFormTab[]): string[][] {
			const tabHeaders: string[] = [];
			const cardsHeader: string[] = [];
			const fieldsHeader: string[] = [];

			tabs.forEach((tab: APAssetFormTab) => {
				tab.cards.forEach((card: APAssetFormTabCard) => {
					if (card.block) {
						card.block.fields.forEach((field: APAssetFormBlockField) => {
							tabHeaders.push(tab.name);
							cardsHeader.push(card.name);
							fieldsHeader.push(field.name);
						});
					}
				});
			});

			return [
				tabHeaders,
				cardsHeader,
				fieldsHeader
			];
		}

		// Build xlsx row data from tabs
		function buildXLSXRowDataFromTabs(asset: APAsset, tabs: APAssetFormTab[], assetFieldsData: APAssetFieldData[]): string[] {
			const rowData: string[] = [];

			tabs.forEach((tab: APAssetFormTab) => {
				tab.cards.forEach((card: APAssetFormTabCard) => {
					if (card.block) {
						card.block.fields.forEach((field: APAssetFormBlockField) => {
							const fieldData: APAssetFieldData = assetFieldsData.find((d: APAssetFieldData) => { return d.cardUuid === card.uuid && d.fieldUuid === field.uuid; });

							switch (field.formFieldComponent) {
								case APAssetFormBlockFieldComponentType.DOCUMENT_RESOURCE_MULTIPLE:
								case APAssetFormBlockFieldComponentType.IMAGE_RESOURCE_MULTIPLE:
									const urls: string[] = [];
									for (let i = 0; i < fieldData?.data?.length; i++) {
										urls.push(ResourceUtils.getURL(fieldData?.data[i]));
									}

									rowData.push(urls.join(', '));
									break;

								case APAssetFormBlockFieldComponentType.OPTIONS_MULTIPLE:
									rowData.push(fieldData?.data?.join(', '));
									break;

								default:
									rowData.push(fieldData ? fieldData.data : '');
									break;
							}
						});
					}
				});
			});

			return rowData;
		}

		async function appendSheet(assets: APAsset[], assetsData: APAssetFieldData[], assetType: APAssetType, assetSubtype?: APAssetSubType): Promise<void> {
			// Get asset strucutre tabs
			const tabs: APAssetFormTab[] = await AssetReport.getAssetStructureTabsContent(assetType.uuid, assetSubtype?.uuid);

			// Build sheet headers from tabs
			const headers: string[][] = buildXLSXHeadersFromTabs(tabs);
		
			// Merge asset tabs headers with the asset base headers
			headers[0] = new Array(assetXLSXBaseHeader.length).fill('-').concat(headers[0]);
			headers[1] = new Array(assetXLSXBaseHeader.length).fill('-').concat(headers[1]);
			headers[2] = assetXLSXBaseHeader.concat(headers[2]);
		
			const filteredAssets: APAsset[] = assets.filter((a: APAsset) => { 
				if (assetSubtype) {
					return a.typeUuid === assetType.uuid && a.subTypeUuid === assetSubtype.uuid;
				} else {
					return a.typeUuid === assetType.uuid && !a.subTypeUuid;
				}
			});

			// Get all assets parents not already fetched from API to avoid fetching assets individually
			const parentAssetUUIDs: UUID[] = [];
			filteredAssets.forEach((a: APAsset) => {
				if (a.parentUuid && !assetsCache.has(a.parentUuid)) {
					parentAssetUUIDs.push(a.parentUuid);
				}
			});
		
			if (parentAssetUUIDs.length > 0) {
				// Get parent assets in batch
				const requestParents = (await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: parentAssetUUIDs}, Session.session, true)).response.assets;
				requestParents.forEach((d: any) => {
					const a: APAsset = APAsset.parse(d);
					assetsCache.set(a.uuid, a);
				});
			}

			// The sheet containing data of assets
			const sheetData: any[][] = [];
		
			for (let i = 0; i < filteredAssets.length; i++) {
				let parentAsset: APAsset;
				if (filteredAssets[i].parentUuid) {
					parentAsset = await getAsset(filteredAssets[i].parentUuid);
				}
		
				// Build asset base row data
				let assetRow: string[] = APAssetExport.buildAssetXLSXBaseRow(filteredAssets[i], parentAsset, assetType, assetSubtype);
			
				assetRow = assetRow.concat(...buildXLSXRowDataFromTabs(filteredAssets[i], tabs, assetsData.filter((d: APAssetFieldData) => { return d.assetUuid === filteredAssets[i].uuid;})));
		
				sheetData.push(assetRow);
			}
			
			const assetTypeSheet: XlsxSheetData = {
				name: workBookSheets.length + 1 + ' | ' + assetType.name + (assetSubtype ? ' | ' + assetSubtype.name : ''),
				data: [
					...headers,
					...sheetData
				]
			};
			
			workBookSheets.push(assetTypeSheet);
		}

		try {
			for (let i = 0; i < types.length; i++) {
				// The list of all assets of this type (regardless of its subtype)
				const assets: APAsset[] = await loadAssetsDetailedByType(types[i].uuid);

				const assetsData: APAssetFieldData[] = await AssetFieldDataService.listBatch(assets.map((a) => {return a.uuid;}));
				
				await appendSheet(assets, assetsData, types[i], null);
	
				// Get all asset type' subtypes
				const subtypeRequest: ServiceResponse = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listName, null, null, {typeUuid: types[i].uuid}, Session.session, true);
				const subtypes: APAssetSubType[] = subtypeRequest.response.subTypes.map((d: any) => {return APAssetSubType.parse(d);});

				// Create a new sheet on document for every asset subtype
				for (let j = 0; j < subtypes.length; j++) {
					progressBar.update(Locale.get('loadingData'), i / types.length + j / subtypes.length / types.length);
					await appendSheet(assets, assetsData, types[i], subtypes[j]);
				}

				progressBar.update(Locale.get('loadingData'), (i + 1) / types.length);
			}
			
			XlsxUtils.writeMultiSheetFile(workBookSheets, 'assets.xlsx');

			progressBar.update(Locale.get('loadingData'), 1);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progressBar.destroy();
	}
}
