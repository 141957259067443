import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Locale} from '../../../../../../locale/locale';
import {Modal} from '../../../../../../modal';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {ActionPlanAction} from '../../../../../../models/atex-inspections/action-plan/action-plan-action';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoDynamicFormModule} from '../../../../../../components/uno-forms/uno-dynamic-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {ActionPlanActionService} from '../../../services/action-plan-action.service';
import {ActionPlanActionLayout} from './action-plan-action-layout';

@Component({
	selector: 'masterdata-action-plan-actions-page',
	templateUrl: 'action-plan-actions.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoDynamicFormModule, UnoButtonComponent, TranslateModule]
})
export class ActionPlanActionsPage extends ScreenComponent implements OnInit {
	public get layout(): any { return ActionPlanActionLayout; }


	public permissions = [UserPermissions.ACTION_PLAN_ACTIONS_EDIT];

	public data: ActionPlanAction[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('actionPlanActions');

		this.data = await ActionPlanActionService.load();
	}

	public add(): void {
		const action = new ActionPlanAction();
		action.id = this.data.length;
		this.data.push(action);
	}

	public delete(action: ActionPlanAction): void {
		const index = this.data.indexOf(action);
		if (index !== -1) {
			this.data.splice(index, 1);
		}
	}

	public save(): void {
		const data = structuredClone(this.data);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.atex.actionPlan.actionsUpdate, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}
}
