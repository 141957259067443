import {Vector2} from 'three';
import {Geolocation} from '../models/geolocation';

/**
 * Location utils contains utils to access the user location (GPS, IP location or wifi) and convert data between representations.
 *
 * Devices with a GPS, for example, can take a minute or more to get a GPS fix, so less accurate data (IP location or wifi) may be returned.
 */
export class GeolocationUtils {
	/**
	 * Average earth radius in meters.
	 */
	public static EARTH_RADIUS = 6371008;

	/**
	 * Earth equator perimeter in meters.
	 */
	public static EARTH_PERIMETER = 2 * Math.PI * GeolocationUtils.EARTH_RADIUS;

	/**
	 * Earth equator perimeter in meters.
	 */
	public static EARTH_ORIGIN = GeolocationUtils.EARTH_PERIMETER / 2.0;

	/**
	 * Get position from GPS or browser location API.
	 */
	public static async getLocation(): Promise<Geolocation> {
		return new Promise(function(resolve, reject) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function(position: GeolocationPosition) {
					resolve(new Geolocation(position.coords.latitude, position.coords.longitude));
				}, function(error: GeolocationPositionError ) {
					reject(error);
				});
			} else {
				reject('Geolocation API is not available.');
			}
		});
	}

	/**
	 * Calculates the distance between two points in meters.
	 *
	 * @param a - Coordinates of 1st point.
	 * @param b - Coordinates of 2nd point
	 * @returns The distance between the two points.
	 */
	public static distance(a: Geolocation, b: Geolocation): number {
		const R = GeolocationUtils.EARTH_RADIUS / 1e3;

		const radToDeg = Math.PI / 180;
		const dLat = (b.latitude - a.latitude) * radToDeg;
		const dLon = (b.longitude - a.longitude) * radToDeg;

		const c = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(a.latitude * radToDeg) * Math.cos(b.latitude * radToDeg);
		const d = 2 * Math.atan2(Math.sqrt(c), Math.sqrt(1 - c));

		return R * d * 1000;
	}

	/**
	 * Converts given lat/lon in WGS84 Datum to XY in Spherical Mercator EPSG:900913.
	 */
	public static datumsToSpherical(latitude: number, longitude: number): Vector2 {
		const x = longitude * GeolocationUtils.EARTH_ORIGIN / 180.0;
		let y = Math.log(Math.tan((90 + latitude) * Math.PI / 360.0)) / (Math.PI / 180.0);
		y = y * GeolocationUtils.EARTH_ORIGIN / 180.0;

		return new Vector2(x, y);
	}

	/**
	 * Converts XY point from Spherical Mercator EPSG:900913 to lat/lon in WGS84 Datum.
	 */
	public static sphericalToDatums(x: number, y: number): Geolocation {
		const longitude = x / GeolocationUtils.EARTH_ORIGIN * 180.0;
		let latitude = y / GeolocationUtils.EARTH_ORIGIN * 180.0;

		latitude = 180.0 / Math.PI * (2 * Math.atan(Math.exp(latitude * Math.PI / 180.0)) - Math.PI / 2.0);

		return new Geolocation(latitude, longitude);
	}

	/**
	 * Converts quad tree zoom/x/y to lat/lon in WGS84 Datum.
	 */
	public static quadtreeToDatums(zoom: number, x: number, y: number): Geolocation {
		const n = Math.pow(2.0, zoom);
		const longitude = x / n * 360.0 - 180.0;
		const latitudeRad = Math.atan(Math.sinh(Math.PI * (1.0 - 2.0 * y / n)));
		const latitude = 180.0 * (latitudeRad / Math.PI);

		return new Geolocation(latitude, longitude);
	}
}
