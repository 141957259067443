import {UUID} from '../../uuid';
import {LDSAlarmLevel} from './lds-alarm-level';
import {AlarmCondition} from './lds-alarm-condition';

/**
 * Range of the sensor alarm.
 */
export type LDSAlarmRange = {min?: number, max?: number};

/**
 * LDS Alarm configuration, applied to a specific LDS sensor.
 *
 * The alarm configuration determines the parameters to analyse LDS data and actions to apply when a condition is met.
 */
export class LDSAlarm {
	/**
	 * UUID of the alarm.
	 */
	public uuid: UUID;

	/**
	 * LDS to which this alarm belongs.
	 */
	public ldsUuid: UUID;

	/**
	 * Description of the alarm.
	 */
	public description: string = '';

	/**
	 * Alarm level.
	 */
	public level: LDSAlarmLevel;

	/**
	 * Condition used to analyse data.
	 */
	public condition: AlarmCondition;

	/**
	 * Value range of the alarm conditions.
	 */
	public range: LDSAlarmRange = {min: 0, max: 0};

	/**
	 * Activation time in seconds.
	 *
	 * Data have to meet the defined condition during the
	 */
	public activationTime: number;

	/**
	 * Users that will be notified of the alarm via email.
	 */
	public usersEmail: UUID[] = [];

	/**
	 * List of users that will be notified of the alarm via SMS.
	 */
	public usersSms: UUID[] = [];

	/**
	 * Indicates if the alarm is active.
	 * 
	 * Set by the system when the alarm is triggered and reset when the alarm is processed.
	 */
	public activated: boolean = false;

	/**
	 * Parse alarm data received from API into Alarm object.
	 * 
	 * @param data - Data received from API.
	 * @returns Alarm object. 
	 */
	public static parse(data: any): LDSAlarm {
		const alarm = new LDSAlarm();

		alarm.uuid = data.uuid;

		alarm.ldsUuid = data.ldsUuid;
		alarm.description = data.description;
		alarm.level = data.level;
		alarm.condition = data.condition;
		alarm.range = data.range;
		alarm.activationTime = data.activationTime;
		alarm.usersEmail = data.usersEmail || [];
		alarm.usersSms = data.usersSms || [];
		alarm.activated = data.activated === true;

		return alarm;
	}
}

