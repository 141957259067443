import {cloneDeep} from 'lodash-es';
import {Component, OnInit} from '@angular/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoTabComponent} from 'src/app/components/uno/uno-tab/uno-tab.component';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {generateUUID} from 'three/src/math/MathUtils';
import {AssetService} from 'src/app/modules/asset-portfolio/services/asset.service';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UnoTabSectionComponent} from 'src/app/components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {TranslateModule} from '@ngx-translate/core';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from 'src/app/components/uno/uno-title/uno-title.component';
import {UnoDynamicFormModule} from 'src/app/components/uno-forms/uno-dynamic-form.module';
import {AssetBaseLayout, AssetModelLayout} from 'src/app/modules/asset-portfolio/screens/asset/asset-layout';
import {ServiceList} from 'src/app/http/service-list';
import {Service} from 'src/app/http/service';
import {DL50InspectionStatus, DL50InspectionStatusLabel, DL50InspectionStatusType} from 'src/app/models/dl50/dl50-inspection-status';
import {UnoFormField} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-field';
import {UnoDynamicFormComponent} from 'src/app/components/uno-forms/uno-dynamic-form/uno-dynamic-form.component';
import {CommonModule} from '@angular/common';
import {DL50InspectionQuestionResponse} from 'src/app/models/dl50/dl50-inspection-question-response';
import {UnoFormUtils} from 'src/app/components/uno-forms/uno-dynamic-form/uno-form-utils';
import {CompanyService} from 'src/app/modules/companies/services/companies.service';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {UnoListComponent} from 'src/app/components/uno/uno-list/uno-list-component';
import {UnoListItemComponent} from 'src/app/components/uno/uno-list-item/uno-list-item.component';
import {UnoListItemLabelComponent} from 'src/app/components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListLazyLoadHandler} from 'src/app/components/uno/uno-list/uno-list-lazy-load-handler';
import {Loading} from 'src/app/loading';
import {FileUtils} from 'src/app/utils/file-utils';
import {DL50Question} from '../../../../../models/dl50/masterdata/dl50-question';
import {DL50Service} from '../../../services/dl50.service';
import {DL50InspectionLayouts} from '../dl50-inspections-layouts';
import {Dl50InspectionUtils} from '../../../data/dl50-inspection-utils';
import {Dl50MasterSettingsService} from '../../../services/dl50-master-settings.service';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {DL50InspectionReport} from '../../../data/dl50_inspection-report';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * Page used to edit dl50 inspections.
 */
@Component({
	selector: 'dl50-inspections-edit-page',
	templateUrl: 'dl50-inspections-edit.page.html',
	standalone: true,
	imports: [
		CommonModule,
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoButtonComponent,
		TranslateModule,
		UnoTitleComponent,
		UnoDynamicFormModule,
		FormatDatePipe,
		UnoNoDataComponent,
		UnoListComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		PermissionsPipe
	]
})
export class DL50InspectionsEditPage extends ScreenComponent implements OnInit {
	
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_INSPECTIONS_EDIT, UserPermissions.DL50_INSPECTOR, UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_CLIENT];

	public get session(): any {return Session;}

	public get userPermissions(): any {return UserPermissions;}

	public get inspectionStatus(): any {return DL50InspectionStatus;}

	public get assetBaseLayout(): any { return AssetBaseLayout; }

	public get assetModelLayout(): any { return AssetModelLayout; }

	public get inspectionLayout(): any { return DL50InspectionLayouts; }

	public get dL50InspectionStatusLabel(): any { return DL50InspectionStatusLabel; }

	/**
	 * Handler for the mobile list
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Flag set true when any asset information is edited.
	 */
	public assetEdited: boolean = false;

	/**
	 * Sets asset edited flag true.
	 */
	public setAssetEdited: Function = () => {
		// Trigger change detection
		this.asset = APAsset.parse(this.asset);
		this.assetEdited = true;
	};

	/**
	 * Reloads asset data if it has changed.
	 */
	public baseInfoEdited: Function = async() => {
		if (this.asset?.uuid !== this.inspection.assetUuid) {
			this.asset = this.inspection.assetUuid ? await AssetService.get(this.inspection.assetUuid) : null;
		}
	};

	/**
	 * Create mode flag. If true, the inspection is being created and still does not exist on the server.
	 */
	public createMode: boolean = true;

	/**
	 * Inspection being edited on this screen. Data is fetched from the API if on edit mode.
	 */
	public inspection: DL50Inspection = null;

	/**
	 * The questions set on master data.
	 */
	public questions: DL50Question[] = [];

	/**
	 * The form layout built from the questions set on master data.
	 */
	public questionsLayout: UnoFormField[] = [];

	/**
	 * The inspection responses to be edited in the GUI.
	 *
	 * Question responses must be updated on the inspection object before submitting to the API.
	 */
	public questionResponses: {[key: string]: DL50InspectionQuestionResponse} = {};

	/**
	 * The asset this inspection refers to.
	 */
	public asset: APAsset = null;

	/*
	 * Flag indicating if inspection data is editable.
	 */
	public editable: boolean = false;

	/*
	 * Flag indicating if there are inspection histories.
	 */
	public hasHistory: boolean = false;

	/**
	 * The inspection base info layout (with customizations).
	 */
	public baseInfoLayout: UnoFormField[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data?.createMode && !data?.uuid) {
			App.navigator.pop();
			throw new Error('Missing required data for the screen. Params "createMode" or "uuid" on edit mode are required.');
		}

		this.createMode = data.createMode === true;
		this.assetEdited = false;

		this.baseInfoLayout = cloneDeep(DL50InspectionLayouts.baseInfo);
		const assetField = UnoFormUtils.getFormFieldByAttribute(this.baseInfoLayout, 'assetUuid');
		assetField.onChange = async() => {
			if (this.inspection.assetUuid) {
				this.asset = await AssetService.get(this.inspection.assetUuid);
			}
		};

		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		// Load questions from master data
		this.questions = (await DL50Service.questionsList()).questions;

		if (this.createMode) {
			this.inspection = new DL50Inspection();
			this.inspection.uuid = generateUUID();
			this.inspection.status = DL50InspectionStatus.IN_PROGRESS;
		} else {
			await this.loadData(data.uuid);
		}

		if (!this.inspection.companyUuid) {
			const settings = await Dl50MasterSettingsService.get();
			this.inspection.companyUuid = settings.companyUuid;
		}

		this.editable = Session.hasPermissions([UserPermissions.DL50_INSPECTIONS_CREATE, UserPermissions.DL50_INSPECTIONS_EDIT]) && this.inspection.status !== this.inspectionStatus.FINISHED;

		// Build the questions form data object
		this.questionResponses = this.inspection.initialize(this.questions);

		// Build the questions form layout
		this.questionsLayout = Dl50InspectionUtils.buildQuestionsLayout(this.questions);

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const request = await Service.fetch(ServiceList.dl50.history.list, null, null, {uuid: this.inspection.uuid}, Session.session);
			return {
				elements: request.response.history,
				hasMore: request.response.hasMore
			};
		};

		await this.handler.reset();
		this.hasHistory = this.handler.hasItems;
	}

	/**
	 * Loads all the data needed to present on edit screen
	 *
	 * @param uuid - The UUID of the inspection to load the data from.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		this.inspection = await DL50Service.inspectionsGet(uuid);

		// Load inspection asset data
		if (this.inspection.assetUuid) {
			this.asset = await AssetService.get(this.inspection.assetUuid);
		}
	}

	/**
	 * Check if all the required forms of the inspection (the visible ones) are filled. Automatically displays a GUI alert message on error.
	 *
	 * @param status - The status of the inspection to validate the forms accordingly.
	 */
	public checkInspectionRequiredForms(status: DL50InspectionStatusType): boolean {
		// Check required fields of asset content
		if (
			!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.baseInfo, this.inspection) ||
			!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.generalData, this.inspection) ||
			this.inspection.hasLoadTests && !UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.loadTestChecklist, this.inspection) ||
			this.inspection.hasElectricalTests && !UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.electricalTestChecklist, this.inspection) ||
			!UnoDynamicFormComponent.requiredFilled(this.questionsLayout, this.inspection.responses) ||
			!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.inspectionFinalNotes, this.inspection) ||
			status > DL50InspectionStatus.IN_PROGRESS && (
				!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.inspectionValidation, this.inspection) ||
				!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.inspectionSupervisionRejected, this.inspection) ||
				!UnoDynamicFormComponent.requiredFilled(DL50InspectionLayouts.inspectionClientFeedback, this.inspection)
			)
		) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Check if all the required forms of the asset are filled. Automatically displays a GUI alert message on error.
	 *
	 * @returns True if all forms are filled, false otherwise.
	 */
	public checkAssetRequiredForms(): boolean {
		// Check required fields of asset content
		if (!UnoDynamicFormComponent.requiredFilled(this.assetBaseLayout, this.asset) || !UnoDynamicFormComponent.requiredFilled(this.assetModelLayout, this.asset)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Saves a DL50 inspection on API.
	 *
	 * It can create or update an inspection depending on the createMode.
	 *
	 * Checks the required fields only on create mode or on status change. User must be able to save the inspection in any state but forms are only checked when changing inspection status.
	 *
	 * Asset forms are also checked when the asset has been edited.
	 *
	 * @param stayOnPage - Either to stay or leave the page after saving.
	 * @param destinationStatus - The status to update the inspection with.
	 */
	public async save(stayOnPage: boolean = false, destinationStatus?: DL50InspectionStatusType): Promise<void> {
		if (this.createMode && !Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_CREATE) || !this.createMode && !Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_EDIT)) {
			Modal.alert(Locale.get('error'), Locale.get('errorUpdatingInspectionPermission'));
			return;
		}

		this.inspection.clean();

		const inspection: DL50Inspection = structuredClone(this.inspection);

		// Check required fields
		if ((destinationStatus && destinationStatus !== inspection.status || this.createMode ) && !this.checkInspectionRequiredForms(inspection.status) || this.assetEdited && !this.checkAssetRequiredForms()) {
			return;
		}

		// Set new status
		if (destinationStatus && destinationStatus !== inspection.status) {
			inspection.status = destinationStatus;
		}

		try {
			// Save the asset data
			if (this.assetEdited) {
				if (!Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
					throw new Error('User does not have permission to edit asset.');
				}

				try {
					await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, this.asset, Session.session);
				} catch {
					Modal.alert(Locale.get('error'), Locale.get('errorUpdatingAsset'));
					return;
				}
			}

			// Update inspection data
			if (this.createMode) {
				await DL50Service.inspectionCreate(inspection);
			} else {
				await DL50Service.inspectionUpdate(inspection);
			}

			Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'), 3000, 'success');

			if (!stayOnPage) {
				App.navigator.pop();
				return;
			}

			await this.loadData(this.inspection.uuid);
		} catch {
			Modal.alert(Locale.get('error'), Locale.get(this.createMode ? 'errorCreatingInspection' : 'errorUpdatingInspection'));
			return;
		}
	}

	/**
	 * Deletes the inspection from API.
	 */
	public async delete(): Promise<void> {
		if (!Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_DELETE)) {
			throw new Error('User does not have permissions to delete this inspection.');
		}

		// Ask to confirm deletion
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await DL50Service.inspectionDelete(this.inspection.uuid);
			Modal.toast(Locale.get('success'), 3000, 'success');
			App.navigator.pop();
		}
	}

	/**
	 * Open an history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(history: any): Promise<void> {
		const buttons = [
			{success: false, label: 'close', color: 'primary'}
		];

		const asset: APAsset = await AssetService.get(history.assetUuid);

		history.pictures = asset.pictures;

		const formLayout: UnoFormField[] = DL50InspectionLayouts.baseInfo.concat(DL50InspectionLayouts.assetInfo, DL50InspectionLayouts.generalData, this.questionsLayout, DL50InspectionLayouts.inspectionFinalNotes);

		Modal.form(Locale.get('history'), history, formLayout, buttons, false);
	}

	/**
	 * Generate and export a DOCX report for the DL50 inspection.
	 *
	 * @param writeToFile - If true, the report is written to a file.
	 * @returns The report generated as array buffer.
	 */
	public async exportReportDOCX(writeToFile: boolean = true): Promise<ArrayBuffer> {
		Loading.show();

		try {
			const doc: ArrayBuffer = await DL50InspectionReport.generateDocx(this.inspection, this.questions, this.inspection.companyUuid ? await CompanyService.get(this.inspection.companyUuid) : null, this.asset);
			if (writeToFile) {
				FileUtils.writeFileArrayBuffer('dl50_' + this.inspection.uuid + '.docx', doc);
			}
			return doc;
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}

		return null;
	}

	/**
	 * Generate a PDF report for the DL50 inspection.
	 * 
	 * Use file conversion server to convert from DOCX to PDF.
	 */
	public async exportReportPDF(): Promise<void> {
		Loading.show();

		const doc: ArrayBuffer = await this.exportReportDOCX(false);
		if (!doc) {
			Loading.hide();
			return;
		}

		const form = new FormData();
		form.append('file', new Blob([doc]), this.inspection.uuid + '.docx');

		try {
			const convertedFile = (await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null)).response;
			FileUtils.writeFileArrayBuffer('dl50_' + this.inspection.uuid + '.pdf', convertedFile);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorConvertingReport', {details: e}));
		}

		Loading.hide();
	}
}
