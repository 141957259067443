import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {UUID} from 'src/app/models/uuid';
import {Session} from '../../../session';
import {Role} from '../../../models/roles/role';

export type RoleServiceListParams = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
	filterActive?: number,
};

export class RoleService {

	/**
	 * Get a role by its uuid.
	 *
	 * @param roleUUID - The UUID of the role.
	 */
	public static async get(roleUUID: UUID): Promise<Role> {
		const request = await Service.fetch(ServiceList.roles.get, null, null, {uuid: roleUUID}, Session.session);
		return Role.parse(request.response.role);
	}

	/**
	 * Create role.
	 *
	 * @param role - Role object.
	 */
	public static async create(role: Role): Promise<UUID> {
		const request = await Service.fetch(ServiceList.roles.create, null, null, role, Session.session);

		return request.response.uuid;
	}

	/**
	 * Update role.
	 *
	 * @param role - Role object.
	 */
	public static async update(role: Role): Promise<void> {
		await Service.fetch(ServiceList.roles.update, null, null, role, Session.session);
	}

	/**
	 * Delete role.
	 *
	 * @param roleUuid - The UUID of the role.
	 */
	public static async delete(roleUuid: UUID): Promise<void> {
		await Service.fetch(ServiceList.roles.delete, null, null, {uuid: roleUuid}, Session.session);
	}

	/**
	 * List all roles meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async listRoles(params: RoleServiceListParams): Promise<{hasMore: boolean, roles: Role[]}> {
		const request = await Service.fetch(ServiceList.roles.list, null, null, params, Session.session);
		return {
			hasMore: request.response.hasMore,
			roles: request.response.roles.map((role) => {
				return Role.parse(role);
			})
		};
	}

	/**
	 * Count all roles meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 */
	public static async countRoles(params: RoleServiceListParams): Promise<number> {
		const request = await Service.fetch(ServiceList.roles.count, null, null, params, Session.session);
		return request.response.count;
	}
}
