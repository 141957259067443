import {Component, Input, signal, WritableSignal} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {Resource} from 'src/app/models/resource';
import {UnoListItemIconComponent} from '../../uno-list-item/uno-list-item-icon.component';
import {UnoListItemLabelComponent} from '../../uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../uno-list-item/uno-list-item.component';
import {UnoTreeLayout, UnoTreeLayoutType} from '../uno-tree-layout';
import {ObjectAttributePipe} from '../../../../pipes/object-attribute.pipe';
import {ResourceUrlPipe} from '../../../../pipes/resource-url.pipe';

/**
 * Company list item is used to display a company (with its subcompanies on a collapsable tree list)
 */
@Component({
	selector: 'uno-tree-item',
	templateUrl: 'uno-tree-item.component.html',
	styleUrls: ['uno-tree-item.component.css'],
	standalone: true,
	imports: [UnoListItemComponent, UnoListItemIconComponent, NgStyle, IonicModule, UnoListItemLabelComponent, ObjectAttributePipe, ResourceUrlPipe]
})
export class UnoTreeItemComponent {
	public get resource(): any { return Resource; }
	
	public get layoutType(): any { return UnoTreeLayoutType; }

	/**
	 * Item to be displayed in the tree.
	 * 
	 * The item should have a children attribute that is an array of items.
	 */
	@Input()
	public item: any = null;

	/**
	 * Layout used to present an item in the tree.
	 */
	@Input()
	public layout: UnoTreeLayout[] = [];

	/**
	 * Picture field to be displayed in the tree (if any).
	 */
	@Input()
	public pictureField: UnoTreeLayout = null;

	/**
	 * Method used to load more items in the tree list.
	 */
	@Input()
	public loadData: (item: any)=> Promise<any[]>;

	/**
	 * Method to call if the item is clicked.
	 */
	@Input()
	public onClick: (item: any)=> void;

	/**
	 * Level of this object in the tree.
	 */
	@Input()
	public level: number = 1;

	/**
	 * Children of the item.
	 */
	public children: any[] = null;

	/**
	 * If true the tree list is expanded to show its children.
	 */
	public expanded: WritableSignal<boolean> = signal(false);


	/**
	 * Attribute to indicate if the item has children. Used to display the expand/collapse button.
	 * 
	 * If the attribute is not present, we consider that all items have children.
	 */
	public childrenAttribute: string = 'hasChildren';

	/**
	 * Method called when a item is clicked.
	 */
	public itemClick(event: MouseEvent, item: any): void {
		if (this.onClick) {
			this.onClick(item);
		}
	}

	/**
	 * Toggle the value of expand var, making template expand/collapse
	 * 
	 * May require data to be loaded lazily.
	 */
	public async expand(event: MouseEvent): Promise<void> {
		// Cancel event bubble to prevent navigation
		if (event !== undefined) {
			event.preventDefault();
			event.stopPropagation();
		}

		// Lazy load data if method available.
		if (!this.childrenAttribute || this.item[this.childrenAttribute] && !this.children) {
			this.children = await this.loadData(this.item);
		}

		// Toggle expanded flag
		this.expanded.set(!this.expanded());
	}
}
